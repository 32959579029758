import React from 'react'
import ReactPaginate from 'react-paginate'
import Icons from '../../Constants/Icons'

const CustomPagination = ({
  pageCount,
  pageRangeDisplayed,
  marginPagesDisplayed,
  onPageChange,
  initialPage
}) => {

  return (
    <ReactPaginate
      forcePage={initialPage}
      pageCount={pageCount}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginPagesDisplayed}
      onPageChange={onPageChange}
      containerClassName={'pagination'}
      previousLinkClassName={'pagination__link'}
      nextLinkClassName={'pagination__link'}
      activeClassName={'pagination__link--active'}
      previousLabel={
        <img
          src={Icons.ic_white_left_arrow}
          alt='Next'
          className='custom-pagination-center'
        />
      }
      nextLabel={
        <img
          src={Icons.ic_white_right_arrow}
          alt='Next'
          className='custom-pagination-center'
        />
      }
    />
  )
}

export default CustomPagination
