import React from 'react'
import Icons from '../../Constants/Icons'
import { useNavigate } from 'react-router-dom'
import Colors from '../../Constants/Colors'

const CustomEventScreen = ({ currentUsers, handelEnabled }) => {
  const navigate = useNavigate()
  const handleEditClick = currentUsers => {
    navigate('/EventUpdate', {
      state: {
        eventId: currentUsers.eventId,
        eventTypeId: currentUsers.eventTypeId
      }
    })
  }
  return (
    <div className='scrollable-container transform_data'>
      {currentUsers?.map((currentUsers, index) => (
        <div key={index} className='transaction-item cursor-pointer'>
          <div className='list-user-div-container border-bottom'>
            <div className='grid-cols grid_7' style={{ padding: '20px' }}>
              <div
                onClick={() =>
                  navigate('/EventDetails', {
                    state: {
                      eventId: currentUsers.eventId
                    }
                  })
                }
                className='fontSize16WithoutFlex user_name w-10 pointer'
              >
                {currentUsers.categoryName == ''
                  ? '-'
                  : currentUsers.categoryName}
              </div>

              <div
                onClick={() =>
                  navigate('/EventDetails', {
                    state: {
                      eventId: currentUsers.eventId
                    }
                  })
                }
                className='fontSize16WithoutFlex user_name alignItems'
              >
                <img
                  src={
                    currentUsers.eventCreatorImg === null
                      ? Icons.ic_AY_logo
                      : currentUsers.eventCreatorImg
                  }
                  className='currentUsers-image'
                  style={{
                    objectFit: 'cover'
                  }}
                />
                <div
                  className='user_text pointer'
                  onClick={() =>
                    navigate('/EventDetails', {
                      state: {
                        eventId: currentUsers.eventId
                      }
                    })
                  }
                >
                  <div className='user_text'>
                    {currentUsers.eventCreatorName}
                  </div>
                  <div className='user_text'>
                    {currentUsers.eventCreatorUserName}
                  </div>
                </div>
              </div>

              <div
                style={{ display: 'block', gap: '4px' }}
                className='user_name'
              >
                <div
                  onClick={() =>
                    navigate('/EventDetails', {
                      state: {
                        eventId: currentUsers.eventId
                      }
                    })
                  }
                  className='fontSize16WithoutFlex alignItems'
                >
                  <img className='sms-res' src={Icons.ic_sms} alt='new' />
                  <div className='user_text'>
                    {currentUsers.eventCreatorEmail}
                  </div>
                </div>
                <div
                  onClick={() =>
                    navigate('/EventDetails', {
                      state: {
                        eventId: currentUsers.eventId
                      }
                    })
                  }
                  className='fontSize16WithoutFlex alignItems pointer'
                >
                  {' '}
                  <img className='icon_style_list' alt='' src={Icons.ic_call} />
                  <div className='user_text'>
                    {currentUsers.eventCreatorMobile}
                  </div>
                </div>
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate('/EventDetails', {
                    state: {
                      eventId: currentUsers.eventId
                    }
                  })
                }
                className='fontSize16WithoutFlex user_name'
              >
                {currentUsers.eventDescription}
              </div>
              <div
                onClick={() =>
                  navigate('/EventDetails', {
                    state: {
                      eventId: currentUsers.eventId
                    }
                  })
                }
                className='fontSize16WithoutFlex user_name alignItems pointer'
              >
                <div
                  className='user_text'
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate('/EventDetails', {
                      state: {
                        eventId: currentUsers.eventId
                      }
                    })
                  }
                >
                  <div className='user_text'>{currentUsers.eventName}</div>
                  <div className='user_text'>{currentUsers.eventTypeName}</div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate('/EventDetails', {
                    state: {
                      eventId: currentUsers.eventId
                    }
                  })
                }
                className='fontSize16WithoutFlex user_name alignItems pointer'
              >
                <div
                  className='user_text'
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate('/EventDetails', {
                      state: {
                        eventId: currentUsers.eventId
                      }
                    })
                  }
                >
                  <div className='user_text'>{currentUsers.venueName}</div>
                  <div className='user_text'>{currentUsers.venueAddress}</div>
                </div>
              </div>
              <div className='w-10'>
                <img
                  src={Icons.edit}
                  onClick={() => handleEditClick(currentUsers)}
                  className='edit-button pointer'
                />
              </div>
              <div className='user_Status user_name pointer'>
                <button
                  onClick={() => handelEnabled(currentUsers, index)}
                  className='button'
                  style={{
                    cursor: 'pointer',
                    backgroundColor:
                      currentUsers.action === 'Accepted'
                        ? Colors.mainGreen01
                        : currentUsers.action === 'Rejected'
                        ? Colors.mainOrange01
                        : Colors.mainRed01,
                    color: currentUsers.isEnabled
                      ? Colors.darkGreen
                      : Colors.darkRed
                  }}
                >
                  {currentUsers.isEnabled ? 'Activate' : 'Deactivate'}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CustomEventScreen
