import React, { useState, useEffect } from 'react'
import Icons from '../../Constants/Icons'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import { CustomUploadProfilePic } from '../../Core/CustomUploadProfilePic'
import { apiCallAddSubCategoryAdd } from '../../Core/Apicall'

const AddSubcategory = ({
  shows,
  setShowCategory,
  editData,
  handleClose,
  categoryItemSelect,
  pageRefresh
}) => {
  const [subcategoryName, setSubcategoryName] = useState('')
  const [uploadImage, setUploadImage] = useState(true)
  const [imagePreview, setImagePreview] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    if (editData !== undefined) {
      setImagePreview(editData?.categoryImageURL || '')
      setSubcategoryName(editData?.categoryName || '')
      setUploadImage(true)
    }
  }, [editData])

  const uploadImageRemove = () => {
    setImagePreview('')
    setUploadImage(true)
  }

  const handleSubcategoryNameChange = e => {
    setSubcategoryName(e.target.value)
  }

  const closeModal = () => {
    setSubcategoryName('')
    setImagePreview('')
    setUploadImage(true)
    setShowCategory(false)
  }

  const handleImageUpload = async event => {
    dispatch(setLoadingTrue())
    const selectedImage = event.target.files[0]
    const formData = new FormData()
    formData.append('imageURL', selectedImage)
    formData.append('imageType', 'IntrestCategoryImages')
    formData.append('imageStoreId', '123456789')

    try {
      const data = await CustomUploadProfilePic(formData)
      setImagePreview(data.location)
      setUploadImage(false)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const handleSave = async () => {
    if (subcategoryName.trim() === '') {
      alert('Please enter a subcategory name')
      return
    }
    if (imagePreview === '') {
      alert('Please upload an image')
      return
    }
    dispatch(setLoadingTrue())
    let param = {
      categoryId: categoryItemSelect?.categoryId,
      artName: subcategoryName,
      artIconURL: imagePreview
    }
    try {
      await apiCallAddSubCategoryAdd(param)
      setSubcategoryName('')
      setImagePreview('')
      setUploadImage(true)
      closeModal()
      pageRefresh()
      handleClose()
      setShowCategory(false)
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  return (
    <div
      className={`modal-category ${shows ? 'display-block' : 'display-none'}`}
    >
      <div className='modal-content bg-Blue grid p-4 relative'>
        <span className='close' onClick={closeModal}>
          &times;
        </span>
        <h2>Add New Subcategory</h2>
        <div className='Category_name'>
          <input
            type='text'
            value={subcategoryName}
            onChange={handleSubcategoryNameChange}
            placeholder='Enter Subcategory Name'
          />
        </div>
        <div className='Category_upload'>
          {uploadImage ? (
            <div className='absolute'>
              <div className='upload-data'>
                <input
                  type='file'
                  className='subcategory-input'
                  onChange={handleImageUpload}
                  accept='image/*'
                />
                <img src={Icons.upload} className='subcategory-input' />
              </div>
            </div>
          ) : (
            <div className='w-full text-center relative '>
              <img
                src={Icons.delete}
                alt='Category Preview'
                onClick={uploadImageRemove}
                className='delete subcategory-delete'
              />
              <img
                src={imagePreview}
                alt='Category Preview'
                className='m-auto subcategory-preview '
              />
            </div>
          )}
        </div>
        <div className='category_button'>
          <button className='subcategory-save' onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddSubcategory
