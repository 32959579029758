import React, { useState } from "react";
function RejectModal({ isVisible, handleClose, onReject }) {
  const [rejectComment, setRejectComment] = useState("");

  const handleSubmit = async (rejectComment) => {
    onReject(rejectComment);
  };
  const closeModal = () => {
    setRejectComment("");
    handleClose();
  };
  return (
    <div
      className={`modal-category ${
        isVisible ? "display-block" : "display-none"
      }`}
    >
      <div className="modal-content bg-Blue grid p-4 relative">
        <span className="close " onClick={closeModal}>
          &times;
        </span>
        <h2 className="text-white font-medium text-xl text-center my-3">
          {"Reject Comment"}
        </h2>
        <div>
          <textarea
            value={rejectComment}
            onChange={(e) => {
              setRejectComment(e.target.value);
            }}
            placeholder="Please provide a reason for rejection"
            className="custom-style-reject-model"
          ></textarea>
        </div>
        <div style={{ marginTop: "1rem" }} className="category_button ">
          <button
            style={{ width: "100px", borderRadius: "6px" }}
            onClick={()=>handleSubmit(rejectComment)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default RejectModal;
