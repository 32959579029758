import Header from '../../Components/Header/Header';
import CustomDropdown from '../../Components/DropDown/CustomDropdown';
import { Line } from 'react-chartjs-2';
import Colors from '../../Constants/Colors';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UpcomingEventsScreen from '../../Components/CustomDashboardScreen/UpcomingEventsScreen';
import {
  apiCallDAshAdmin_ArtRunner_Count,
  apiCallDashArtLover,
  apiCallDashArtistCount,
  apiCallGetAllEvent,
  apiCallGetEventStatus,
  onAPiCallAdminGetDashboardEventCount
} from '../../Core/Apicall'
import { useDispatch } from 'react-redux';
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions';
import EmptyData from '../../Components/EmptyData/EmptyData';
import { Chart } from 'chart.js/auto';
import { CustomCommonBox } from '../../Components/CustomCommonBox/CustomCommonBox';
import './style.css';

const optionsChart = {
  scales: {
    x: {
      display: false
    },
    y: {
      display: false
    }
  },
  plugins: {
    title: {
      display: false
    },
    legend: {
      display: false
    }
  }
}
function Dashboard () {
  const [artistChartData, setArtistChartData] = useState()
  const [artistChartDataTotal, setArtistChartDataTotal] = useState()
  const [userChartData, setUserChartData] = useState()
  const [userChartDataTotal, setUserChartDataTotal] = useState()
  const [artRunnerChartData, setArtRunnerChartData] = useState()
  const [artRunnerChartDataTotal, seArtRunnerChartDataTotal] = useState()
  const [userDropdownLabel, setUserDropdownLabel] = useState('Today')
  const [artistDropdownLabel, setArtistDropdownLabel] = useState('Today')
  const [artRunnerDropdownLabel, setArtRunnerDropdownLabel] = useState('Today')
  const [todayEventListData, setTodayEventListData] = useState()
  const [todayTotalCount, setTodayTotalCount] = useState(0)
  const [upcomingTotalCount, setUpcomingTotalCount] = useState(0)
  const [pastTotalCount, setPastTotalCount] = useState(0)
  const [featuredTotalCount, setFeaturedTotalCount] = useState(0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dropdownFilterData = ['Today', 'This Week', 'This Month']
  useEffect(() => {
    onApiEventTypeList('Today')
    onApiCallDashBoardCount()
    onApiCallArtistChart('Today')
    onApiCallUserChart('Today')
    onApiCallArtRunnerChart('Today')
  }, [])

  const onApiCallDashBoardCount = async () => {
    dispatch(setLoadingTrue())
    try {
      const response = await onAPiCallAdminGetDashboardEventCount()
      setTodayTotalCount(response?.totalTodayEvent)
      setUpcomingTotalCount(response?.totalUpcomingEvent)
      setPastTotalCount(response?.totalPastEvent)
      setFeaturedTotalCount(response?.totalFeaturedEvent)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const onApiEventTypeList = async (item, selectedPage) => {
    dispatch(setLoadingTrue())
    const param = {
      status: null,
      weekSet: item,
      offset: selectedPage?.selected + 1 || 1,
      limit: 10
    }
    try {
      const response = await apiCallGetAllEvent(param)
      setTodayEventListData(response.data)
      dispatch(setLoadingFalse())
    } catch (error) {
      console.error('Error fetching event types:', error)
      dispatch(setLoadingFalse())
    }
  }

  const onApiCallArtistChart = async item => {
    dispatch(setLoadingTrue())
    const param = {
      weekSet: item
    }
    try {
      const res = await apiCallDashArtistCount(param)
      let initialTaxChartData = {
        labels: ['1', '2'],
        datasets: [
          {
            responsive: true,
            data: [0, res?.totalCount],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: Colors.mainOrange40,
            borderColor: Colors.mainOrange,
            borderWidth: 1.5,
            lineTension: 0.4,
            fill: true
          }
        ]
      }
      setArtistChartData(initialTaxChartData)
      setArtistChartDataTotal(res?.totalCount)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const onApiCallUserChart = async item => {
    dispatch(setLoadingTrue())
    const param = {
      weekSet: item
    }
    try {
      const res = await apiCallDashArtLover(param)
      let initialTaxChartData = {
        labels: ['1', '2'],
        datasets: [
          {
            responsive: true,
            data: [0, res?.totalCount],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: Colors.mainBlue40,
            borderColor: Colors.mainBlue,
            borderWidth: 1.5,
            lineTension: 0.4,
            fill: true
          }
        ]
      }
      setUserChartData(initialTaxChartData)
      setUserChartDataTotal(res?.totalCount)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const onApiCallArtRunnerChart = async item => {
    dispatch(setLoadingTrue())
    const param = {
      weekSet: item
    }
    try {
      const res = await apiCallDAshAdmin_ArtRunner_Count(param)
      let initialTaxChartData = {
        labels: ['1', '2'],
        datasets: [
          {
            responsive: true,
            data: [0, res?.totalCount],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: Colors.mainGreen01,
            borderColor: Colors.mainGreen,
            borderWidth: 1.5,
            lineTension: 0.4,
            fill: true
          }
        ]
      }
      setArtRunnerChartData(initialTaxChartData)
      seArtRunnerChartDataTotal(res?.totalCount)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const onClickUserDropdownChart = item => {
    setUserDropdownLabel(item)
    onApiCallUserChart(
      item == 'This Week'
        ? 'ThisWeekend'
        : item == 'This Month'
        ? 'ThisMonth'
        : 'Today'
    )
  }
  const onClickArtistDropdownChart = item => {
    setArtistDropdownLabel(item)
    onApiCallArtistChart(
      item == 'This Week'
        ? 'ThisWeekend'
        : item == 'This Month'
        ? 'ThisMonth'
        : 'Today'
    )
  }
  const onClickArtRunnerDropdownChart = item => {
    setArtRunnerDropdownLabel(item)
    onApiCallArtRunnerChart(
      item == 'This Week'
        ? 'ThisWeekend'
        : item == 'This Month'
        ? 'ThisMonth'
        : 'Today'
    )
  }

  const handelEnabled = async data => {
    dispatch(setLoadingTrue())
    const param = {
      eventId: data.eventId,
      isEnabled: !data.isEnabled
    }
    try {
      const res = await apiCallGetEventStatus(param)
      onApiEventTypeList('Today')
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const onClickTabOpen = item => {
    const event = new CustomEvent('subTabEventListner', {
      detail: item
    })
    window.dispatchEvent(event)
    navigate(item)
  }
  const eventTabs = [
    {
      label: "Today's event",
      color: Colors.darkBlue,
      count: todayTotalCount,
      route: '/TodayEvents'
    },
    {
      label: 'Total Upcoming events',
      color: Colors.darkGreen,
      count: upcomingTotalCount,
      route: '/UpcomingEvent'
    },
    {
      label: 'Past Events',
      color: Colors.darkOrange,
      count: pastTotalCount,
      route: '/PastEvent'
    },
    {
      label: 'Featured Events',
      color: Colors.darkRed,
      count: featuredTotalCount,
      route: '/FeaturedEvent'
    }
  ]
  return (
    <div>
      <Header name={'Dashboard'} />
      <div className='min-Dashboard'>
        <div className='min-Dashboard-1'>
          <div className='min-Dashboard-1-1'>
            {eventTabs.map(tab => (
              <CustomCommonBox
                key={tab.route}
                route={tab.route}
                label={tab.label}
                onClickTabOpen={onClickTabOpen}
                color={tab.color}
                count={tab.count}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='User_show'>
        <div className='card-style'>
          <div className='card-flex-column'>
            <div className='card-flex-row'>
              <div className='revenueMainDiv'>User</div>
              <div className='revenueTaxActiveMainDivDropdown'>
                <CustomDropdown
                  dropDownLabel={userDropdownLabel}
                  items={dropdownFilterData}
                  onSelect={item => onClickUserDropdownChart(item)}
                />
              </div>
            </div>
            <div className='revenueValueDiv'> {userChartDataTotal}</div>
            {userChartData && (
              <Line height={50} data={userChartData} options={optionsChart} />
            )}
          </div>
        </div>
        <div className='card-style'>
          <div className='card-flex-column'>
            <div className='card-flex-row'>
              <div className='revenueMainDiv'>Artists</div>
              <div className='revenueTaxActiveMainDivDropdown'>
                <CustomDropdown
                  dropDownLabel={artistDropdownLabel}
                  items={dropdownFilterData}
                  onSelect={item => onClickArtistDropdownChart(item)}
                />
              </div>
            </div>
            <div className='revenueValueDiv'>{artistChartDataTotal}</div>
            {artistChartData && (
              <Line height={50} data={artistChartData} options={optionsChart} />
            )}
          </div>
        </div>

        <div className='card-style'>
          <div className='card-flex-column'>
            <div className='card-flex-row'>
              <div className='revenueMainDiv'>ArtRunner</div>
              <div className='revenueTaxActiveMainDivDropdown'>
                <CustomDropdown
                  dropDownLabel={artRunnerDropdownLabel}
                  items={dropdownFilterData}
                  onSelect={item => onClickArtRunnerDropdownChart(item)}
                />
              </div>
            </div>
            <div className='revenueValueDiv'>{artRunnerChartDataTotal}</div>
            {artRunnerChartData && (
              <Line
                height={50}
                data={artRunnerChartData}
                options={optionsChart}
              />
            )}
          </div>
        </div>
      </div>
      <div className='mt-20'>
        <div>
          <div className='upcoming_list'>
            <div className='card-container'>
              <div className='center-row'>
                <div className='flex-row-start'>
                  <div className='astrologerText'>Today Events List</div>
                </div>
              </div>
              <div className='stretch-flex-container'>
                <div className='custom-flex-container'>
                  <div className='lastWeekEndDivGreyColor w-15'>image</div>
                  <div className='lastWeekEndDivGreyColor w-15'>Name</div>
                  <div className='lastWeekEndDivGreyColor w-13'>
                    Events Name
                  </div>

                  <div className='lastWeekEndDivGreyColor w-20'>
                    Date & times
                  </div>
                  <div className='lastWeekEndDivGreyColor w-10'>Edit</div>
                  <div className='lastWeekEndDivGreyColor w-20'>Status</div>
                </div>

                {todayEventListData?.length == 0 ? (
                  <div className='text-center-full-width'>
                    <EmptyData />
                  </div>
                ) : (
                  <div
                    style={{ maxHeight: '900px' }}
                    className='scrollable-container'
                  >
                    <UpcomingEventsScreen
                      handelEnabled={handelEnabled}
                      data={todayEventListData}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Dashboard
