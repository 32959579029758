import { StyleSheet } from 'react-native'
import Colors from '../Constants/Colors'

const styles = StyleSheet.create({
  EventUpdate: {
    padding: '40px',
    paddingTop: '10px',
    borderRadius: '10px'
  },
  CustomDrop: {
    marginBottom: '20px',
    boxSizing: 'border-box'
  },
  EventDate: {
    display: 'flex',
    gap: '20px'
  },
  line: {
    width: '100%',
    height: '2px',
    backgroundColor: Colors.dark_black,
    marginBottom: '20px'
  },
  checkbox: {
    marginRight: 10
  },
  label: {
    fontSize: '14px',
    color: '#818181'
  },
  labelPhoto: {
    fontSize: '14px',
    color: 'white',
    width: '200px'
  },

  eventSubmit: {
    cursor: 'pointer',
    backgroundColor: Colors.ReddRak,
    padding: '15px',
    borderRadius: '5px',
    textAlign: 'center',
    width: '100%'
  },
  startDate: {
    display: 'flex',
    paddingLeft: '20px',
    alignItems: 'center',
    paddingTop: '10px',
    gap: '10px'
  },
  TicketDate: {
    display: 'flex',
    paddingLeft: '20px',
    alignItems: 'center',
    paddingTop: '10px',
    gap: '10',
    justifyContent: 'space-between',
    width: '560px'
  },
  timePicker: {
    position: 'absolute',
    zIndex: '999'
  },
  customText_ad: {
    color: '#818181'
  },
  menu_ic: {
    width: '100%',
    borderRadius: '16px',
    backgroundColor: Colors.mainDarkColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '1rem',
    gap: '1rem',
    boxSizing: 'border-box'
  },
  HostDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3 ,3fr)',
    width: '100%',
    gap: '20px'
  },
  HostDetails_1: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2 ,3fr)',
    width: '100%',
    gap: '20px'
  },
  labelPhotos: {
    color: 'white'
  }
})

export default styles
