import React, { useEffect } from 'react'
import Colors from '../../../../Constants/Colors'
import Icons from '../../../../Constants/Icons'
import { useState } from 'react'
import ArtProjectsModel from './ArtProjectsModel'
import {
  apiCallGetUserArtProject,
  apiCallPostToggleArtProjectStatus
} from '../../../../Core/Apicall'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../../Config/Actions/loadingActions'
import EmptyData from '../../../../Components/EmptyData/EmptyData'

const ArtProjects = ({ userId }) => {
  const isLoading = useSelector(state => state.isLoading)
  const dispatch = useDispatch()
  const [artProjectData, setArtProjectData] = useState([])
  const [ArtShow, setArtShow] = useState(false)
  const [mediaSelectData, setSelectMedia] = useState()

  useEffect(() => {
    apiCallGetArtProjectData()
  }, [])

  const onClickStatusChange = async item => {
    dispatch(setLoadingTrue())
    const param = {
      videoId: item.videoId,
      isEnabled: !item.isEnabled
    }
    try {
      await apiCallPostToggleArtProjectStatus(param)
      apiCallGetArtProjectData()
    } catch (error) {
      console.error('Error fetching event types:', error)
      dispatch(setLoadingFalse())
    }
  }

  const onClickMediaOpen = async item => {
    setArtShow(true)
    setSelectMedia(item)
  }

  const apiCallGetArtProjectData = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      userId: userId,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15
    }
    try {
      const res = await apiCallGetUserArtProject(param)
      setArtProjectData(res.data)
      dispatch(setLoadingFalse())
    } catch (error) {
      console.error('Error fetching event types:', error)
      dispatch(setLoadingFalse())
    }
  }

  return (
    <div>
      {ArtShow && (
        <ArtProjectsModel
          ArtShow={ArtShow}
          mediaSelectData={mediaSelectData}
          setArtShow={setArtShow}
        />
      )}
      <div className='achievements'>
        <div className='upcoming_list'>
          <div className='margin-top-20 achievement-container'>
            <div className='achievement-header'>
              <div className='achievement-title'>
                <div className='astrologerText'>Art Projects</div>
              </div>
            </div>
            <div className='achievement-content'>
              <div className='achievement-content-header'>
                <div className='lastWeekEndDivGreyColor w-15'>Title</div>
                <div className='lastWeekEndDivGreyColor w-35'>Description</div>
                <div className='lastWeekEndDivGreyColor w-15'>Media</div>
                <div className='lastWeekEndDivGreyColor w-15'>Status</div>
              </div>
              <div className='scrollable-container h-max-350'>
                {!isLoading && artProjectData.length == 0 ? (
                  <EmptyData />
                ) : (
                  artProjectData.map((item, index) => (
                    <div key={index}>
                      <div className='achievement-content-header'>
                        <div className='fontSize16WithoutFlex w-15'>
                          {item.title}
                        </div>
                        <div className='fontSize16WithoutFlex w-35'>
                          {item.description}
                        </div>
                        <div className='fontSize16WithoutFlex w-15'>
                          <img
                            src={Icons.all_m}
                            onClick={() => onClickMediaOpen(item)}
                            style={{
                              height: '35px',
                              filter: 'brightness(0) invert(1)',
                              cursor: 'pointer'
                            }}
                          />
                        </div>

                        <div className='fontSize16WithoutFlex w-15'>
                          <div className='user_Status pointer'>
                            <button
                              onClick={() => onClickStatusChange(item)}
                              className='button'
                              style={{
                                cursor: 'pointer',
                                backgroundColor: item.isEnabled
                                  ? Colors.mainGreen01
                                  : Colors.mainRed01,
                                color: item.isEnabled
                                  ? Colors.darkGreen
                                  : Colors.darkRed
                              }}
                            >
                              {item.isEnabled ? 'Activate' : 'Deactivate'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtProjects
