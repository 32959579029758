import React, { useState, useEffect, useRef } from 'react'
import Icons from '../../../Constants/Icons'
import CustomDropdown from '../../../Components/DropDown/CustomDropdown'
import Header from '../../../Components/Header/Header'
import styles from '../../Style'
import { Text } from 'react-native'
import Switch from '@mui/material/Switch'
import Colors from '../../../Constants/Colors'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddArtists from './AddArtists'
import {
  apiCallEditArtRunnerEvent,
  apiCallGetEventDetails,
  onAPiCallCreateArtRunnerEvent
} from '../../../Core/Apicall'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../Config/Actions/loadingActions'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import EventTypeModel from './EventTypeModel'
import ArtTypeModel from './ArtTypeModel'
import { CustomUploadProfilePic } from '../../../Core/CustomUploadProfilePic'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import styled from 'styled-components'
import Autocomplete from 'react-google-autocomplete'
import { useDispatch, useSelector } from 'react-redux'
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
`

const Checkbox = styled.div`
  padding: 0;
  background-color: transparent;
  border-width: 0;
  margin: 0;
  cursor: pointer;
`

const EventUpdate = () => {
  const googlePlaceAutoCompleteRef = useRef(null)
  const isProfileData = useSelector(state => state.isProfileData)
  const [imagePreview, setImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(true)
  const [isSelected, setSelection] = useState(false)
  const [imagePreviewEvent, setImagePreviewEvent] = useState([])
  const [StartTimeOPen, setStartTimeOpen] = useState(false)
  const [EndTimeOPen, setEndTimeOPen] = useState(false)
  const [DoorTimeOpen, setDoorTimeOpen] = useState(false)
  const [DoorTimeClock, setDoorTimeClock] = useState(null)
  const [StartTimeClock, setStartTimeClock] = useState(null)
  const [TimeOPen, setTimeOPen] = useState(false)
  const [TimeClock, setTimeClock] = useState(null)
  const [EndTimeClock, setEndTimeClock] = useState(null)
  const [isValidityToDate, setIsValidityDate] = useState('')
  const [weeklyDate, setWeeklyDate] = useState('')
  const [weeklyEventDate, setWeeklyEventDate] = useState('')
  const [CustomDate, setCustomDate] = useState('')
  const [TicketDate, setTicketDate] = useState('')
  const [ArtRunner, setArtRunner] = useState('Open Mic')
  const [ArtType, setArtType] = useState('Art Type')
  const [Monthly, setMonthly] = useState('')
  const [Public, setPublic] = useState('')
  const [Ticket, setTicket] = useState('')
  const [multiple, setMultiple] = useState(true)
  const [Tour, setTour] = useState(true)
  const [Workshop, setWorkshop] = useState(true)
  const [WorkshopPrice, setWorkshopPrice] = useState(false)
  const [Award, setAward] = useState('Yes')
  const [Personal, setPersonal] = useState(true)
  const [Notification, setNotification] = useState(false)
  const [VIPTicket, setVIPTicket] = useState('')
  const [EconomyTicket, setEconomyTicket] = useState('')
  const [WorkshopPrices, setWorkshopPrices] = useState('')
  const [expect, setExpect] = useState('')
  const [expect1, setExpect1] = useState('')
  const [expect2, setExpect2] = useState('')
  const [EventDetails, setEventDetails] = useState()
  const [EventUpdata, setEventUpdata] = useState()
  const [EventTypeid, setEventTypeid] = useState('65dc6a4e2b9eed8cc77271fc')
  const [BonusSpots, setBonusSpots] = useState(false)
  const [PersonallyApprove, setPersonallyApprove] = useState(false)
  const [Guests, setGuests] = useState(false)
  const [supportVenue, setSupportVenue] = useState(false)
  const [MultipleSignup, setMultipleSignup] = useState(false)
  const [saveSettings, setSaveSettings] = useState(true)
  const [ArtistId, setArtistId] = useState(null)
  const [categoryId, setCategoryId] = useState()
  const [AwardImage, setAwardImage] = useState(true)
  const [AwardPreview, setAwardPreview] = useState('')
  const [DescriptionName, setDescriptionName] = useState('')
  const [newEventName, setEventName] = useState('')
  const [VenueAddress, setVenueAddress] = useState('')
  const [VenueName, setVenueName] = useState('')
  const [NumberSpots, setNumberSpots] = useState('')
  const [NumberSpotsA, setNumberSpotsA] = useState('')
  const [Requirements, setRequirements] = useState('')
  const [LinkTicket, setLinkTicket] = useState('')
  const [statusState, setStatusState] = useState(true)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [visibility, setVisibility] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')

  const handleChange = date => {
    setIsValidityDate(date)
    setIsValid(prevState => ({
      ...prevState,
      isValidityToDate: true
    }))
  }
  const handleCheckboxClick = visibilityOption => {
    setVisibility(prevFormData => ({
      ...prevFormData,
      signupVisibility:
        prevFormData.signupVisibility === visibilityOption
          ? 0
          : visibilityOption
    }))
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleImageUploadEvent = async event => {
    const selectedImages = event.target.files
    const newImagesArray = []
    try {
      const maxImages = 4
      if (imagePreviewEvent.length + selectedImages.length > maxImages) {
        console.warn('Cannot add more than 2 images.')
        return
      }

      for (let i = 0; i < selectedImages.length; i++) {
        const formData = new FormData()
        const selectedImage = selectedImages[i]
        formData.append('imageURL', selectedImage)
        formData.append('imageType', 'IntrestCategoryImages')
        formData.append('imageStoreId', '123456789')
        try {
          const data = await CustomUploadProfilePic(formData)
          if (data && data.status === true) {
            newImagesArray.push(data.location)
          } else {
          }
        } catch (error) {
          console.error('Error uploading profile picture:', error)
        }
      }

      if (imagePreviewEvent.length + newImagesArray.length > maxImages) {
        console.warn('Cannot add more than 2 images.')
        return
      }

      setImagePreviewEvent([...imagePreviewEvent, ...newImagesArray])
    } catch (error) {
      console.error('Error handling images:', error)
    }
    setIsValid(prevState => ({
      ...prevState,
      imagePreviewEvent: true
    }))
  }
  const uploadImageRemove = index => {
    setImagePreviewEvent(prevImages => prevImages.filter((_, i) => i !== index))
  }
  const handleImageUpload = event => {
    const selectedImage = event.target.files[0]
    if (selectedImage) {
      const reader = new FileReader()
      reader.onload = () => {
        setImagePreview(reader.result)
        setUploadImage(true)
      }
      reader.readAsDataURL(selectedImage)
      console.log(selectedImage)
    }
  }
  const handleImageDelete = () => {
    setImagePreview(null)
    setImagePreview(false)
  }
  const AwardImageUpload = event => {
    const selectedImage = event.target.files[0]
    if (selectedImage) {
      const reader = new FileReader()
      reader.onload = () => {
        setAwardPreview(reader.result)
        setAwardImage(true)
      }
      reader.readAsDataURL(selectedImage)
      console.log(selectedImage)
    }
  }

  const location = useLocation()
  const ArtRunnerSelect = items => {
    if (items.eventTypeName === 'Open Mic') {
      setMultiple(true)
      setTour(true)
      setWorkshop(true)
      setWorkshopPrice(false)
      setPersonal(true)
      setNotification(false)
    } else if (items.eventTypeName === 'Showcase') {
      setMultiple(false)
      setTour(true)
      setWorkshopPrice(false)
      setWorkshop(true)
      setPersonal(true)
      setNotification(false)
    } else if (items.eventTypeName === 'Tour') {
      setTour(false)
      setMultiple(true)
      setWorkshopPrice(false)
      setWorkshop(true)
      setPersonal(true)
      setNotification(false)
    } else if (items.eventTypeName === 'Work Shop') {
      setMultiple(true)
      setTour(true)
      setWorkshopPrice(false)
      setPersonal(true)
      setNotification(false)
    } else if (items.eventTypeName === 'Personal') {
      setPersonal(false)
      setWorkshopPrice(false)
      setNotification(true)
      setTour(true)
    } else if (items.eventTypeName === 'Festival') {
      setMultiple(true)
      setTour(true)
      setWorkshop(true)
      setWorkshopPrice(false)
      setPersonal(true)
      setNotification(false)
    }

    setEventTypeid(items.eventTypeId)
    setArtRunner(items.eventTypeName)
  }

  useEffect(() => {
    if (location.state === null) {
      console.error('Location state is null')
      return
    }

    const eventId = location.state?.eventId

    if (eventId) {
      onApiCallUserDetails(eventId)
    } else {
      console.log('Event ID is null or undefined')
    }
  }, [location.state])

  const onApiCallUpdata = async () => {
    dispatch(setLoadingTrue())

    const param =
      location.state !== null
        ? {
            eventId: location.state.eventId,

            eventDetail: {
              eventTypeId: EventTypeid,
              eventName: newEventName,
              eventDescription: DescriptionName,
              eventImages: imagePreviewEvent,
              eventStartDate: moment(isValidityToDate).format('DD/MM/YYYY'),
              eventStartTime: convertedTime,
              eventDoorOpenTime: convertedDoorTime,
              eventEndTime: dayjs(EndTimeClock).format('hh:mm A'),
              eventVisibility: Public,
              requirements: ArtRunner === 'Tour' ? null : Requirements
            },
            venueDetail: {
              venueName: VenueName,
              venueAddress: VenueAddress
            },
            eventMode: {
              categoryId: categoryId,
              bonusSpots: ArtRunner === 'Tour' ? null : BonusSpots,
              noOfSpots:
                ArtRunner === 'Tour'
                  ? null
                  : NumberSpotsA && BonusSpots === false
                  ? null
                  : NumberSpotsA,
              extraBonusSpots:
                ArtRunner === 'Tour'
                  ? null
                  : NumberSpots && ArtRunner === 'Showcase'
                  ? null
                  : NumberSpots && BonusSpots === false
                  ? null
                  : NumberSpots
            },
            personalApproval: {
              allowGuests: ArtRunner === 'Tour' ? null : Guests,
              supportVenue: ArtRunner === 'Tour' ? null : supportVenue,
              allowMultipleSignup: ArtRunner === 'Tour' ? null : MultipleSignup
            },
            preAddedArtist: {
              artistId: ArtistId
            },
            scheduleEvent: {
              doRepeat: ArtRunner === 'Tour' ? null : statusState,
              repeatType: statusState === false ? null : Monthly,
              startDate:
                statusState === false
                  ? null
                  : moment(weeklyDate).format('DD/MM/YYYY') &&
                    Monthly === 'Custom'
                  ? moment(weeklyDate).format('DD/MM/YYYY')
                  : null,
              nextEvent:
                statusState === false
                  ? null
                  : moment(weeklyEventDate).format('DD/MM/YYYY') &&
                    Monthly === 'Custom'
                  ? moment(weeklyEventDate).format('DD/MM/YYYY')
                  : null
            },
            artistSignupVisibility: {
              signupVisibility:
                ArtRunner === 'Tour' ? null : visibility.signupVisibility,
              visibilityStartDate:
                visibility.signupVisibility === 3
                  ? moment(CustomDate).format('DD/MM/YYYY')
                  : null,
              visibilityStartTime:
                visibility.signupVisibility === 3
                  ? dayjs(TimeClock).format('hh:mm A')
                  : null,
              saveSettings: saveSettings
            },
            eventPricing: {
              eventEntry: Ticket,
              VIPPrice: Ticket === 'Free' ? null : VIPTicket,
              economyPrice: Ticket === 'Free' ? null : EconomyTicket,
              latestDateForPurchase: moment(TicketDate).format('DD/MM/YYYY'),
              ticketPageLink: LinkTicket
            },
            userId: EventDetails?.userId,
            exceptFirst: expect,
            exceptSecond: expect1,
            exceptThird: expect2,
            latitude: latitude,
            longitude: longitude,
            status: 'Submit'
          }
        : {
            eventDetail: {
              eventTypeId: EventTypeid,
              eventName: newEventName,
              eventDescription: DescriptionName,
              eventImages: imagePreviewEvent,
              eventStartDate: moment(isValidityToDate).format('DD/MM/YYYY'),
              eventStartTime: convertedTime,
              eventDoorOpenTime: convertedDoorTime,
              eventEndTime: dayjs(EndTimeClock).format('hh:mm A'),
              eventVisibility: Public,
              requirements: ArtRunner === 'Tour' ? null : Requirements
            },
            venueDetail: {
              venueName: VenueName,
              venueAddress: VenueAddress
            },
            eventMode: {
              categoryId: categoryId,
              bonusSpots: ArtRunner === 'Tour' ? null : BonusSpots,
              noOfSpots:
                ArtRunner === 'Tour'
                  ? null
                  : NumberSpotsA || BonusSpots === false
                  ? null
                  : NumberSpotsA,
              extraBonusSpots:
                ArtRunner === 'Tour'
                  ? null
                  : NumberSpots && ArtRunner === 'Showcase'
                  ? null
                  : NumberSpots
            },
            personalApproval: {
              allowGuests: ArtRunner === 'Tour' ? null : Guests,
              supportVenue: ArtRunner === 'Tour' ? null : supportVenue,
              allowMultipleSignup: ArtRunner === 'Tour' ? null : MultipleSignup
            },
            preAddedArtist: {
              artistId: ArtistId
            },
            scheduleEvent: {
              doRepeat: ArtRunner === 'Tour' ? null : statusState,
              repeatType:
                statusState === false || ArtRunner === 'Tour' ? null : Monthly,
              startDate:
                statusState === false
                  ? null
                  : moment(weeklyDate).format('DD/MM/YYYY') &&
                    Monthly === 'Custom'
                  ? moment(weeklyDate).format('DD/MM/YYYY')
                  : null,
              nextEvent:
                statusState === false
                  ? null
                  : moment(weeklyEventDate).format('DD/MM/YYYY') &&
                    Monthly === 'Custom'
                  ? moment(weeklyEventDate).format('DD/MM/YYYY')
                  : null
            },
            artistSignupVisibility: {
              signupVisibility:
                ArtRunner === 'Tour' ? null : visibility.signupVisibility,
              visibilityStartDate:
                visibility.signupVisibility === 3
                  ? moment(CustomDate).format('DD/MM/YYYY')
                  : null,
              visibilityStartTime:
                visibility.signupVisibility === 3
                  ? dayjs(TimeClock).format('hh:mm A')
                  : null,
              saveSettings: saveSettings
            },
            eventPricing: {
              eventEntry: Ticket,
              VIPPrice: Ticket === 'Free' ? null : VIPTicket,
              economyPrice: Ticket === 'Free' ? null : EconomyTicket,
              latestDateForPurchase: moment(TicketDate).format('DD/MM/YYYY'),
              ticketPageLink: LinkTicket
            },
            exceptFirst: expect,
            exceptSecond: expect1,
            exceptThird: expect2,
            latitude: latitude,
            longitude: longitude,
            status: 'Submit',
            createdBy: 'Admin'
          }

    if (location?.state?.AdminEvent) {
      param.userId = isProfileData.userData.userId
      param.createdBy = 'Admin'
    } else if (location?.state?.adminEventEdit) {
      param.userId = isProfileData.userData.userId
      param.createdBy = 'Admin'
    }

    try {
      let response
      if (location?.state?.AdminEvent) {
        response = await onAPiCallCreateArtRunnerEvent(param)
      } else if (location?.state?.adminEventEdit) {
        response = await apiCallEditArtRunnerEvent(param)
      } else {
        if (location.state === null) {
          response = await onAPiCallCreateArtRunnerEvent(param)
        } else {
          response = await apiCallEditArtRunnerEvent(param)
        }
      }
      setEventUpdata(response)

      setSuccessMessage('Form submitted successfully!')
      setShowSuccessPopup(true)
      setTimeout(() => {
        setShowSuccessPopup(false)
        navigate(-1)
        // navigate("/EventsManagement");
      }, 1000)
    } catch (error) {
      console.error('API Error:', error)
      alert('Admin is not able')
      console.log('param', param)
    } finally {
      dispatch(setLoadingFalse())
    }
  }
  const onApiCallUserDetails = async () => {
    dispatch(setLoadingTrue())
    const param = {
      eventId: location.state.eventId
    }
    try {
      const response = await apiCallGetEventDetails(param)
      setEventDetails(response.data)
    } catch (error) {
      console.error('API Error:', error)
    } finally {
      dispatch(setLoadingFalse())
    }
  }

  useEffect(() => {
    if (EventDetails != undefined) {
      ArtRunnerSelect({
        eventTypeName: EventDetails?.eventDetail.eventTypeName
      })
      setArtRunner(EventDetails?.eventDetail.eventTypeName)
      setEventName(EventDetails?.eventDetail.eventName)
      setDescriptionName(EventDetails?.eventDetail.eventDescription)
      setMonthly(EventDetails?.scheduleEvent.repeatType)
      setTicket(EventDetails?.eventPricing.eventEntry)
      setVIPTicket(EventDetails?.eventPricing.VIPPrice)
      setEconomyTicket(EventDetails?.eventPricing.economyPrice)
      setLinkTicket(EventDetails?.eventPricing.ticketPageLink)
      setNumberSpotsA(EventDetails?.eventMode.noOfSpots)
      setExpect(EventDetails?.exceptFirst)
      setExpect1(EventDetails?.exceptSecond)
      setExpect2(EventDetails?.exceptThird)
      setPublic(EventDetails?.eventDetail.eventVisibility)
      setVenueAddress(EventDetails?.venueDetail.venueAddress)
      setVenueName(EventDetails?.venueDetail.venueName)
      setRequirements(EventDetails?.eventDetail.requirements)
      setImagePreviewEvent(EventDetails?.eventDetail.eventImages)
      setEventTypeid(EventDetails?.eventDetail.eventTypeId)
      setBonusSpots(EventDetails?.eventMode.bonusSpots)
      setMultipleSignup(EventDetails?.personalApproval.allowMultipleSignup)
      setGuests(EventDetails?.personalApproval.allowGuests)
      setSupportVenue(EventDetails?.personalApproval.supportVenue)
      setStatusState(EventDetails?.scheduleEvent.doRepeat)
      setSaveSettings(EventDetails?.artistSignupVisibility.saveSettings)
      setNumberSpots(EventDetails?.eventMode.extraBonusSpots)
      setLatitude(EventDetails?.latitude)
      setLongitude(EventDetails?.longitude)
      setTicketDate(
        EventDetails?.eventPricing.latestDateForPurchase === null
          ? ''
          : moment(
              EventDetails?.eventPricing.latestDateForPurchase,
              'DD-MM-YYYY'
            ).format('MM-DD-YYYY')
      )
      setIsValidityDate(
        EventDetails?.eventDetail.eventStartDate === null
          ? ''
          : moment(
              EventDetails?.eventDetail.eventStartDate,
              'DD-MM-YYYY'
            ).format('MM-DD-YYYY')
      )
      setArtType(
        EventDetails?.eventMode.categoryName === ''
          ? 'Art Type'
          : EventDetails?.eventMode.categoryName
      )
      setCategoryId(EventDetails?.eventMode.categoryId)
      setArtistId(
        EventDetails?.preAddedArtist?.artistsDetails.map(
          item => item.artistId
        ) ?? []
      )
      setCustomDate(
        EventDetails?.artistSignupVisibility.visibilityStartDate === null
          ? ''
          : moment(
              EventDetails?.artistSignupVisibility.visibilityStartDate,
              'DD-MM-YYYY'
            ).format('MM-DD-YYYY')
      )
      setWeeklyDate(
        EventDetails?.scheduleEvent.nextEvent === null
          ? ''
          : moment(EventDetails?.scheduleEvent.nextEvent, 'DD-MM-YYYY').format(
              'MM-DD-YYYY'
            )
      )
      setWeeklyEventDate(
        EventDetails?.scheduleEvent.startDate === null
          ? ''
          : moment(EventDetails?.scheduleEvent.startDate, 'DD-MM-YYYY').format(
              'MM-DD-YYYY'
            )
      )
      setStartTimeClock(
        dayjs(moment(EventDetails?.eventDetail.eventStartTime, 'hh:mm A'))
      )
      setDoorTimeClock(
        dayjs(moment(EventDetails?.eventDetail.eventDoorOpenTime, 'hh:mm:ss'))
      )
      setEndTimeClock(
        dayjs(moment(EventDetails?.eventDetail.eventEndTime, 'hh:mm:ss'))
      )
      setTimeClock(
        dayjs(
          moment(
            EventDetails?.artistSignupVisibility.visibilityStartTime,
            'hh:mm:ss'
          )
        )
      )
      setVisibility({
        signupVisibility: EventDetails?.artistSignupVisibility.signupVisibility
      })
    }
  }, [EventDetails])
  const [isValid, setIsValid] = useState({
    EventName: true,
    descriptionName: true,
    artType: true,
    isValidityToDate: true,
    StartTimeClock: true,
    DoorTimeClock: true,
    EndTimeClock: true,
    VenueName: true,
    VenueAddress: true,
    NumberSpots: true,
    NumberSpotsA: true,
    ArtistId: true,
    Requirements: true,
    Monthly: true,
    weeklyEventDate: true,
    Public: true,
    CustomDate: true,
    weeklyDate: true,
    TimeClock: true,
    Ticket: true,
    VIPTicket: true,
    EconomyTicket: true,
    TicketDate: true,
    LinkTicket: true,
    expect: true,
    expect1: true,
    expect2: true,
    imagePreviewEvent: true,
    ArtRunner: true
  })
  const handleSubmit = async e => {
    let isValidForm = true
    if (ArtRunner === 'Event Type' || ArtRunner === null) {
      setIsValid(prevState => ({
        ...prevState,
        ArtRunner: false
      }))
      isValidForm = false
    } else {
      setIsValid(prevState => ({
        ...prevState,
        ArtRunner: true
      }))
    }
    if (ArtType === 'Art Type') {
      setIsValid(prevState => ({
        ...prevState,
        artType: false
      }))
      isValidForm = false
    } else {
      setIsValid(prevState => ({
        ...prevState,
        artType: true
      }))
    }
    const checkField = (field, fieldName) => {
      if (
        field === '' ||
        field === null ||
        (Array.isArray(field) && field.length === 0)
      ) {
        setIsValid(prevState => ({
          ...prevState,
          [fieldName]: false
        }))
        isValidForm = false
      } else {
        setIsValid(prevState => ({
          ...prevState,
          [fieldName]: true
        }))
      }
    }

    if (
      ['Tour', 'Showcase', 'Work Shop', 'Open Mic', 'Personal'].includes(
        ArtRunner
      )
    ) {
      checkField(ArtType, 'Art Type')
      checkField(newEventName, 'EventName')
      checkField(DescriptionName, 'descriptionName')
      checkField(isValidityToDate, 'isValidityToDate')

      if (
        !StartTimeClock ||
        dayjs(StartTimeClock).format() === 'Invalid Date' ||
        StartTimeClock === null
      ) {
        setIsValid(prevState => ({
          ...prevState,
          StartTimeClock: false
        }))
        isValidForm = false
      } else {
        setIsValid(prevState => ({
          ...prevState,
          StartTimeClock: true
        }))
      }
    }

    if (['Tour', 'Showcase', 'Work Shop', 'Open Mic'].includes(ArtRunner)) {
      if (
        !DoorTimeClock ||
        dayjs(DoorTimeClock).format() === 'Invalid Date' ||
        DoorTimeClock === null
      ) {
        setIsValid(prevState => ({
          ...prevState,
          DoorTimeClock: false
        }))
        isValidForm = false
      } else {
        setIsValid(prevState => ({
          ...prevState,
          DoorTimeClock: true
        }))
      }

      if (
        !EndTimeClock ||
        dayjs(EndTimeClock).format() === 'Invalid Date' ||
        EndTimeClock === null
      ) {
        setIsValid(prevState => ({
          ...prevState,
          EndTimeClock: false
        }))
        isValidForm = false
      } else {
        setIsValid(prevState => ({
          ...prevState,
          EndTimeClock: true
        }))
      }

      checkField(VenueName, 'VenueName')
      checkField(VenueAddress, 'VenueAddress')
    }
    if (BonusSpots === true) {
      if (['Open Mic', 'Work Shop'].includes(ArtRunner)) {
        checkField(NumberSpots, 'NumberSpots')
      }

      if (['Showcase', 'Work Shop', 'Open Mic'].includes(ArtRunner)) {
        checkField(NumberSpotsA, 'NumberSpotsA')
      }
    }

    if (['Tour', 'Showcase', 'Work Shop', 'Open Mic'].includes(ArtRunner)) {
      checkField(ArtistId, 'ArtistId')
    }

    if (['Showcase', 'Work Shop', 'Open Mic'].includes(ArtRunner)) {
      checkField(Requirements, 'Requirements')
    }

    if (['Showcase', 'Work Shop', 'Open Mic', 'Personal'].includes(ArtRunner)) {
      if (statusState === true) {
        checkField(Monthly, 'Monthly')

        if (Monthly === 'Custom') {
          checkField(weeklyDate, 'weeklyDate')
          checkField(weeklyEventDate, 'weeklyEventDate')
        }
      }
    }

    checkField(Public, 'Public')

    if (visibility.signupVisibility === 3) {
      checkField(CustomDate, 'CustomDate')

      if (
        !TimeClock ||
        dayjs(TimeClock).format() === 'Invalid Date' ||
        TimeClock === null
      ) {
        setIsValid(prevState => ({
          ...prevState,
          TimeClock: false
        }))
        isValidForm = false
      } else {
        setIsValid(prevState => ({
          ...prevState,
          TimeClock: true
        }))
      }
    }

    checkField(Ticket, 'Ticket')

    if (Ticket === 'Ticket') {
      checkField(VIPTicket, 'VIPTicket')
      checkField(EconomyTicket, 'EconomyTicket')
    }

    checkField(TicketDate, 'TicketDate')
    checkField(LinkTicket, 'LinkTicket')
    checkField(expect, 'expect')
    checkField(expect1, 'expect1')
    checkField(expect2, 'expect2')
    checkField(imagePreviewEvent || [], 'imagePreviewEvent')

    if (isValidForm) {
      try {
        console.log('Calling onApiCallUpdata')
        await onApiCallUpdata()
        console.log('onApiCallUpdata success')
      } catch (error) {
        console.error('Error in onApiCallUpdata:', error)
        dispatch(setLoadingFalse())
      }
    }
  }

  const handleEventName = e => {
    setEventName(e.target.value)
    setIsValid(prevState => ({
      ...prevState,
      EventName: true
    }))
  }

  const handleVenueName = e => {
    setVenueName(e.target.value)
    setIsValid(prevState => ({
      ...prevState,
      VenueName: true
    }))
  }
  const handleDescriptionName = e => {
    setDescriptionName(e.target.value)
    setIsValid(prevState => ({
      ...prevState,
      descriptionName: true
    }))
  }
  const handleArtistId = () => {
    setIsValid(prevState => ({
      ...prevState,
      ArtistId: true
    }))
  }
  const black_handle = () => {
    navigate(-1)
  }

  const formatTime = time => {
    const formattedTime = time.format('hh:mm A')
    return formattedTime === '00:00 AM' ? '12:00 AM' : formattedTime
  }
  function convertTo24Hour (time12) {
    if (!time12) {
      return ''
    }
    return dayjs(time12, 'h:mma').format('HH:mm')
  }
  const convertedTime = convertTo24Hour(StartTimeClock)
  const convertedDoorTime = convertTo24Hour(DoorTimeClock)

  return (
    <div>
      <Header
        name={
          location?.state?.AdminEvent
            ? 'Admin Event Add'
            : location?.state?.adminEventEdit
            ? 'Admin Event Edit'
            : 'Event Edit'
        }
      />

      <div></div>
      <div className='EventUpdate' style={styles.EventUpdate}>
        <button className='close_icon' onClick={black_handle}>
          X
        </button>
        <div className='modal-content bg-Blue grid p-4 relative'>
          <h2>
            {location?.state?.AdminEvent
              ? 'Admin Event Add'
              : location?.state?.adminEventEdit
              ? 'Admin Event Edit'
              : 'Event Edit'}
          </h2>

          <div
            className={`Category_name Subcategory_drop ${
              isValid.ArtRunner ? 'valid' : 'invalid'
            }`}
            style={styles.CustomDrop}
          >
            <EventTypeModel
              dropDownLabel={
                ArtRunner === null || ArtRunner === ''
                  ? 'Event Type '
                  : ArtRunner
              }
              color='blue'
              onSelect={ArtRunnerSelect}
              customLabelStyle={{
                fontWeight: '400',
                color: 'white',
                width: '100%'
              }}
            />
          </div>

          <div
            className={`Category_name Subcategory_drop ${
              isValid.artType ? 'valid' : 'invalid'
            }`}
            style={styles.CustomDrop}
          >
            <ArtTypeModel
              dropDownLabel={
                ArtType === 'Art Type' ? (
                  <div style={{ color: 'rgb(129, 129, 129)' }}>Art Type</div>
                ) : (
                  ArtType
                )
              }
              color='blue'
              onSelect={items => {
                setArtType(items.categoryName)
                setCategoryId(items.categoryId)
                setIsValid(prevState => ({
                  ...prevState,
                  artType: true
                }))
              }}
              customLabelStyle={{
                fontWeight: '400',
                color: 'white',
                width: '100%'
              }}
            />
          </div>
          <div
            className={`Category_name ${
              isValid.EventName ? 'valid' : 'invalid'
            }`}
            style={{ marginBottom: '20px', height: '50px' }}
          >
            <input
              className='Event_input'
              type='text'
              value={newEventName}
              onChange={handleEventName}
              placeholder='Event Name'
            />
          </div>

          <div
            className={`Category_name ${
              isValid.descriptionName ? 'valid' : 'invalid'
            }`}
            style={{ marginBottom: '20px', height: '50px' }}
          >
            <input
              className='Event_input'
              type='text'
              value={DescriptionName}
              onChange={handleDescriptionName}
              placeholder='Description'
            />
          </div>

          <div style={styles.EventDate}>
            <div className='label_data'>Start Date</div>
            <div className='label_data'>Start Time</div>
          </div>
          <div style={styles.EventDate}>
            <div
              className={`Category_name  ${
                isValid.isValidityToDate ? 'valid' : 'invalid'
              }`}
              style={{ marginBottom: '20px', width: '100%', zIndex: '2' }}
            >
              <DatePicker
                selected={isValidityToDate}
                minDate={new Date()}
                dateFormat='dd/MM/yyyy'
                placeholderText='Start Date'
                showPopperArrow={false}
                onChange={handleChange}
                showTimeSelect={false}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                customInput={
                  <div style={styles.startDate}>
                    <img
                      src={Icons.ic_date}
                      style={{ height: '20px' }}
                      alt='calendar icon'
                    />
                    <div className='fontSize16'>
                      {isValidityToDate ? (
                        format(isValidityToDate, 'dd/MM/yyyy')
                      ) : (
                        <div style={styles.customText_ad}>Start Date</div>
                      )}
                    </div>
                  </div>
                }
              />
            </div>

            <div
              className={`Category_name  ${
                isValid.StartTimeClock ? 'valid' : 'invalid'
              }`}
              style={{ marginBottom: '20px', width: '100%' }}
            >
              <div style={styles.timePicker}>
                {StartTimeOPen && (
                  <div>
                    <div
                      onClick={() => setStartTimeOpen(false)}
                      className='time_date'
                    ></div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticTimePicker
                        sx={{
                          borderRadius: 5,
                          margin: 0,
                          height: null,
                          width: null
                        }}
                        ampm={true}
                        value={
                          StartTimeClock === null
                            ? dayjs('00:00', 'HH:mm')
                            : StartTimeClock
                        }
                        onClose={() => setStartTimeOpen(false)}
                        onAccept={newTime => {
                          setStartTimeClock(newTime)
                          setStartTimeOpen(false)
                          setIsValid(prevState => ({
                            ...prevState,
                            StartTimeClock: true
                          }))
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
              <div
                style={styles.startDate}
                onClick={() => setStartTimeOpen(true)}
              >
                <img src={Icons.ic_time} style={{ height: '20px' }} />
                <div style={{ fontSize: '12px' }}>
                  {StartTimeClock && StartTimeClock.isValid() ? (
                    formatTime(StartTimeClock)
                  ) : (
                    <div style={styles.customText_ad}>Start Time</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {Personal && (
            <>
              <div style={styles.EventDate}>
                <div className='label_data'>Door Opens</div>
                <div className='label_data'>End Time</div>
              </div>
              <div style={styles.EventDate}>
                <div
                  className={`Category_name  ${
                    isValid.DoorTimeClock ? 'valid' : 'invalid'
                  }`}
                  style={{ marginBottom: '20px', width: '100%' }}
                >
                  <div style={styles.timePicker}>
                    {DoorTimeOpen && (
                      <div>
                        <div
                          onClick={() => setDoorTimeOpen(false)}
                          className='time_date'
                        ></div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticTimePicker
                            sx={{
                              borderRadius: 5,
                              margin: 0,
                              height: null,
                              width: null
                            }}
                            ampm={true}
                            minTime={StartTimeClock}
                            value={
                              DoorTimeClock === null
                                ? dayjs('00:00', 'HH:mm')
                                : DoorTimeClock
                            }
                            onClose={() => setDoorTimeOpen(false)}
                            onAccept={newTime => {
                              setDoorTimeClock(newTime)
                              setIsValid(prevState => ({
                                ...prevState,
                                DoorTimeClock: true
                              }))
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </div>
                  <div
                    style={styles.startDate}
                    onClick={() => setDoorTimeOpen(true)}
                  >
                    <img src={Icons.ic_door} style={{ height: '20px' }} />
                    <div style={{ fontSize: '12px' }}>
                      {DoorTimeClock && DoorTimeClock.isValid() ? (
                        formatTime(DoorTimeClock)
                      ) : (
                        <div style={styles.customText_ad}>Door Opens</div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`Category_name  ${
                    isValid.EndTimeClock ? 'valid' : 'invalid'
                  }`}
                  style={{ marginBottom: '20px', width: '100%' }}
                >
                  <div style={styles.timePicker}>
                    {EndTimeOPen && (
                      <div>
                        <div
                          onClick={() => setEndTimeOPen(false)}
                          className='time_date'
                        ></div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticTimePicker
                            sx={{
                              borderRadius: 5,
                              margin: 0,
                              height: null,
                              width: null
                            }}
                            ampm={true}
                            minTime={DoorTimeClock}
                            value={
                              EndTimeClock === null
                                ? dayjs('00:00', 'HH:mm')
                                : EndTimeClock
                            }
                            onClose={() => setEndTimeOPen(false)}
                            onAccept={data => {
                              setEndTimeClock(data)
                              setEndTimeOPen(false)
                              setIsValid(prevState => ({
                                ...prevState,
                                EndTimeClock: true
                              }))
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </div>
                  <div
                    style={styles.startDate}
                    onClick={() => setEndTimeOPen(true)}
                  >
                    <img src={Icons.ic_time} style={{ height: '20px' }} />
                    <div style={{ fontSize: '12px' }}>
                      {EndTimeClock && EndTimeClock.isValid() ? (
                        formatTime(EndTimeClock)
                      ) : (
                        <div style={styles.customText_ad}>End Time</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ fontSize: '12px', marginBottom: '10px' }}>
                Venue Details{' '}
              </div>
              <div style={styles.EventDate}>
                <div
                  className={`Category_name  ${
                    isValid.VenueName ? 'valid' : 'invalid'
                  }`}
                  style={{ marginBottom: '10px', width: '100%' }}
                >
                  <input
                    type='text'
                    placeholder='Name'
                    value={VenueName}
                    onChange={handleVenueName}
                  />
                </div>
                <div
                  className={`Category_name Location_values ${
                    isValid.VenueAddress ? 'valid' : 'invalid'
                  }`}
                  style={{ marginBottom: '20px', width: '100%' }}
                >
                  <div style={{ paddingTop: '10px' }}>
                    <Autocomplete
                      apiKey='AIzaSyCmcI0swO1MNjQYUD7qktNT78-cDMBoi44'
                      onPlaceSelected={place => {
                        setLatitude(place.geometry.location.lat())
                        setLongitude(place.geometry.location.lng())
                        setVenueAddress(place.formatted_address)
                        setIsValid(prevState => ({
                          ...prevState,
                          VenueAddress: true
                        }))
                      }}
                      onPaste={e => {
                        e.preventDefault()
                      }}
                      onChange={e => setVenueAddress(e.target.value)}
                      defaultValue={VenueAddress}
                      placeholder='Address'
                      options={{
                        types: ['geocode', 'establishment']
                      }}
                      ref={googlePlaceAutoCompleteRef}
                    />
                  </div>
                </div>
              </div>
              {Tour && (
                <>
                  <div style={styles.line}></div>
                  {Workshop && (
                    <>
                      {multiple && (
                        <div
                          className={`Category_name  ${
                            isValid.NumberSpots ? 'valid' : 'invalid'
                          }`}
                          style={{ marginBottom: '20px', width: '30%' }}
                        >
                          <input
                            type='number'
                            placeholder='#Number of Spots'
                            value={NumberSpots}
                            onChange={e => {
                              setNumberSpots(e.target.value)
                              setIsValid(prevState => ({
                                ...prevState,
                                NumberSpots: true
                              }))
                            }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center ',
                          justifyContent: 'space-between',
                          marginBottom: '20px',
                          marginLeft: '20px'
                        }}
                      >
                        <div class='checkbox-container'>
                          <label
                            class='checkbox-container'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px'
                            }}
                          >
                            <input
                              type='checkbox'
                              checked={BonusSpots}
                              onClick={() =>
                                setBonusSpots(prevState => !prevState)
                              }
                              id='checkbox'
                            />
                            <span class='checkmark'></span>
                            <div
                              className={` ${
                                BonusSpots ? 'white_red' : 'white'
                              }`}
                              style={styles.label}
                            >
                              {BonusSpots
                                ? 'Bonus Spots (bucket)'
                                : 'Bonus Spots (bucket)'}
                            </div>
                          </label>
                        </div>
                        <div
                          className={`Category_name  ${
                            isValid.NumberSpotsA ? 'valid' : 'invalid'
                          }`}
                          style={{ width: '30%' }}
                        >
                          <input
                            type='number'
                            placeholder='Number of Spots'
                            value={NumberSpotsA}
                            onChange={e => {
                              setNumberSpotsA(e.target.value)
                              setIsValid(prevState => ({
                                ...prevState,
                                NumberSpotsA: true
                              }))
                            }}
                          />
                        </div>
                      </div>
                      <div style={styles.line} />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {Personal && (
            <>
              {Workshop && (
                <>
                  {Tour && (
                    <div style={{ marginBottom: '20px' }}>
                      <div class='checkbox-container'>
                        <label
                          class='checkbox-container'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px'
                          }}
                        >
                          <input
                            type='checkbox'
                            id='checkbox'
                            onClick={() =>
                              setPersonallyApprove(prevState => !prevState)
                            }
                            checked={PersonallyApprove}
                          />
                          <span class='checkmark'></span>
                          <Text class='approve_a' style={styles.labelPhotos}>
                            Personally approve Artist’s request:
                          </Text>
                        </label>
                        <div
                          className={`${
                            PersonallyApprove ? 'display-block' : 'display-none'
                          }`}
                        >
                          <label
                            class='checkbox-container'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '15px',
                              marginTop: '20px'
                            }}
                          >
                            <input
                              type='checkbox'
                              id='checkbox'
                              checked={Guests}
                              onClick={() => setGuests(prevState => !prevState)}
                            />
                            <span class='checkmark'></span>
                            <div style={{ display: 'flex', gap: '50px' }}>
                              <Text style={styles.labelPhoto}>
                                {isSelected ? 'Checked' : 'Guests'}
                              </Text>
                              <Text style={styles.label}>
                                {isSelected ? 'Checked' : 'Accompanying Guests'}
                              </Text>
                            </div>
                          </label>
                          <label
                            class='checkbox-container'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '15px',
                              marginTop: '8px'
                            }}
                          >
                            <input
                              type='checkbox'
                              id='checkbox'
                              checked={supportVenue}
                              onClick={() =>
                                setSupportVenue(prevState => !prevState)
                              }
                            />
                            <span class='checkmark'></span>
                            <div style={{ display: 'flex', gap: '50px' }}>
                              <Text style={styles.labelPhoto}>
                                {isSelected ? 'Checked' : 'Support Venue'}
                              </Text>
                              <Text style={styles.label}>
                                {isSelected
                                  ? 'Checked'
                                  : 'Make a purchase at the venue'}
                              </Text>
                            </div>
                          </label>
                          {multiple && (
                            <label
                              class='checkbox-container'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '15px',
                                marginTop: '8px'
                              }}
                            >
                              <input
                                type='checkbox'
                                id='checkbox'
                                checked={MultipleSignup}
                                onClick={() =>
                                  setMultipleSignup(prevState => !prevState)
                                }
                              />
                              <span class='checkmark'></span>

                              <div style={{ display: 'flex', gap: '50px' }}>
                                <Text style={styles.labelPhoto}>
                                  {isSelected
                                    ? 'Checked'
                                    : 'Allow multiple sign-up'}
                                </Text>
                                <Text style={styles.label}>
                                  {isSelected
                                    ? 'Checked'
                                    : 'Up to 3 events/artist'}
                                </Text>
                              </div>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  )}{' '}
                </>
              )}{' '}
            </>
          )}
          {Personal && (
            <>
              <div
                className={`Category_name AddArtists  ${
                  isValid.ArtistId ? 'valid' : 'invalid-id'
                }`}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  position: 'relative'
                }}
              >
                <div style={{ position: 'relative', zIndex: '1' }}>
                  <AddArtists
                    setArtistId={setArtistId}
                    ArtistId={ArtistId}
                    handleArtistId={handleArtistId}
                  />
                </div>
              </div>
            </>
          )}
          {Tour && (
            <>
              {Personal && (
                <>
                  <div
                    className={`Category_name   ${
                      isValid.Requirements ? 'valid' : 'invalid'
                    }`}
                    style={{
                      width: '100%',
                      marginBottom: '20px',
                      position: 'relative',
                      height: '154px'
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        color: 'white',
                        marginLeft: '20px',
                        paddingTop: '20px',
                        display: 'block'
                      }}
                    >
                      Requirements
                    </span>
                    <textarea
                      value={Requirements}
                      onChange={e => {
                        setRequirements(e.target.value)
                        setIsValid(prevState => ({
                          ...prevState,
                          Requirements: true
                        }))
                      }}
                      placeholder='Describes'
                      style={{
                        maxWidth: '100%',
                        minWidth: '100%',
                        maxHeight: '100%',
                        minHeight: '100%',
                        backgroundColor: 'transparent'
                      }}
                    ></textarea>
                  </div>
                </>
              )}
              <div className='Switch_event' style={{ marginBottom: '20px' }}>
                <Text style={styles.labelPhoto}>Repeat</Text>

                <Switch
                  color='primary'
                  name='Status'
                  onChange={() => setStatusState(prevCheck => !prevCheck)}
                  value={statusState}
                  checked={!!statusState}
                />
              </div>
              <div
                className={`${statusState ? 'display-block' : 'display-none'}`}
              >
                <div
                  className={`Category_name Subcategory_drop  ${
                    isValid.Monthly ? 'valid' : 'invalid'
                  }`}
                  style={styles.CustomDrop}
                >
                  <CustomDropdown
                    dropDownLabel={
                      Monthly === '' || Monthly === null ? (
                        <div style={{ color: 'rgb(129, 129, 129)' }}>
                          Repeat Type
                        </div>
                      ) : (
                        Monthly
                      )
                    }
                    items={['Weekly', 'BiWeekly', 'Monthly', 'Custom']}
                    color='blue'
                    onSelect={items => {
                      setMonthly(items)
                      setIsValid(prevState => ({
                        ...prevState,
                        Monthly: true
                      }))
                    }}
                    customLabelStyle={{
                      fontWeight: '400',
                      color: 'white',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
              {Monthly === 'Custom' && (
                <>
                  <div style={styles.EventDate}>
                    <div className='label_data'>End Date</div>
                    <div className='label_data'>Next Event</div>
                  </div>
                  <div
                    style={styles.EventDate}
                    className={`${
                      statusState ? 'display-block' : 'display-none'
                    }`}
                  >
                    <div
                      className={`Category_name   ${
                        isValid.weeklyDate ? 'valid' : 'invalid'
                      }`}
                      style={{ marginBottom: '20px', width: '100%' }}
                    >
                      <DatePicker
                        selected={weeklyDate}
                        dateFormat='yyyy/MM/dd'
                        placeholderText='Start Date'
                        showPopperArrow={false}
                        onChange={date => {
                          setWeeklyDate(date)
                          setIsValid(prevState => ({
                            ...prevState,
                            weeklyDate: true
                          }))
                        }}
                        showTimeSelect={false}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        customInput={
                          <div style={styles.startDate}>
                            <img
                              src={Icons.Ic_newdate}
                              style={{ height: '20px' }}
                            />
                            <div className='fontSize16'>
                              {weeklyDate ? (
                                format(weeklyDate, 'dd/MM/yyyy')
                              ) : (
                                <div style={styles.customText_ad}>End Date</div>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </div>

                    <div
                      className={`Category_name   ${
                        isValid.weeklyEventDate ? 'valid' : 'invalid'
                      }`}
                      style={{ marginBottom: '20px', width: '100%' }}
                    >
                      <DatePicker
                        selected={weeklyEventDate}
                        minDate={weeklyDate}
                        dateFormat='yyyy/MM/dd'
                        placeholderText='Start Date'
                        showPopperArrow={false}
                        onChange={date => {
                          setWeeklyEventDate(date)
                          setIsValid(prevState => ({
                            ...prevState,
                            weeklyEventDate: true
                          }))
                        }}
                        showTimeSelect={false}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        customInput={
                          <div style={styles.startDate}>
                            <img
                              src={Icons.Ic_newdate}
                              style={{ height: '20px' }}
                            />
                            <div className='fontSize16'>
                              {weeklyEventDate ? (
                                format(weeklyEventDate, 'dd/MM/yyyy')
                              ) : (
                                <div style={styles.customText_ad}>
                                  Next Event
                                </div>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </>
              )}{' '}
            </>
          )}
          {Personal && (
            <>
              <div style={styles.line} />
              <Text style={styles.labelPhoto}>Visibility</Text>
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                <div
                  className={`Category_name  Subcategory_drop ${
                    isValid.Public ? 'valid' : 'invalid'
                  }`}
                  style={styles.CustomDrop}
                >
                  <CustomDropdown
                    dropDownLabel={
                      Public === null || Public === '' ? (
                        <div style={{ color: 'rgb(129, 129, 129)' }}>
                          Event Visibility
                        </div>
                      ) : (
                        Public
                      )
                    }
                    items={['Public', 'Private']}
                    color='blue'
                    onSelect={items => {
                      setPublic(items)
                      setIsValid(prevState => ({
                        ...prevState,
                        Public: true
                      }))
                    }}
                    customLabelStyle={{
                      fontWeight: '400',
                      color: 'white',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {Workshop && (
            <>
              {Tour && (
                <>
                  {Personal && (
                    <div
                      style={{ marginBottom: '20px' }}
                      className='Visibility'
                    >
                      <Text style={styles.labelPhoto}>
                        Visibility to Artists for signing-up
                      </Text>

                      <CheckboxContainer>
                        <Checkbox onClick={() => handleCheckboxClick(2)}>
                          {visibility.signupVisibility === 2 ? (
                            <div className='checkbox_F'></div>
                          ) : (
                            <div className='checkbox_d'></div>
                          )}
                        </Checkbox>
                        <Text style={styles.labelPhoto}>Always</Text>
                      </CheckboxContainer>

                      <CheckboxContainer>
                        <Checkbox onClick={() => handleCheckboxClick(1)}>
                          {visibility.signupVisibility === 1 ? (
                            <div className='checkbox_F'></div>
                          ) : (
                            <div className='checkbox_d'></div>
                          )}
                        </Checkbox>
                        <Text style={styles.labelPhoto}>
                          1 week before the event
                        </Text>
                      </CheckboxContainer>

                      <CheckboxContainer>
                        <Checkbox onClick={() => handleCheckboxClick(3)}>
                          {visibility.signupVisibility === 3 ? (
                            <div className='checkbox_F'></div>
                          ) : (
                            <div className='checkbox_d'></div>
                          )}
                        </Checkbox>
                        <Text style={styles.labelPhoto}>Custom</Text>
                      </CheckboxContainer>
                    </div>
                  )}
                  {Personal && (
                    <>
                      {visibility.signupVisibility === 3 && (
                        <div style={styles.EventDate}>
                          <div
                            className={`Category_name   ${
                              isValid.CustomDate ? 'valid' : 'invalid'
                            }`}
                            style={{ marginBottom: '20px', width: '100%' }}
                          >
                            <DatePicker
                              selected={CustomDate}
                              minimumDate={new Date()}
                              dateFormat='yyyy/MM/dd'
                              placeholderText='Start Date'
                              showPopperArrow={false}
                              onChange={date => {
                                setCustomDate(date)
                                setIsValid(prevState => ({
                                  ...prevState,
                                  CustomDate: true
                                }))
                              }}
                              showTimeSelect={false}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              customInput={
                                // Customizes the appearance of the input field
                                <div style={styles.startDate}>
                                  <img
                                    src={Icons.Ic_newdate}
                                    style={{ height: '20px' }}
                                  />
                                  <div className='fontSize16'>
                                    {CustomDate ? (
                                      format(CustomDate, 'dd/MM/yyyy')
                                    ) : (
                                      <div style={styles.customText_ad}>
                                        End Date
                                      </div>
                                    )}
                                  </div>
                                </div>
                              }
                            />
                          </div>

                          <div
                            className={`Category_name   ${
                              isValid.TimeClock ? 'valid' : 'invalid'
                            }`}
                            style={{ marginBottom: '20px', width: '100%' }}
                          >
                            <div style={styles.timePicker}>
                              {TimeOPen && (
                                <div>
                                  <div
                                    onClick={() => setTimeOPen(false)}
                                    className='time_date'
                                  ></div>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <StaticTimePicker
                                      sx={{
                                        borderRadius: 5,
                                        margin: 0,
                                        height: null,
                                        width: null
                                      }}
                                      ampm={true}
                                      value={
                                        TimeClock === null
                                          ? dayjs('00:00', 'HH:mm')
                                          : TimeClock
                                      }
                                      onClose={() => setTimeOPen(false)}
                                      onAccept={newTime => {
                                        setTimeClock(newTime)
                                        setIsValid(prevState => ({
                                          ...prevState,
                                          TimeClock: true
                                        }))
                                      }}
                                    />
                                  </LocalizationProvider>{' '}
                                </div>
                              )}
                            </div>
                            <div
                              style={styles.startDate}
                              onClick={() => setTimeOPen(true)}
                            >
                              <img
                                src={Icons.Ic_newTime}
                                style={{ height: '20px' }}
                              />
                              <div style={{ fontSize: '12px' }}>
                                {TimeClock && TimeClock.isValid() ? (
                                  formatTime(TimeClock)
                                ) : (
                                  <div style={styles.customText_ad}>Time</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {Personal && (
                <div style={{ marginBottom: '20px' }}>
                  <label
                    class='checkbox-container'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      marginTop: '0px'
                    }}
                  >
                    <input
                      type='checkbox'
                      checked={saveSettings}
                      onClick={() => setSaveSettings(prevState => !prevState)}
                    />
                    <span class='checkmark'></span>
                    <Text style={{ color: 'white', marginLeft: '8px' }}>
                      Retain the settings for the future events
                    </Text>
                  </label>
                </div>
              )}

              {Personal && <div style={styles.line} />}
              {Personal && (
                <>
                  <div>
                    <Text style={styles.labelPhoto}>Event Entry</Text>
                  </div>
                  <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <div
                      className={`Category_name Subcategory_drop  ${
                        isValid.Ticket ? 'valid' : 'invalid'
                      }`}
                      style={styles.CustomDrop}
                    >
                      <CustomDropdown
                        dropDownLabel={
                          Ticket === null || Ticket === '' ? (
                            <div style={{ color: 'rgb(129, 129, 129)' }}>
                              Event Entry
                            </div>
                          ) : (
                            Ticket
                          )
                        }
                        items={['Ticket', 'Free']}
                        color='blue'
                        onSelect={items => {
                          setTicket(items)
                          setIsValid(prevState => ({
                            ...prevState,
                            Ticket: true
                          }))
                        }}
                        customLabelStyle={{
                          fontWeight: '400',
                          color: 'white',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {Personal && (
            <>
              {Workshop && (
                <>
                  {Ticket === 'Ticket' && (
                    <div>
                      <div
                        className={`Category_name   ${
                          isValid.VIPTicket ? 'valid' : 'invalid'
                        }`}
                        style={{ marginBottom: '20px' }}
                      >
                        <input
                          type='number'
                          placeholder='VIP Ticket Price'
                          value={VIPTicket}
                          onChange={e => {
                            setVIPTicket(e.target.value)
                            setIsValid(prevState => ({
                              ...prevState,
                              VIPTicket: true
                            }))
                          }}
                        />
                      </div>

                      <div
                        className={`Category_name   ${
                          isValid.EconomyTicket ? 'valid' : 'invalid'
                        }`}
                        style={{ marginBottom: '20px' }}
                      >
                        <input
                          type='number'
                          placeholder='Economy Ticket Price'
                          value={EconomyTicket}
                          onChange={e => {
                            setEconomyTicket(e.target.value)
                            setIsValid(prevState => ({
                              ...prevState,
                              EconomyTicket: true
                            }))
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {WorkshopPrice && (
            <div>
              <div
                className={`Category_name   ${
                  isValid.VIPTicket ? 'valid' : 'invalid'
                }`}
                style={{ marginBottom: '20px' }}
              >
                <input
                  type='text'
                  placeholder='Workshop Price'
                  value={VIPTicket}
                  onChange={e => {
                    setVIPTicket(e.target.value)
                    setIsValid(prevState => ({
                      ...prevState,
                      VIPTicket: true
                    }))
                  }}
                />
              </div>
            </div>
          )}

          {Personal && (
            <>
              <div
                className={`Category_name   ${
                  isValid.TicketDate ? 'valid' : 'invalid'
                }`}
                style={{ marginBottom: '20px' }}
              >
                <DatePicker
                  selected={TicketDate}
                  minDate={isValidityToDate}
                  dateFormat='dd/MM/yyyy'
                  placeholderText='Start Date'
                  showPopperArrow={false}
                  onChange={date => {
                    setTicketDate(date)
                    setIsValid(prevState => ({
                      ...prevState,
                      TicketDate: true
                    }))
                  }}
                  showTimeSelect={false}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  customInput={
                    <div style={styles.TicketDate}>
                      <div className='fontSize16'>
                        {TicketDate ? (
                          format(TicketDate, 'dd/MM/yyyy')
                        ) : (
                          <div style={styles.customText_ad}>
                            Select Ticket Purchase Deadline Date
                          </div>
                        )}
                      </div>
                      <img src={Icons.Ic_newdate} style={{ height: '20px' }} />
                    </div>
                  }
                />
              </div>
            </>
          )}
          {Personal && (
            <>
              <div
                className={`Category_name   ${
                  isValid.LinkTicket ? 'valid' : 'invalid'
                }`}
                style={{ marginBottom: '20px' }}
              >
                <input
                  type='url'
                  placeholder='Link To The Ticket’s Page'
                  value={LinkTicket}
                  onChange={e => {
                    setLinkTicket(e.target.value)
                    setIsValid(prevState => ({
                      ...prevState,
                      LinkTicket: true
                    }))
                  }}
                />
              </div>
            </>
          )}
          {WorkshopPrice && (
            <div>
              <div class='custom_radio' style={{ marginBottom: '20px' }}>
                <Text style={styles.labelPhoto}>
                  Visibility to Artists for signing-up
                </Text>
                <CheckboxContainer>
                  <Checkbox onClick={() => handleCheckboxClick(2)}>
                    {visibility.signupVisibility === 2 ? (
                      <div className='checkbox_F'></div>
                    ) : (
                      <div className='checkbox_d'></div>
                    )}
                  </Checkbox>
                  <Text style={styles.labelPhoto}>Always</Text>
                </CheckboxContainer>

                <CheckboxContainer>
                  <Checkbox onClick={() => handleCheckboxClick(1)}>
                    {visibility.signupVisibility === 1 ? (
                      <div className='checkbox_F'></div>
                    ) : (
                      <div className='checkbox_d'></div>
                    )}
                  </Checkbox>
                  <Text style={styles.labelPhoto}>1 week before the event</Text>
                </CheckboxContainer>

                <CheckboxContainer>
                  <Checkbox onClick={() => handleCheckboxClick(3)}>
                    {visibility.signupVisibility === 3 ? (
                      <div className='checkbox_F'></div>
                    ) : (
                      <div className='checkbox_d'></div>
                    )}
                  </Checkbox>
                  <Text style={styles.labelPhoto}>Custom</Text>
                </CheckboxContainer>
              </div>

              <div style={styles.EventDate}>
                <div
                  className='Category_name'
                  style={{ marginBottom: '20px', width: '100%' }}
                >
                  <DatePicker
                    selected={CustomDate}
                    minimumDate={new Date()}
                    dateFormat='yyyy/MM/dd'
                    placeholderText='Start Date'
                    showPopperArrow={false}
                    onChange={date => setCustomDate(date)}
                    showTimeSelect={false}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    customInput={
                      <div style={styles.startDate}>
                        <img
                          src={Icons.Ic_newdate}
                          style={{ height: '20px' }}
                        />
                        <div className='fontSize16'>
                          {CustomDate ? (
                            format(CustomDate, 'dd/MM/yyyy')
                          ) : (
                            <div style={styles.customText_ad}>End Date</div>
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>

                <div
                  className='Category_name'
                  style={{ marginBottom: '20px', width: '100%' }}
                >
                  <div style={styles.timePicker}>
                    {TimeOPen && (
                      <div>
                        <div
                          onClick={() => setTimeOPen(false)}
                          className='time_date'
                        ></div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticTimePicker
                            sx={{
                              borderRadius: 5,
                              margin: 0,
                              height: null,
                              width: null
                            }}
                            ampm={true}
                            value={EndTimeClock}
                            onClose={() => setTimeOPen(false)}
                            onAccept={newTime => {
                              setTimeClock(newTime)
                              setIsValid(prevState => ({
                                ...prevState,
                                TimeClock: true
                              }))
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </div>
                  <div
                    style={styles.startDate}
                    onClick={() => setTimeOPen(true)}
                  >
                    <img src={Icons.Ic_newTime} style={{ height: '20px' }} />
                    <div style={{ fontSize: '12px' }}>
                      {TimeClock ? (
                        TimeClock.format('HH:mm')
                      ) : (
                        <div style={styles.customText_ad}>Time</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <label
                  class='checkbox-container'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    marginTop: '0px'
                  }}
                >
                  <input
                    type='checkbox'
                    checked={saveSettings}
                    onClick={() => setSaveSettings(prevState => !prevState)}
                  />
                  <span class='checkmark'></span>
                  <Text style={{ color: 'white' }}>
                    Retain the settings for the future events
                  </Text>
                </label>
              </div>

              <div style={{ marginBottom: '20px' }}>
                {AwardPreview && (
                  <div className='w-full text-center relative '>
                    <img
                      src={AwardPreview}
                      alt='Category Preview'
                      style={{
                        width: '100%',
                        height: '155px',
                        objectFit: 'contain'
                      }}
                      className='m-auto'
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {Personal && <div style={styles.line} />}
          {Personal && (
            <>
              <div>
                <Text style={styles.labelPhoto}>Event Ticket Stub</Text>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <div
                  className='Category_upload'
                  style={{
                    backgroundColor: Colors.dark_black,
                    height: '70px',
                    width: '80px',
                    borderRadius: '15px'
                  }}
                >
                  {uploadImage && (
                    <div className='absolute'>
                      <div
                        className='upload-data'
                        style={{ width: '40px', height: '40px' }}
                      >
                        <input
                          type='file'
                          style={{ height: '40px', width: '40px' }}
                          onChange={handleImageUpload}
                          accept='image/*'
                        />
                        <img
                          src={Icons.Ic_AddedImg}
                          style={{ height: '40px', width: '40px' }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {imagePreview && (
                  <div
                    className='w-full text-center relative '
                    style={{ width: '500px', position: 'relative' }}
                  >
                    <img
                      src={Icons.delete}
                      alt='Category Preview'
                      onClick={() => handleImageDelete()}
                      className='delete'
                      style={{
                        position: 'absolute',
                        width: '20px',
                        cursor: 'pointer',
                        height: '20px',
                        objectFit: 'cover',
                        filter: 'brightness(0) invert(1)',
                        right: '-15px',
                        top: '-15px'
                      }}
                    />
                    <img
                      src={imagePreview}
                      alt='Category Preview'
                      style={{
                        width: '500px',
                        height: '155px',
                        objectFit: 'cover'
                      }}
                      className='m-auto'
                    />
                  </div>
                )}
                <div
                  style={{
                    marginLeft: '30px',
                    marginTop: '20px',
                    marginBottom: '40px'
                  }}
                >
                  <Text style={styles.labelPhoto}>What to expect:</Text>
                  <div className='expect_event'>
                    <div>
                      <input
                        className={`${isValid.expect ? 'valid' : 'invalid'}`}
                        value={expect}
                        onChange={e => {
                          setExpect(e.target.value)
                          setIsValid(prevState => ({
                            ...prevState,
                            expect: true
                          }))
                        }}
                        type='text'
                      />
                    </div>
                    <div>
                      <input
                        className={`${isValid.expect1 ? 'valid' : 'invalid'}`}
                        value={expect1}
                        onChange={e => {
                          setExpect1(e.target.value)
                          setIsValid(prevState => ({
                            ...prevState,
                            expect1: true
                          }))
                        }}
                        type='text'
                      />
                    </div>

                    <div>
                      <input
                        className={`${isValid.expect2 ? 'valid' : 'invalid'}`}
                        value={expect2}
                        onChange={e => {
                          setExpect2(e.target.value)
                          setIsValid(prevState => ({
                            ...prevState,
                            expect2: true
                          }))
                        }}
                        u
                        type='text'
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`Category_upload ${
                  isValid.imagePreviewEvent ? 'valid' : 'invalid'
                }`}
                style={{
                  backgroundColor: Colors.dark_black,
                  height: '100px',
                  width: '100%',
                  borderRadius: '15px',
                  marginBottom: '30px'
                }}
              >
                {uploadImage && (
                  <div
                    className='absolute'
                    style={{
                      justifyContent: 'flex-start',
                      marginLeft: '20px',
                      display: 'block',
                      marginTop: '20px'
                    }}
                  >
                    <Text style={[{ marginBottom: '50px' }, styles.labelPhoto]}>
                      Event Photos
                    </Text>
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                        marginTop: '15px'
                      }}
                    >
                      <div
                        className='upload-data'
                        style={{
                          width: '40px',
                          height: '40px',
                          cursor: 'pointer'
                        }}
                      >
                        <input
                          type='file'
                          style={{
                            height: '40px',
                            width: '40px',
                            cursor: 'pointer'
                          }}
                          onChange={handleImageUploadEvent}
                          accept='image/*'
                        />
                        <img
                          src={Icons.Ic_AddedImg}
                          style={{ height: '40px', width: '40px' }}
                        />
                      </div>

                      {imagePreviewEvent.map((imageData, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                          <img
                            key={index}
                            src={imageData}
                            alt={`Uploaded preview ${index + 1}`}
                            style={{
                              width: '40px',
                              height: '40px',
                              objectFit: 'cover'
                            }}
                            className='m-auto'
                          />
                          <img
                            src={Icons.delete}
                            alt='Category Preview'
                            onClick={() => uploadImageRemove(index)}
                            className='delete'
                            style={{
                              position: 'absolute',
                              width: '20px',
                              cursor: 'pointer',
                              height: '20px',
                              right: '-15px',
                              top: '-10px'
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {Notification && (
            <div>
              <div
                style={{
                  marginTop: '20px',
                  marginLeft: '20px',
                  marginBottom: '30px'
                }}
              >
                <label
                  class='checkbox-container'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginTop: '0px'
                  }}
                >
                  <input type='checkbox' />
                  <span class='checkmark'></span>
                  <Text style={{ color: 'white' }}>Notification</Text>
                </label>
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              gap: '40px',
              maxWidth: '500px',
              margin: 'auto'
            }}
          >
            <div style={styles.eventSubmit} onClick={handleSubmit}>
              Submit
            </div>
          </div>

          {showSuccessPopup && (
            <div className='success-popup'>
              <div className='success_event' style={{ textAlign: 'center' }}>
                <img src={Icons.Success} style={{ width: '50px' }} />
                <p>Event Successfully Updated</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EventUpdate
