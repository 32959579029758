import React from 'react'

export const CustomCommonBox = ({ route, label, onClickTabOpen, color ,count }) => {
  return (
    <div className='dashboard-card' onClick={() => onClickTabOpen(route)}>
      <div className='lastWeekEndDiv stretch-item'>{label}</div>
      <div className='astrologerTextValueShow' style={{ color: color }}>
        {count}
      </div>
    </div>
  )
}
