import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../Components/Header/Header";
import Colors from "../../Constants/Colors";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import {
  apiCallGetRegistrationUser,
  apiCallNewRegistarionApprove,
} from "../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import EmptyData from "../../Components/EmptyData/EmptyData";
import RegistrationRenderItem from "./RegistrationRenderItem";
import RejectModal from "./RejectModal";
import { CustomCommonBox } from "../../Components/CustomCommonBox/CustomCommonBox";
function Registration() {
  const dispatch = useDispatch();
  const [userListData, setUserListData] = useState();
  const [userTotalActive, setUserTotalActive] = useState(0);
  const [userTotalInActive, setUserTotalInActive] = useState(0);
  const [userTotalCount, setUserTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [initialPage, setInitialPage] = useState(0);
  const [currentUserId, setCurrentUserId] = useState();

  useEffect(() => {
    onApiCallUserList(0);
  }, []);

  const onApiCallUserList = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      offset: selectedPage + 1,
      limit: 15,
    };
    try {
      const res = await apiCallGetRegistrationUser(param);
      console.log("resres", res);

      setUserTotalActive(res?.allApproveUser);
      setUserTotalCount(res?.allUser);
      setUserTotalInActive(res?.allDeapproveUser);
      setInitialPage(selectedPage ?? 0);
      setUserListData(res);
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  const registrationTotal = [
    {
      label: "Total Registration",
      count: userTotalCount,
      color: "",
    },
    // {
    //   label: "Total Approved",
    //   count: userTotalActive,
    //   color: Colors.darkGreen,
    // },
    {
      label: "Total DeApproved",
      count: userTotalInActive,
      color: Colors.darkBlue,
    },
  ];
  const onApprove = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: data.userId,
      isApprove: !data.isApprove,
    };
    try {
      await apiCallNewRegistarionApprove(param);
      onApiCallUserList(0);
    } catch (error) {
      console.error("Error toggling user status:", error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  const onReject = async (rejectComment) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: currentUserId.userId,
      email: currentUserId.userEmail,
      status: true,
      message: rejectComment,
    };
    try {
      await apiCallNewRegistarionApprove(param);
      onApiCallUserList(0);
      setIsVisible(false);
    } catch (error) {
      console.error("Error toggling user status:", error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  const onClickTabOpen = () => {};
  return (
    <div>
      <Header name={"New Registration"} />
      <div className="register-flex">
        <div className="astrologer-inner">
          <div className="frame-container">
            <div className="frame-div">
              {registrationTotal.map((tab) => (
                <CustomCommonBox
                  key={tab.label}
                  label={tab.label}
                  onClickTabOpen={onClickTabOpen}
                  color={tab.color}
                  count={tab.count}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {userListData?.data.length != 0 && (
        <div style={{}} className="register-style-stretch">
          <div className="lastWeekEndDiv Total-Event">
            <div className="user_title">Registration list</div>
            <div className="transparentWhite">
              <nav className="grid-cols grid_7 fontSize16WithoutFlex transparentWhite-padding">
                {[
                  { label: "Name", value: "12%" },
                  { label: "Username", value: "12%" },
                  { label: "Contact", value: "20%" },
                  { label: " Date of Birth", value: "8%" },
                  { label: "User Type", value: "8%" },
                  { label: "Address", value: "20%" },
                  { label: "Status", value: "10%" },
                  { label: "", value: "10%" },
                ].map((items,index) => (
                  <div key={index.toString()} style={{ width: items.value }} className="user_name">
                    {items.label}
                  </div>
                ))}
              </nav>
              <RegistrationRenderItem
                onApprove={(data) => onApprove(data)}
                currentUsers={userListData?.data}
                setIsVisible={(data) => {
                  setIsVisible(true);
                  setCurrentUserId(data);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {userListData?.data.length === 0 && (
        <div className="empty-centered">
          <EmptyData />
        </div>
      )}

      {userListData?.data.length !== 0 && (
        <div className="Custom-Pagination">
          <CustomPagination
            pageCount={Math.ceil(userListData?.totalCount / 15)}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              onApiCallUserList(selected);
            }}
          />
        </div>
      )}

      {isVisible && (
        <RejectModal
          onReject={(rejectComment) => onReject(rejectComment)}
          isVisible={isVisible}
          handleClose={() => {
            setCurrentUserId();
            setIsVisible(false);
          }}
        />
      )}
    </div>
  );
}

export default Registration;
