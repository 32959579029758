import {
  admin_toggleEventStatus,
  createCategoryWiseInterest,
  createInterestCategory,
  deleteCategoryWiseInterest,
  deleteEventMedia,
  deleteInterestCategory,
  editCategoryWiseInterest,
  editInterestCategory,
  getAdminCategoryWiseInterest,
  getAdminInterestCategoryList,
  getUser,
  getUserDetails,
  getUserEvent,
  getUserMerchandise,
  loginAdmin,
  toggleCategoryWiseInterest,
  toggleInterestCategoryStatus,
  toggleStatus,
  toggleUserStatus,
  userCheckInEvent,
  getAdminEventTypes,
  toggleEventTypeStatus,
  deleteEventType,
  getAllEvent,
  getUserArtProject,
  toggleArtProjectStatus,
  userAchievementsEvent,
  toggleEventStatus,
  getEventDetails,
  admin_getUserArtLog,
  admin_getArtists_count,
  admin_get_ArtLover,
  adminGetArtRunner,
  admin_getAllFeatured,
  editArtRunnerEvent,
  adminGetDashboardEventCount,
  adminChangePassword,
  adminToggleFeaturedUser,
  userArtistList,
  getEventRetainableSettings,
  usersForgotPassword,
  userResetPassword,
  updateUserProfile,
  searchUser,
  searchInterestCategory,
  searchCategoryWiseInterest,
  searchUserEvent,
  searchEvent,
  getWithOutCheckInEvent,
  createArtRunnerEvent,
  toggleEventFeatured,
  adminCreateAchievement,
  adminGetAchievement,
  adminDeleteAchievement,
  adminToggleAchievementStatus,
  getRegistrationUser,
  newRegistarionApprove
} from './ApiEndPoint'
import { baseApiCall } from './BaseApiCall'

export const postApiLoginAdmin = data => {
  return baseApiCall({
    url: loginAdmin,
    method: 'post',
    data
  })
}

export const apiCallGetUser = data => {
  return baseApiCall({
    url: getUser,
    method: 'post',
    data
  })
}
export const apiCallGetRegistrationUser = data => {
  return baseApiCall({
    url: getRegistrationUser,
    method: 'post',
    data
  })
}
export const apiCallNewRegistarionApprove = data => {
  return baseApiCall({
    url: newRegistarionApprove,
    method: 'post',
    data
  })
}

export const apiCallToggleUserStatus = data => {
  return baseApiCall({
    url: toggleUserStatus,
    method: 'post',
    data
  })
}

export const apiCallGetAdminInterestCategoryList = data => {
  return baseApiCall({
    url: getAdminInterestCategoryList,
    method: 'post',
    data
  })
}

export const apiCallGetCategoryStatusChange = data => {
  return baseApiCall({
    url: toggleInterestCategoryStatus,
    method: 'post',
    data
  })
}

export const apiCallDeleteInterestCategory = data => {
  return baseApiCall({
    url: deleteInterestCategory,
    method: 'post',
    data
  })
}

export const apiCallCreateInterestCategory = data => {
  return baseApiCall({
    url: createInterestCategory,
    method: 'post',
    data
  })
}

export const apiCallEditInterestCategory = data => {
  return baseApiCall({
    url: editInterestCategory,
    method: 'post',
    data
  })
}

export const apiCallGetAdminCategoryWiseInterest = data => {
  return baseApiCall({
    url: getAdminCategoryWiseInterest,
    method: 'post',
    data
  })
}

export const apiCallAddSubCategoryAdd = data => {
  return baseApiCall({
    url: createCategoryWiseInterest,
    method: 'post',
    data
  })
}

export const apiCallToggleCategoryWiseInterest = data => {
  return baseApiCall({
    url: toggleCategoryWiseInterest,
    method: 'post',
    data
  })
}

export const apiCallDeleteCategoryWiseInterest = data => {
  return baseApiCall({
    url: deleteCategoryWiseInterest,
    method: 'post',
    data
  })
}

export const apiCallEditCategoryWiseInterest = data => {
  return baseApiCall({
    url: editCategoryWiseInterest,
    method: 'post',
    data
  })
}

export const apiCallGetUserDetails = data => {
  return baseApiCall({
    url: getUserDetails,
    method: 'post',
    data
  })
}

export const apiCallGetUserMerchandise = data => {
  return baseApiCall({
    url: getUserMerchandise,
    method: 'post',
    data
  })
}

export const apiCallMerchandiseStatus = data => {
  return baseApiCall({
    url: toggleStatus,
    method: 'post',
    data
  })
}

export const apiCallUserCheckInEvent = data => {
  return baseApiCall({
    url: userCheckInEvent,
    method: 'post',
    data
  })
}

export const apiCallDeleteEventMedia = data => {
  return baseApiCall({
    url: deleteEventMedia,
    method: 'post',
    data
  })
}

export const apiCallGetUserWiseAllEvent = data => {
  return baseApiCall({
    url: getUserEvent,
    method: 'post',
    data
  })
}

export const apiCall_Admin_ToggleEventStatus = data => {
  return baseApiCall({
    url: admin_toggleEventStatus,
    method: 'post',
    data
  })
}

export const apiCall_getAdminEventTypes = data => {
  return baseApiCall({
    url: getAdminEventTypes,
    method: 'post',
    data
  })
}

export const apiCallGetUserArtProject = data => {
  return baseApiCall({
    url: getUserArtProject,

    method: 'post',
    data
  })
}

export const apiCallPostToggleArtProjectStatus = data => {
  return baseApiCall({
    url: toggleArtProjectStatus,

    method: 'post',
    data
  })
}

export const apiCall_toggleEventTypeStatus = data => {
  return baseApiCall({
    url: toggleEventTypeStatus,
    method: 'post',
    data
  })
}
export const apiCall_deleteEventType = data => {
  return baseApiCall({
    url: deleteEventType,
    method: 'post',
    data
  })
}

export const apiCallPostUserAchievementsEvent = data => {
  return baseApiCall({
    url: userAchievementsEvent,
    method: 'post',
    data
  })
}

export const apiCallPostGetArtLog = data => {
  return baseApiCall({
    url: admin_getUserArtLog,
    method: 'post',
    data
  })
}
export const apiCallGetAllEvent = data => {
  return baseApiCall({
    url: getAllEvent,
    method: 'post',
    data
  })
}
export const apiCallGetEventStatus = data => {
  return baseApiCall({
    url: toggleEventStatus,
    method: 'post',
    data
  })
}

export const apiCallGetEventDetails = data => {
  return baseApiCall({
    url: getEventDetails,
    method: 'post',
    data
  })
}

export const apiCallDashArtistCount = data => {
  return baseApiCall({
    url: admin_getArtists_count,
    method: 'post',
    data
  })
}

export const apiCallDashArtLover = data => {
  return baseApiCall({
    url: admin_get_ArtLover,
    method: 'post',
    data
  })
}

export const apiCallDAshAdmin_ArtRunner_Count = data => {
  return baseApiCall({
    url: adminGetArtRunner,
    method: 'post',
    data
  })
}

export const apiCallAdminGetAllFeatured = data => {
  return baseApiCall({
    url: admin_getAllFeatured,
    method: 'post',
    data
  })
}

export const apiCallEditArtRunnerEvent = data => {
  return baseApiCall({
    url: editArtRunnerEvent,
    method: 'post',
    data
  })
}

export const onAPiCallAdminGetDashboardEventCount = data => {
  return baseApiCall({
    url: adminGetDashboardEventCount,
    method: 'post',
    data
  })
}

export const onAPiCallAdminChangePassword = data => {
  return baseApiCall({
    url: adminChangePassword,
    method: 'post',
    data
  })
}

export const onAPiCallAdminToggleFeaturedUser = data => {
  return baseApiCall({
    url: adminToggleFeaturedUser,
    method: 'post',
    data
  })
}

export const onAPiCallUserStartList = data => {
  return baseApiCall({
    url: userArtistList,
    method: 'post',
    data
  })
}
export const apiCallEventRetainableSettings = data => {
  return baseApiCall({
    url: getEventRetainableSettings,
    method: 'post',
    data
  })
}

export const onApiCallForgotPassword = data => {
  return baseApiCall({
    url: usersForgotPassword,
    method: 'post',
    data
  })
}

export const onAPiCallUserResetPassword = data => {
  return baseApiCall({
    url: userResetPassword,
    method: 'post',
    data
  })
}

export const onAPiCallUpdateUserProfile = data => {
  return baseApiCall({
    url: updateUserProfile,
    method: 'post',
    data
  })
}
export const onAPiCallUserSearchUser = data => {
  return baseApiCall({
    url: searchUser,
    method: 'post',
    data
  })
}
export const onAPiCallSearchInterestCategory = data => {
  return baseApiCall({
    url: searchInterestCategory,
    method: 'post',
    data
  })
}
export const onAPiCallSearchCategoryWiseInterest = data => {
  return baseApiCall({
    url: searchCategoryWiseInterest,
    method: 'post',
    data
  })
}

export const onAPiCallSearchEvent = data => {
  return baseApiCall({
    url: searchEvent,
    method: 'post',
    data
  })
}
export const onAPiCallGetWithOutCheckInEvent = data => {
  return baseApiCall({
    url: getWithOutCheckInEvent,
    method: 'post',
    data
  })
}

export const onAPiCallCreateArtRunnerEvent = data => {
  return baseApiCall({
    url: createArtRunnerEvent,
    method: 'post',
    data
  })
}

export const onAPiCallToggleEventFeatured = data => {
  return baseApiCall({
    url: toggleEventFeatured,
    method: 'post',
    data
  })
}

export const onApiCallAdminCreateAchievement = data => {
  return baseApiCall({
    url: adminCreateAchievement,
    method: 'post',
    data
  })
}

export const onApiCallGetAchievement = data => {
  return baseApiCall({
    url: adminGetAchievement,
    method: 'post',
    data
  })
}

export const onApiCallAdminDeleteAchievement = data => {
  return baseApiCall({
    url: adminDeleteAchievement,
    method: 'post',
    data
  })
}

export const onApiAdminToggleAchievementStatus = data => {
  return baseApiCall({
    url: adminToggleAchievementStatus,
    method: 'post',
    data
  })
}
