import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import Icons from '../../Constants/Icons'
import './auth.css'
import Loader from '../../Components/Loader/Loader'
import { onAPiCallUserResetPassword } from '../../Core/Apicall'
import { toast } from 'react-toastify'

const SetForgotPassword = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [newPassword, setNewPassword] = useState('')
  const [Confirm, setConfirm] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [rePasswordError, setRePasswordError] = useState('')
  const [otpError, setOtpError] = useState('')
  const [showNewConfirm, setShowNewConfirm] = useState(false)
  const [otp, setOtp] = useState('')

  const dispatch = useDispatch()

  const validatePassword = newPassword => {
    return newPassword.length >= 8
  }

  const handleOnClick = async e => {
    let isValid = true
    if (otp == '') {
      setOtpError('Please enter otp')
      isValid = false
    } else {
      setOtpError('')
    }

    if (!validatePassword(newPassword)) {
      setPasswordError('Password must be at least 8 characters long')
      isValid = false
    } else {
      setPasswordError('')
    }

    if (newPassword != Confirm) {
      setRePasswordError('New Password and Re-enter Password do not match')
      isValid = false
    } else {
      setRePasswordError('')
    }

    if (isValid) {
      let param = {
        email: location?.state,
        otp: otp,
        newPassword: newPassword
      }
      try {
        dispatch(setLoadingTrue())
        let res = await onAPiCallUserResetPassword(param)
        toast.success(res.message, {
          autoClose: 1200
        })
        setOtp('')
        setNewPassword('')
        setConfirm('')
        navigate('/', { replace: true })
        dispatch(setLoadingFalse())
      } catch (error) {
        toast.error(error, {
          autoClose: 1200
        })
        dispatch(setLoadingFalse())
      }
    }
  }

  return (
    <div className='custom-login-flex'>
      <div className='custom-login-center'>
        <img src={Icons.ic_AY_logo} className='custom-login-img' />
      </div>
      <div className='custom-login-container'>
        <div className='custom-login-content'>
          <div className='title_reset_password title_reset_password-up'>
            Set Forgot Password
          </div>

          <div className='title_password_main'>Enter otp</div>
          <div className='custom-input-container'>
            <input
              value={otp}
              autoComplete='off'
              maxLength={8}
              name='otp'
              onChange={e => setOtp(e.target.value.replace(/\D/g, ''))}
              className='searchTextInput w-100'
              placeholder='Enter Otp'
            />
          </div>
          {otpError && <div className='custom-error-login'>{otpError}</div>}

          <div className='title_password_main'>Enter password</div>
          <div className='custom-input-container'>
            <input
              autocomplete='whatever'
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              className='searchTextInput w-100'
              placeholder='Enter password'
            />
            <img
              onClick={() => setShowNewPassword(!showNewPassword)}
              src={showNewPassword ? Icons.hidden : Icons.show}
              className='custom-password-icon'
            />
          </div>
          {passwordError && (
            <div className='custom-error-login'>{passwordError}</div>
          )}

          <div className='title_password_main'>Re-enter new password</div>
          <div className='custom-input-container'>
            <input
              autocomplete='new-password'
              name='devicePassword'
              type={showNewConfirm ? 'text' : 'password'}
              value={Confirm}
              onChange={e => setConfirm(e.target.value)}
              className='searchTextInput w-100'
              placeholder='Enter password'
            />
            <img
              onClick={() => setShowNewConfirm(!showNewConfirm)}
              src={showNewConfirm ? Icons.hidden : Icons.show}
              className='custom-password-icon'
            />
          </div>
          {rePasswordError && (
            <div className='custom-error-login'>{rePasswordError}</div>
          )}

          <div
            style={{ width: '150px' }}
            onClick={() => handleOnClick()}
            className='reset_password_on_click forget-pass '
          >
            Set Forgot Password
          </div>
        </div>
        <Loader />
      </div>
    </div>
  )
}

export default SetForgotPassword
