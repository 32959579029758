import React, { useState } from "react";
import Achievements from "./UserList/Achievements";
import AllEvents from "./UserList/AllEvents";
import CheckingDetails from "./UserList/CheckingDetails";
import Merchandise from "./UserList/Merchandise";
import ArtLog from "./UserList/ArtLog";
import ArtProjects from "./UserList/ArtProjects";
import UserDetails from "./UserList/UserDetails";

const UserTab = ({ userId, userDetails }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    "Profile Details",
    "Achievements",
    "All Events",
    "Check-In Details",
    "Merchandise",
    "ArtLog",
    "Art Projects",
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <div>
      <div className="user-profile-custom-container margin-top-20">
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{
              padding: "10px",
              fontWeight: "400",
              color: index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
              borderBottom: index != activeTab ? "" : "1px solid #fff",
              cursor: "pointer",
            }}
            className="fontSize18"
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>

      <div className="margin-top-20">
        {activeTab === 0 && (
          <div className="grid grid-cols-2 gap-7">
            <UserDetails userDetails={userDetails} />
          </div>
        )}

        {activeTab === 1 && (
          <div className="grid grid-cols-2 gap-7">
            <Achievements userId={userId} />
          </div>
        )}

        {activeTab === 2 && (
          <div>
            <AllEvents userId={userId} />
          </div>
        )}

        {activeTab === 3 && (
          <div className="text-white mt-8 font-bold">
            <CheckingDetails userId={userId} />
          </div>
        )}

        {activeTab === 4 && (
          <div className="text-white mt-8 font-bold">
            <Merchandise userId={userId} />
          </div>
        )}

        {activeTab === 5 && (
          <div className="text-white mt-8 font-bold">
            <ArtLog userId={userId} />
          </div>
        )}

        {activeTab === 6 && (
          <div className="text-white mt-8 font-bold">
            <ArtProjects userId={userId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTab;
