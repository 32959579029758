export const loginAdmin = "admin/loginAdmin";
export const getUser = "admin/getUser";
export const getRegistrationUser = "admin/getRegistrationUser";
export const newRegistarionApprove = "admin/newRegistarionApprove";
export const toggleUserStatus = "admin/toggleUserStatus";
export const getAdminInterestCategoryList = "category/getAdminInterestCategoryList";
export const toggleInterestCategoryStatus = "category/toggleInterestCategoryStatus";
export const deleteInterestCategory = "category/deleteInterestCategory";
export const createInterestCategory = "category/createInterestCategory";
export const editInterestCategory = "category/editInterestCategory";
export const getAdminCategoryWiseInterest = "category/getAdminCategoryWiseInterest";
export const createCategoryWiseInterest = "category/createCategoryWiseInterest";
export const toggleCategoryWiseInterest = "category/toggleCategoryWiseInterest";
export const deleteCategoryWiseInterest = "category/deleteCategoryWiseInterest";
export const editCategoryWiseInterest = "category/editCategoryWiseInterest";
export const getUserDetails = "admin/getUserDetails";
export const getUserMerchandise = "admin/getUserMerch";
export const toggleStatus = "admin/toggleMerchStatus";
export const userCheckInEvent = "admin/userCheckInEvent";
export const deleteEventMedia = "artrunner/deleteEventMedia";
export const getUserEvent = "admin/getUserEvent";
export const admin_toggleEventStatus = "admin/toggleEventStatus";
export const getAdminEventTypes = "artrunner/getAdminEventTypes";
export const geteditEventType = "artrunner/editEventType";
export const getcreateEventType = "artrunner/createEventType";
export const toggleEventTypeStatus = "artrunner/toggleEventTypeStatus";
export const deleteEventType = "artrunner/deleteEventType";
export const getAllEvent = "admin/getAllEvent";
export const getUserArtProject = "admin/getUserArtProject";
export const toggleArtProjectStatus = "admin/toggleArtProjectStatus";
export const userAchievementsEvent = "admin/userAchievementsEvent";
export const toggleEventStatus = "admin/toggleEventStatus";
export const getEventDetails  ="admin/getEventDetails";
export const admin_getUserArtLog  ="admin/getUserArtLog";
export const admin_getArtists_count  ="admin/getArtists";
export const admin_get_ArtLover  ="admin/getArtLover";
export const adminGetArtRunner  ="admin/getArtRunner";
export const admin_getAllFeatured  ="admin/getAllFeatured";
export const editArtRunnerEvent ="artrunner/editArtRunnerEvent";
export const adminGetDashboardEventCount ="admin/getDashboardEventCount";
export const adminChangePassword ="admin/changePassword";
export const adminToggleFeaturedUser ="admin/toggleFeaturedUser";
export const userArtistList =  "users/artistList";
export const getEventRetainableSettings =  "artrunner/getEventRetainableSettings";
export const usersForgotPassword =  "users/forgotPassword";
export const userResetPassword =  "users/resetPassword";
export const updateUserProfile ="/users/updateUserProfile";
export const searchUser =  "admin/searchUser";
export const searchInterestCategory = "category/searchInterestCategory";
export const searchCategoryWiseInterest = "category/searchCategoryWiseInterest";
export const searchUserEvent = "admin/searchUserEvent";
export const searchEvent  = "/admin/searchEvent"; 
export const getWithOutCheckInEvent = "admin/getWithOutCheckInEvent";
export const createArtRunnerEvent = "artrunner/createArtRunnerEvent";
export const toggleEventFeatured = "admin/toggleEventFeatured";
export const adminCreateAchievement = "admin/createAchievement";
export const adminGetAchievement = "admin/getAchievement";
export const adminDeleteAchievement = "admin/deleteAchievement";
export const adminToggleAchievementStatus = "admin/toggleAchievementStatus";










