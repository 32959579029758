import React, { useRef } from 'react'
import Header from '../../Components/Header/Header'
import CustomDropdown from '../../Components/DropDown/CustomDropdown'
import Icons from '../../Constants/Icons'
import Colors from '../../Constants/Colors'
import { useState, useEffect } from 'react'
import CustomPagination from '../../Components/CustomPagination/CustomPagination'
import CustomEventScreen from '../../Components/CustomEventScreen/CustomEventScreen'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import {
  apiCallGetAllEvent,
  apiCallGetEventStatus,
  onAPiCallSearchEvent
} from '../../Core/Apicall'
import EmptyData from '../../Components/EmptyData/EmptyData'
import UseDebounce from '../../Components/UseDebounce'
import { CustomCommonBox } from '../../Components/CustomCommonBox/CustomCommonBox'

function EventsManagement () {
  const dispatch = useDispatch()
  const statusDropdownItem = ['All', 'Activate', 'Deactivate']
  const [event, setEvent] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [DropdownLabelSelect, setDropdownLabelSelect] = useState('By Status')
  const [EventTypeAllData, setEventTypeAllData] = useState()
  const [initialPage, setInitialPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [totalActive, setTotalActive] = useState(0)
  const [totalInActive, setTotalInActive] = useState(0)
  const debouncedInput = UseDebounce(searchValue, 500)

  const handleSelect = status => {
    setDropdownLabelSelect(status)
    if (status === 'All') {
      setEvent(EventTypeAllData)
    } else {
      const filteredData = EventTypeAllData.data.filter(item =>
        status === 'Activate'
          ? item.isEnabled === true
          : item.isEnabled === false
      )
      setEvent({ ...EventTypeAllData, data: filteredData })
    }
  }

  const isInitialRender = useRef(true)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    searchFilterFunction(searchValue, 0)
  }, [debouncedInput])

  const handleInputChange = e => {
    const searchText = e.target.value
    setSearchValue(searchText)
  }

  const searchFilterFunction = async (text, page) => {
    setDropdownLabelSelect('By Status')
    const param = {
      searchQuery: text,
      offset: page + 1,
      limit: 15
    }
    dispatch(setLoadingTrue())
    try {
      const res = await onAPiCallSearchEvent(param)
      setInitialPage(page)
      setEvent(res)
      setEventTypeAllData(res)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const handelEnabled = async data => {
    dispatch(setLoadingTrue())
    const param = {
      eventId: data.eventId,
      isEnabled: !data.isEnabled
    }
    try {
      const res = await apiCallGetEventStatus(param)
      if (data.isEnabled) {
        setTotalActive(pre => pre - 1)
        setTotalInActive(pre => pre + 1)
      } else {
        setTotalActive(pre => pre + 1)
        setTotalInActive(pre => pre - 1)
      }

      setEvent(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.eventId === data.eventId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
      setEventTypeAllData(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.eventId === data.eventId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
      dispatch(setLoadingFalse())
    } catch (error) {
      console.log('errorerrorerror', error)
      dispatch(setLoadingFalse())
    }
  }

  useEffect(() => {
    onApiEventTypeList(0)
  }, [])

  const onApiEventTypeList = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      status: null,
      offset: selectedPage + 1,
      limit: 15
    }
    try {
      const response = await apiCallGetAllEvent(param)
      setTotalCount(response?.allEvent)
      setTotalActive(response?.allActiveEvent)
      setTotalInActive(response?.allDeActiveEvent)
      setInitialPage(selectedPage ?? 0)
      setDropdownLabelSelect('By Status')
      setEvent(response)
      setEventTypeAllData(response)
      dispatch(setLoadingFalse())
    } catch (error) {
      console.error('Error fetching event types:', error)
      dispatch(setLoadingFalse())
    }
  }
  const onClickTabOpen = () => {}
  return (
    <div>
      <Header name={'Events Management'} />
      <div className='Event-AlignSelf'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
          <div className='astrologer-inner'>
            <div className='frame-container'>
              <div className='frame-div'>
                {[
                  { label: 'Total Events', color: '', count: totalCount },
                  {
                    label: 'Total Activate',
                    color: Colors.darkGreen,
                    count: totalActive
                  },
                  {
                    label: 'Total Deactivate',
                    color: Colors.darkBlue,
                    count: totalInActive
                  }
                ].map(items => (
                  <CustomCommonBox
                    label={items.label}
                    color={items.color}
                    count={items.count}
                    onClickTabOpen={onClickTabOpen}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='astrologers-list-parent padding-top-bottom'>
        <div className='frame-parent2'>
          <div className='custom-box-user'>
            <div className='custom-container-dropdown-div'>
              <CustomDropdown
                dropDownLabel={DropdownLabelSelect}
                items={statusDropdownItem}
                color='blue'
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: '400',
                  color: 'white'
                }}
              />
            </div>

            <div className='custom-box-search-div'>
              <img className='custom-image' alt='' src={Icons.ic_search} />
              <input
                type='text'
                className='searchTextInput'
                placeholder='Search'
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='Event-custom-style'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
          <div className='user_title event-flex-container'>Events list</div>
          <div className='transparentWhite'>
            <nav className='grid-cols grid_7 fontSize16WithoutFlex transparentWhite-padding'>
              <div className='user_name w-10'>Category Name</div>
              <div className='user_name'>Username</div>
              <div className='user_name'>Contact</div>
              <div className='user_name'>Event description</div>
              <div className='user_name'>Event</div>
              <div className='user_name'>Venue</div>
              <div className='w-10'>Edit</div>
              <div className='user_name'>Status</div>
            </nav>
            <CustomEventScreen
              currentUsers={event?.data}
              handelEnabled={handelEnabled}
            />
          </div>
        </div>
        {event?.data.length == 0 && (
          <div
            className='empty-centered'
            style={{
              paddingBottom: '10px'
            }}
          >
            <EmptyData />
          </div>
        )}
      </div>

      {event?.data.length != 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={event?.totalCount / 15}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected)
              } else {
                onApiEventTypeList(selected)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
export default EventsManagement
