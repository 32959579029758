import React from "react";
import Icons from "../../Constants/Icons";
const RegistrationRenderItem = ({ currentUsers, setIsVisible, onApprove }) => {
  return (
    <div className="register-min" style={{}}>
      <div className="scrollable-container transform_data" style={{}}>
        {currentUsers?.map((currentUsers, index) => (
          <div key={index} className="">
            <div className="list-user-div-container border-bottom">
              <div className="grid-cols grid_7">
                <div className="fontSize16WithoutFlex user_name w-12">
                  {currentUsers.name}
                </div>
                <div className="fontSize16WithoutFlex user_name w-12">
                  {currentUsers.userName}
                </div>
                <div className="user_name w-20 block">
                  <div className="fontSize16WithoutFlex alignItems ">
                    <img className="sms-res" src={Icons.ic_sms} alt="new" />
                    <div className="user_text">{currentUsers.userEmail}</div>
                  </div>
                  <div className="fontSize16WithoutFlex flex-container ">
                    <img
                      className="icon_style_list "
                      alt=""
                      src={Icons.ic_call}
                    />
                    <div className="user_text">{currentUsers.mobile}</div>
                  </div>
                </div>
                <div className="fontSize16WithoutFlex user_name w-8">
                  {currentUsers.birthDate}
                </div>
                <div className="fontSize16WithoutFlex user_name w-8">
                  {currentUsers.userType}
                </div>
                <div className="fontSize16WithoutFlex user_name w-20 word-wrap">
                  {currentUsers.country},{currentUsers.state},{" "}
                  {currentUsers.city}
                </div>

                <div style={{}} className="w-10">
                  <div
                    style={{ cursor: "default" }}
                    className="user_Status pointer"
                  >
                    <div
                      style={{
                        color:
                          !currentUsers.isApprove && !currentUsers.isRejet
                            ? " #ff9933"
                            : currentUsers.isRejet
                            ? "#f04438"
                            : "#12b76a",
                      }}
                      className="button"
                    >
                      {!currentUsers.isApprove && !currentUsers.isRejet
                        ? "Pending"
                        : currentUsers.isRejet
                        ? "Reject"
                        : "Approve"}
                    </div>
                  </div>
                </div>
                <div style={{}} className="w-10">
                  <div
                    onClick={() => onApprove(currentUsers)}
                    className="user_Status pointer"
                  >
                    <button className="button pointer approve-button">
                      {"Approve"}
                    </button>
                  </div>
                  <div
                    style={{ marginTop: "5px" }}
                    className="user_Status pointer"
                  >
                    <button
                      className="button pointer reject-button"
                      onClick={() => setIsVisible(currentUsers)}
                    >
                      {"Reject"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RegistrationRenderItem;
