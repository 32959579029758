import React, { useEffect, useState } from 'react'
import Colors from '../../../../Constants/Colors'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../../Config/Actions/loadingActions'
import {
  apiCallGetUserMerchandise,
  apiCallMerchandiseStatus
} from '../../../../Core/Apicall'
import CustomPagination from '../../../../Components/CustomPagination/CustomPagination'
import EmptyData from '../../../../Components/EmptyData/EmptyData'

const Merchandise = ({ userId }) => {
  const isLoading = useSelector(state => state.isLoading)
  const [merchandise, setMerchandise] = useState()
  const dispatch = useDispatch()
  useEffect(() => {
    onApiCallMerchandise()
  }, [])
  const onApiCallMerchandise = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      userId: userId,
      status: null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15
    }
    try {
      const res = await apiCallGetUserMerchandise(param)
      setMerchandise(res)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const onClickStatusChange = async data => {
    dispatch(setLoadingTrue())
    const param = {
      merchId: data?.merchId,
      isEnabled: !data?.isEnabled
    }
    try {
      const res = await apiCallMerchandiseStatus(param)
      onApiCallMerchandise()
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  return (
    <div className='achievements'>
      <div className='upcoming_list'>
        <div className='margin-top-20 achievement-container'>
          <div className='achievement-header'>
            <div className='achievement-title'>
              <div className='astrologerText'>Merchandise</div>
            </div>
          </div>
          <div className='achievement-content'>
            <div className='achievement-content-header'>
              <div className='lastWeekEndDivGreyColor w-20'>Product image</div>
              <div className='lastWeekEndDivGreyColor w-20s'>Product name</div>
              <div className='lastWeekEndDivGreyColor w-20'>Price</div>
              <div className='lastWeekEndDivGreyColor w-20'>Status</div>
            </div>
            <div className='scrollable-container h-max-350'>
              {!isLoading && merchandise?.data.length == 0 ? (
                <EmptyData />
              ) : (
                merchandise?.data?.map((items, index) => (
                  <div key={index}>
                    <div className='achievement-stretch-row'>
                      <div className='fontSize16WithoutFlex w-20'>
                        <img
                          src={items.imageUrl}
                          className='subcategory-input'
                          style={{
                            objectFit: 'contain'
                          }}
                        />
                      </div>
                      <div className='fontSize16WithoutFlex w-20'>
                        {items.productTitle}
                      </div>
                      <div className='fontSize16WithoutFlex w-20'>
                        {items.productPrice}
                      </div>

                      <div className='fontSize16WithoutFlex w-20'>
                        <div className='user_Status pointer'>
                          <button
                            onClick={() => onClickStatusChange(items, index)}
                            className='button'
                            style={{
                              cursor: 'pointer',
                              backgroundColor: items.isEnabled
                                ? Colors.mainGreen01
                                : Colors.mainRed01,
                              color: items.isEnabled
                                ? Colors.darkGreen
                                : Colors.darkRed
                            }}
                          >
                            {items.isEnabled ? 'Activate' : 'Deactivate'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        {merchandise?.data?.length != 0 && (
          <div className='Custom-Pagination'>
            <CustomPagination
              pageCount={Math.ceil(merchandise?.totalCount / 15)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={onApiCallMerchandise}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Merchandise
