import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CustomUploadProfilePic } from '../../../Core/CustomUploadProfilePic'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../Config/Actions/loadingActions'
import Icons from '../../../Constants/Icons'
import { apiCallEditCategoryWiseInterest } from '../../../Core/Apicall'

function ModalSubCategory ({ show, handleClose, pageRefresh, editData }) {
  const [newCategoryName, setNewCategoryName] = useState('')
  const [imagePreview, setImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    if (editData != undefined) {
      setNewCategoryName(editData?.artName)
      setImagePreview(editData?.artIconURL)
      setUploadImage(false)
    }
  }, [editData])

  const uploadImageRemove = () => {
    setImagePreview('')
    setUploadImage(true)
  }

  const handleImageUpload = async event => {
    dispatch(setLoadingTrue())
    const selectedImage = event.target.files[0]
    const formData = new FormData()
    formData.append('imageURL', selectedImage)
    formData.append('imageType', 'IntrestCategoryImages')
    formData.append('imageStoreId', '123456789')
    try {
      const data = await CustomUploadProfilePic(formData)
      setImagePreview(data.location)
      setUploadImage(false)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const handleSubmit = async () => {
    if (newCategoryName.trim() === '') {
      alert('Please enter a category name')
      return
    }
    if (imagePreview === '') {
      alert('Please upload an image')
      return
    }
    dispatch(setLoadingTrue())
    let param = {
      artName: newCategoryName,
      artIconURL: imagePreview,
      artId: editData?.artId,
      categoryId: editData?.categoryId
    }

    try {
      await apiCallEditCategoryWiseInterest(param)
      setNewCategoryName('')
      setImagePreview('')
      setUploadImage(true)
      pageRefresh()
      handleClose()
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const closeModal = () => {
    setNewCategoryName('')
    setImagePreview('')
    setUploadImage(true)
    handleClose()
  }
  return (
    <div
      className={`modal-category ${show ? 'display-block' : 'display-none'}`}
    >
      <div className='modal-content bg-Blue grid p-4 relative'>
        <span className='close ' onClick={closeModal}>
          &times;
        </span>
        <h2 className='text-white font-medium text-xl text-center my-3'>
          {editData ? 'Edit Category' : 'Add New Category'}
        </h2>
        <div className='Category_name'>
          <input
            type='text'
            value={newCategoryName}
            onChange={e => setNewCategoryName(e.target.value)}
            placeholder='Enter Category Name'
          />
        </div>

        <div className='Category_upload'>
          {uploadImage && (
            <div className='absolute pointer'>
              <div className='upload-data pointer'>
                <input
                  type='file'
                  className='subcategory-input'
                  onChange={handleImageUpload}
                  accept='image/*'
                />
                <img src={Icons.upload} className='subcategory-input' />
              </div>
            </div>
          )}
          {imagePreview && (
            <div className='w-full text-center relative '>
              <img
                src={Icons.delete}
                alt='Category Preview'
                onClick={() => uploadImageRemove()}
                className='delete subcategory-delete'
              />
              <img
                src={imagePreview}
                alt='Category Preview'
                className='m-auto subcategory-preview'
              />
            </div>
          )}
        </div>
        <div className='category_button'>
          <button
          className='subcategory-save'
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalSubCategory
