import React, { useState, useEffect } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { onAPiCallUserStartList } from '../../../Core/Apicall'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../Config/Actions/loadingActions'

const AddArtists = ({ setArtistId, ArtistId, handleArtistId }) => {
  const [selectedArtists, setSelectedArtists] = useState([])
  const [artists, setArtists] = useState([])
  const [nweArtists, setNewArtists] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchArtists = async () => {
      dispatch(setLoadingTrue())
      const param = {
        offset: 1,
        limit: 1000000,
        type: ''
      }
      try {
        const response = await onAPiCallUserStartList(param)
        setArtists(response.data || [])
        dispatch(setLoadingFalse())
      } catch (error) {
        console.error('Error fetching artist list:', error)
        dispatch(setLoadingFalse())
      }
    }

    fetchArtists()
  }, [dispatch])

  useEffect(() => {
    if (ArtistId && ArtistId.length > 0) {
      const selected = ArtistId.map(id => {
        const artist = artists.find(a => a.artistId === id)
        return artist
          ? { label: artist.userName, value: artist.artistId }
          : null
      }).filter(Boolean)
      setSelectedArtists(selected)
      setNewArtists(selected.length > 0)
    } else {
      setSelectedArtists([])
    }
  }, [ArtistId, artists])

  const handleChange = selectedItems => {
    setSelectedArtists(selectedItems)
    const ids = selectedItems.map(item => item.value)
    setArtistId(ids)
    handleArtistId()
    setNewArtists(selectedItems.length > 0)
  }
  const handleRemove = removedItem => {
    const updatedSelectedArtists = selectedArtists.filter(
      item => item.value !== removedItem.value
    )
    setSelectedArtists(updatedSelectedArtists)
    const ids = updatedSelectedArtists.map(item => item.value)
    setArtistId(ids)
    setNewArtists(ids.length > 0)
  }
  const options = artists.map(artist => ({
    label: artist.userName,
    value: artist.artistId
  }))

  return (
    <div
      className={`artists_id ${nweArtists ? 'Artists-bold' : 'Artists-bold-d'}`}
    >
      <MultiSelect
        options={options}
        value={selectedArtists}
        onChange={handleChange}
        labelledBy='Add Artists'
        overrideStrings={{ selectSomeItems: 'Add Artists' }}
        className='custom-multiselect'
        onRemove={handleRemove}
        styles={{
          dropdown: {
            backgroundColor: '#f0f0f0',
            borderRadius: '5px',
            border: '1px solid #ccc'
          },
          option: {
            color: '#333',
            '&:hover': {
              backgroundColor: '#ddd'
            }
          },
          tag: {
            backgroundColor: '#007bff',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 8px'
          }
        }}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedArtists.map(artist => (
          <div
            key={artist.value}
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '5px 5px 5px 10px',
              fontSize: '14px',
              background: '#00000080',
              padding: '5px 10px'
            }}
          >
            {artist.label}
            <div
              style={{ marginLeft: '8px', cursor: 'pointer', color: 'red' }}
              onClick={() => handleRemove(artist)}
            >
              X
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AddArtists
