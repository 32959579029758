import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import Icons from '../../Constants/Icons'
import './auth.css'
import Header from '../../Components/Header/Header'
import { onAPiCallAdminChangePassword } from '../../Core/Apicall'
import { toast } from 'react-toastify'
import { setProfileData } from '../../Config/Actions/profileActions'

const SetResetPassword = ({}) => {
  const isProfileData = useSelector(state => state.isProfileData.userData)
  const navigate = useNavigate()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [Confirm, setConfirm] = useState('')
  const [showRePassword, setShowRePassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewConfirm, setShowNewConfirm] = useState(false)
  const [errors, setErrors] = useState({})
  const [valid, setValid] = useState({
    oldPassword: true,
    newPassword: true,
    Confirm: true
  })
  const dispatch = useDispatch()
  const validateForm = () => {
    let errors = {}
    let isValid = true
    if (!oldPassword) {
      errors.oldPassword = 'Please enter old password'
      setValid(prevState => ({
        ...prevState,
        oldPassword: false
      }))
      isValid = false
    } else {
      setValid(prevState => ({
        ...prevState,
        oldPassword: true
      }))
    }
    if (!newPassword) {
      errors.newPassword = 'Please enter new password'
      setValid(prevState => ({
        ...prevState,
        newPassword: false
      }))
      isValid = false
    } else if (newPassword.length < 8) {
      errors.newPassword = 'New password must be at least 8 characters long'
      isValid = false
    } else {
      setValid(prevState => ({
        ...prevState,
        newPassword: true
      }))
    }
    if (newPassword !== Confirm) {
      errors.Confirm = 'New Password and Re-enter Password do not match'
      isValid = false
      setValid(prevState => ({
        ...prevState,
        Confirm: false
      }))
    } else {
      setValid(prevState => ({
        ...prevState,
        Confirm: true
      }))
    }
    setErrors(errors)
    return isValid
  }
  const onClickResetPassword = async e => {
    if (validateForm()) {
      dispatch(setLoadingTrue())
      const param = {
        userId: isProfileData.userId,
        password: oldPassword,
        newPassword: newPassword
      }
      try {
        const res = await onAPiCallAdminChangePassword(param)
        dispatch(setProfileData(null))
        const event = new CustomEvent('tabEventListener', {
          detail: '/Home'
        })
        window.dispatchEvent(event)
        navigate('/')
        toast.success(res.message, {
          autoClose: 1200
        })
        setOldPassword('')
        setNewPassword('')
        setConfirm('')
        dispatch(setLoadingFalse())
      } catch (error) {
        toast.error(error, {
          autoClose: 1200
        })
        dispatch(setLoadingFalse())
      }
    }
  }

  return (
    <div>
      <Header name='Reset Password' />
      <div className='custom-login-flex'>
        <div className='custom-login-center'></div>
        <div className='custom-login-container'>
          <div className='custom-login-content'>
            <div className='title_reset_password title_reset_password-up'>
              Reset Password
            </div>
            <div>
              <div className='title_password_main'>Old password</div>
              <div
                className={`  ${valid.oldPassword ? 'valid' : 'invalid'}`}
                style={{
                  background: '#ffffff1a',
                  borderRadius: '3px',
                  width: '45vh',
                  display: 'flex',
                  alignItems: 'center',
                  padding: 10
                }}
              >
                <input
                  type={showRePassword ? 'text' : 'password'}
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                  className='searchTextInput'
                  placeholder='Enter old password w-100'
                />
                <img
                  onClick={() => setShowRePassword(!showRePassword)}
                  src={showRePassword ? Icons.hidden : Icons.show}
                  className='custom-password-icon'
                />
              </div>

              {errors.oldPassword && (
                <div className='custom-error-login'>{errors.oldPassword}</div>
              )}

              <div className='title_password_main'>New password</div>
              <div
                className={`${valid.newPassword ? 'valid' : 'invalid'}`}
                style={{
                  background: '#ffffff1a',
                  borderRadius: '3px',
                  width: '45vh',
                  display: 'flex',
                  padding: 10,
                  alignItems: 'center'
                }}
              >
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  className='searchTextInput w-100'
                  placeholder='Enter new password'
                />
                <img
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  src={showNewPassword ? Icons.hidden : Icons.show}
                  className='custom-password-icon'
                />
              </div>
              {errors.newPassword && (
                <div className='custom-error-login'>{errors.newPassword}</div>
              )}
              <div className='title_password_main'>Re-enter new password</div>
              <div
                className={`${valid.Confirm ? 'valid' : 'invalid'}`}
                style={{
                  background: '#ffffff1a',
                  borderRadius: '3px',
                  width: '45vh',
                  display: 'flex',
                  alignItems: 'center',
                  padding: 10
                }}
              >
                <input
                  type={showNewConfirm ? 'text' : 'password'}
                  value={Confirm}
                  onChange={e => setConfirm(e.target.value)}
                  className='searchTextInput w-100'
                  placeholder='Enter Re-enter new password '
                />
                <img
                  onClick={() => setShowNewConfirm(!showNewConfirm)}
                  src={showNewConfirm ? Icons.hidden : Icons.show}
                  className='custom-password-icon'
                />
              </div>
              {errors.Confirm && (
                <div className='custom-error-login'>{errors.Confirm}</div>
              )}
              <div
                onClick={() => onClickResetPassword()}
                className='reset_password_on_click forget-pass'
                 style={{width:'150px'}}
              >
                Set new password
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetResetPassword
