import React from 'react'
import styles from '../../../Style'
import Icons from '../../../../Constants/Icons'

const HostDetails = ({ eventDetails }) => {
  const renderTable = rows => (
    <table className='table'>
      {rows.map(([label, value], index) => (
        <tr key={index}>
          <th>{label}</th>
          <td>{value || 'N/A'}</td>
        </tr>
      ))}
    </table>
  )
  const Section = ({ title, children }) => (
    <div style={styles.menu_ic}>
      <div className='astrologerText'>{title}</div>
      {children}
    </div>
  )
  const renderProfile = (imgSrc, name) => (
    <div
      style={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        marginBottom: '1rem'
      }}
    >
      <div
        style={{
          borderRadius: '50%',
          overflow: 'hidden',
          width: '70px',
          height: '70px'
        }}
      >
        <img
          style={{ width: '70px', height: '70px', borderRadius: '70px' }}
          src={imgSrc || Icons.Ic_HandShot}
          alt={name}
        />
      </div>
      <div>{name || 'N/A'}</div>
    </div>
  )

  return (
    <div className='achievements'>
      <Section title='Host Details'>
        <div style={styles.HostDetails}>
          <Section title='Event Details'>
            {renderProfile(
              eventDetails?.eventDetail?.eventImages,
              eventDetails?.eventDetail?.eventName
            )}
            {renderTable([
              ['Start Date', eventDetails?.eventDetail?.eventStartDate],
              ['Start Time', eventDetails?.eventDetail?.eventStartTime],
              ['Type Name', eventDetails?.eventDetail?.eventTypeName],
              ['Visibility', eventDetails?.eventDetail?.eventVisibility],
              ['Requirements', eventDetails?.eventDetail?.requirements],
              ['Description', eventDetails?.eventDetail?.eventDescription]
            ])}
          </Section>
          <Section title='Event Creator'>
            {renderProfile(
              eventDetails?.eventCreator?.eventCreatorProfile,
              eventDetails?.eventCreator?.eventCreatorName
            )}
            {renderTable([
              [
                'Creator Username',
                eventDetails?.eventCreator?.eventCreatorUserName
              ],
              [
                'Creator Mobile',
                eventDetails?.eventCreator?.eventCreatorMobile
              ],
              ['Creator Email', eventDetails?.eventCreator?.eventCreatorEmail],
              ['Creator City', eventDetails?.eventCreator?.eventCreatorCity]
            ])}
          </Section>
          <Section title='Venue Detail'>
            {renderTable([
              ['Venue Name', eventDetails?.venueDetail?.venueName],
              ['Venue Address', eventDetails?.venueDetail?.venueAddress]
            ])}

            <div className='astrologerText'>Event Mode</div>
            {renderTable([
              ['Category Name', eventDetails?.eventMode?.categoryName],
              ['Extra Bonus Spots', eventDetails?.eventMode?.extraBonusSpots],
              ['No Of Spots', eventDetails?.eventMode?.noOfSpots]
            ])}
          </Section>

          <Section title='All Event'>
            {renderTable([
              ['Except First', eventDetails?.exceptFirst],
              ['Except Second', eventDetails?.exceptSecond],
              ['Except Third', eventDetails?.exceptThird],
              ['Latitude', eventDetails?.latitude],
              ['Longitude', eventDetails?.longitude]
            ])}
          </Section>
          <Section title='Event Pricing'>
            {renderTable([
              ['VIP Price', eventDetails?.eventPricing?.VIPPrice],
              ['Economy Price', eventDetails?.eventPricing?.economyPrice],
              ['Event Entry', eventDetails?.eventPricing?.eventEntry],
              [
                'Latest Date For Purchase',
                eventDetails?.eventPricing?.latestDateForPurchase
              ],
              ['Ticket Page Link', eventDetails?.eventPricing?.ticketPageLink]
            ])}
          </Section>
          <Section title='Schedule Event'>
            {renderTable([
              ['Repeat Type', eventDetails?.scheduleEvent?.repeatType],
              ['Start Date', eventDetails?.scheduleEvent?.startDate],
              ['Next Event', eventDetails?.scheduleEvent?.nextEvent]
            ])}
          </Section>
        </div>
      </Section>
    </div>
  )
}

export default HostDetails
