import React, { useRef } from 'react'
import Header from '../../Components/Header/Header'
import CustomDropdown from '../../Components/DropDown/CustomDropdown'
import Icons from '../../Constants/Icons'
import Colors from '../../Constants/Colors'
import { useState, useEffect } from 'react'
import CustomPagination from '../../Components/CustomPagination/CustomPagination'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import {
  apiCallGetAllEvent,
  apiCallGetEventStatus,
  onAPiCallSearchEvent
} from '../../Core/Apicall'
import UpcomingEventsScreen from '../../Components/CustomDashboardScreen/UpcomingEventsScreen'
import EmptyData from '../../Components/EmptyData/EmptyData'
import UseDebounce from '../../Components/UseDebounce'
import { CustomCommonBox } from '../../Components/CustomCommonBox/CustomCommonBox'
function PastEvent () {
  const dispatch = useDispatch()
  const statusDropdownItem = ['All', 'Activate', 'Deactivate']
  const [initialPage, setInitialPage] = useState(0)
  const [pastEvent, setPastEvent] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState('By Status')
  const [EventTypeAllData, setEventTypeAllData] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [totalActive, setTotalActive] = useState(0)
  const [totalInActive, setTotalInActive] = useState(0)

  const debouncedInput = UseDebounce(searchValue, 500)

  const handleSelect = status => {
    setDropdownLabelSelect(status)
    if (status === 'All') {
      setPastEvent(EventTypeAllData)
    } else {
      const filteredData = EventTypeAllData.data.filter(item =>
        status === 'Activate'
          ? item.isEnabled === true
          : item.isEnabled === false
      )
      setPastEvent({ ...EventTypeAllData, data: filteredData })
    }
  }

  const isInitialRender = useRef(true)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    searchFilterFunction(searchValue, 0)
  }, [debouncedInput])

  const handleInputChange = e => {
    const searchText = e.target.value
    setSearchValue(searchText)
  }

  const searchFilterFunction = async (text, page) => {
    const param = {
      weekSet: 'Past',
      searchQuery: text,
      offset: page + 1,
      limit: 15
    }
    dispatch(setLoadingTrue())
    setDropdownLabelSelect('By Status')
    try {
      const res = await onAPiCallSearchEvent(param)
      setPastEvent(res)
      setInitialPage(page)
      setEventTypeAllData(res)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  useEffect(() => {
    onApiEventTypeList(0)
  }, [])

  const onApiEventTypeList = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      status: null,
      weekSet: 'Past',
      offset: selectedPage + 1,
      limit: 15
    }
    try {
      const response = await apiCallGetAllEvent(param)
      setTotalCount(response?.allEvent)
      setTotalActive(response?.allActiveEvent)
      setTotalInActive(response?.allDeActiveEvent)

      setInitialPage(selectedPage ?? 0)
      setDropdownLabelSelect('By Status')
      setPastEvent(response)
      setEventTypeAllData(response)
      dispatch(setLoadingFalse())
    } catch (error) {
      console.error('Error fetching event types:', error)
      dispatch(setLoadingFalse())
    }
  }

  const handelEnabled = async data => {
    dispatch(setLoadingTrue())
    const param = {
      eventId: data.eventId,
      isEnabled: !data.isEnabled
    }
    try {
      const res = await apiCallGetEventStatus(param)
      if (data.isEnabled) {
        setTotalActive(pre => pre - 1)
        setTotalInActive(pre => pre + 1)
      } else {
        setTotalActive(pre => pre + 1)
        setTotalInActive(pre => pre - 1)
      }

      setPastEvent(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.eventId === data.eventId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
      setEventTypeAllData(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.eventId === data.eventId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  const onClickTabOpen = () => {}
  return (
    <div>
      <Header name={'Pasts Events'} />
      <div className='Event-AlignSelf'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
          <div style={{}} className='astrologer-inner'>
            <div className='frame-container'>
              <div className='frame-div'>
                {[
                  {
                    label: 'Total Past Events',
                    color: '',
                    count: totalCount
                  },
                  {
                    label: 'Total Past Activate',
                    color: Colors.darkGreen,
                    count: totalActive
                  },
                  {
                    label: 'Total Past Deactivate',
                    color: Colors.darkBlue,
                    count: totalInActive
                  }
                ].map(items => (
                  <CustomCommonBox
                    label={items.label}
                    color={items.color}
                    count={items.count}
                    onClickTabOpen={onClickTabOpen}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='astrologers-list-parent padding-top-bottom'>
        <div className='frame-parent2'>
          <div className='custom-box-user'>
            <div className='custom-container-dropdown-div'>
              <CustomDropdown
                dropDownLabel={dropdownLabelSelect}
                items={statusDropdownItem}
                color='blue'
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: '400',
                  color: 'white'
                }}
              />
            </div>

            <div className='custom-box-search-div'>
              <img className='custom-image' alt='' src={Icons.ic_search} />
              <input
                type='text'
                className='searchTextInput'
                placeholder='Search'
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='Event-custom-style'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
          <div className='user_title event-flex-container'>
            Past Events list
          </div>
          <div className='Achievements-class-name'>
            <div className=' achievement-content'>
              <div className='achievement-content-header'>
                <div className='lastWeekEndDivGreyColor w-15'>image</div>
                <div className='lastWeekEndDivGreyColor w-15'>Name</div>
                <div className='lastWeekEndDivGreyColor w-13'>Events Name</div>

                <div className='lastWeekEndDivGreyColor w-20'>Date & times</div>
                <div className='lastWeekEndDivGreyColor w-10'>Edit</div>
                <div className='lastWeekEndDivGreyColor w-20'>Status</div>
              </div>
              <div className='scrollable-container transform_data'>
                <UpcomingEventsScreen
                  handelEnabled={handelEnabled}
                  data={pastEvent?.data}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {pastEvent?.data.length == 0 && (
        <div className='empty-centered'>
          <EmptyData />
        </div>
      )}
      {pastEvent?.data.length != 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={pastEvent?.totalCount / 15}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected)
              } else {
                onApiEventTypeList(selected)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
export default PastEvent
