import React from 'react'
import Icons from '../../Constants/Icons'
import { useNavigate } from 'react-router-dom'
import Colors from '../../Constants/Colors'

const CustomUserScreen = ({
  currentUsers,
  onClickStatusChange,
  onClickFeatureStatusChange
}) => {
  const navigate = useNavigate()
  const handleEditClick = currentUsers => {
    navigate('/UserUpdate', {
      state: {
        userId: currentUsers.userId,
        userId: currentUsers.userId
      }
    })
  }
  return (
    <div className='custom-user-list'>
      <div className='scrollable-container transform_data'>
        {currentUsers?.map((currentUsers, index) => (
          <div key={index} className='transaction-item cursor-pointer'>
            <div className='list-user-div-container border-bottom '>
              <div className='grid-cols grid_7'>
                <div
                  onClick={() =>
                    navigate('/UserProfile', {
                      state: {
                        userId: currentUsers.userId
                      }
                    })
                  }
                  className='fontSize16WithoutFlex  user_name alignItems pointer'
                >
                  <img
                    src={currentUsers.image ?? Icons.ic_default_user}
                    className='currentUsers-image'
                  />
                  <div
                    className='user_text pointer'
                    onClick={() =>
                      navigate('/UserProfile', {
                        state: {
                          userId: currentUsers.userId
                        }
                      })
                    }
                  >
                    {currentUsers.name}
                  </div>
                </div>
                <div
                  onClick={() =>
                    navigate('/UserProfile', {
                      state: {
                        userId: currentUsers.userId
                      }
                    })
                  }
                  className='fontSize16WithoutFlex user_name pointer'
                >
                  {currentUsers.userName}
                </div>
                <div className='user_name block'>
                  <div
                    onClick={() =>
                      navigate('/UserProfile', {
                        state: {
                          userId: currentUsers.userId
                        }
                      })
                    }
                    className='fontSize16WithoutFlex pointer alignItems'
                  >
                    <img className='sms-res' src={Icons.ic_sms} alt='new' />
                    <div className='user_text'>{currentUsers.userEmail}</div>
                  </div>
                  <div
                    onClick={() =>
                      navigate('/UserProfile', {
                        state: {
                          userId: currentUsers.userId
                        }
                      })
                    }
                    className='fontSize16WithoutFlex pointer  flex-container'
                  >
                    <img
                      className='icon_style_list '
                      alt=''
                      src={Icons.ic_call}
                    />
                    <div className='user_text'>{currentUsers.mobile}</div>
                  </div>
                </div>
                <div
                  onClick={() =>
                    navigate('/UserProfile', {
                      state: {
                        userId: currentUsers.userId
                      }
                    })
                  }
                  className='fontSize16WithoutFlex user_name pointer'
                >
                  {currentUsers.birthDate}
                </div>
                <div
                  onClick={() =>
                    navigate('/UserProfile', {
                      state: {
                        userId: currentUsers.userId
                      }
                    })
                  }
                  className='fontSize16WithoutFlex user_name pointer'
                >
                  {currentUsers.userType}
                </div>
                <div className='w-10'>
                  <img
                    src={Icons.edit}
                    onClick={() => handleEditClick(currentUsers)}
                    className='edit-button pointer'
                  />
                </div>
                <div className='user_Status user_name pointer'>
                  <button
                    onClick={() => onClickStatusChange(currentUsers)}
                    className='button'
                    style={{
                      cursor: 'pointer',
                      backgroundColor: currentUsers.isEnabled
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: currentUsers.isEnabled
                        ? Colors.darkGreen
                        : Colors.darkRed
                    }}
                  >
                    {currentUsers.isEnabled ? 'Activate' : 'Deactivate'}
                  </button>
                </div>

                <div className='user_Status user_name pointer'>
                  <button
                    onClick={() => onClickFeatureStatusChange(currentUsers)}
                    className='button'
                    style={{
                      cursor: 'pointer',
                      backgroundColor: currentUsers.isFeatured
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: currentUsers.isFeatured
                        ? Colors.darkGreen
                        : Colors.darkRed
                    }}
                  >
                    {currentUsers.isFeatured ? 'Activate' : 'Deactivate'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomUserScreen
