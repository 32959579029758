import React from 'react'
import Icons from '../../../../Constants/Icons'
import styles from '../../../Style'
import EmptyData from '../../../../Components/EmptyData/EmptyData'
const CheckingDetails = ({ eventDetails }) => {
  return (
    <div>
      <div className='achievements'>
        <div className='upcoming_list'>
          <div className='margin-top-20' style={styles.menu_ic}>
            <div
              className='achievement-header'
              style={{ justifyContent: 'left' }}
            >
              <div>
                <div className='astrologerText '>Checked in details</div>
              </div>
            </div>
            <div className='achievement-content'>
              <div className='achievement-content-header'>
                <div className='lastWeekEndDivGreyColor w-13'>User Name</div>

                <div className='lastWeekEndDivGreyColor w-20'>Contact</div>
                <div className='lastWeekEndDivGreyColor w-20'>
                  Venue Purchase
                </div>
                <div className='lastWeekEndDivGreyColor w-20'>Note</div>
                <div className='lastWeekEndDivGreyColor w-20'>Guest Join</div>
              </div>

              <div className='scrollable-container h-max-350'>
                {eventDetails.checkInEventData.length == 0 ? (
                  <EmptyData />
                ) : (
                  eventDetails.checkInEventData.map((items, index) => (
                    <div key={index}>
                      <div className='achievement-stretch-row'>
                        <div className='fontSize16WithoutFlex w-13'>
                          {items.userName}
                        </div>

                        <div
                          style={{ display: 'block', gap: '4px' }}
                          className='fontSize16WithoutFlex user_name'
                        >
                          <div
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px'
                            }}
                            className='fontSize16WithoutFlex'
                          >
                            <img
                              style={{
                                width: '1rem',
                                objectFit: 'fill',
                                height: '1rem',
                                display: 'inline-block',
                                marginRight: '2px'
                              }}
                              src={Icons.ic_sms}
                              alt='new'
                            />

                            <div className='user_text'>{items.userEmail}</div>
                          </div>
                          <div
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                              marginTop: '4px'
                            }}
                            className='fontSize16WithoutFlex'
                          >
                            <img
                              className='icon_style_list '
                              alt=''
                              src={Icons.ic_call}
                            />
                            <div className='user_text'>{items.userMobile}</div>
                          </div>
                        </div>

                        <div className='fontSize16WithoutFlex w-20'>
                          <div className='user_Status pointer'>
                            <div className='user_text'>
                              {items.venuePurchase ?? '-'}
                            </div>
                          </div>
                        </div>
                        <div className='fontSize16WithoutFlex w-20 '>
                          <div className='user_text'>{items.note ?? '-'}</div>
                        </div>
                        <div className='fontSize16WithoutFlex w-20'>
                          <div className='user_text'>
                            {items.guestJoin ?? '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckingDetails
