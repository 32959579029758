import React, { useState, useRef } from 'react';
import Icons from '../../../../Constants/Icons';
import { apiCallDeleteEventMedia } from '../../../../Core/Apicall';
import { useDispatch } from 'react-redux';
import {
  setLoadingFalse,
  setLoadingTrue,
} from '../../../../Config/Actions/loadingActions';
import EmptyData from '../../../../Components/EmptyData/EmptyData';

const MediaDetails = ({ hostDetails, onApiCallUserDetails }) => {
  const [medias, setMedia] = useState(hostDetails);
  const [isPlaying, setIsPlaying] = useState({});
  const videoRefs = useRef({});
  const dispatch = useDispatch();

  const handlePlay = (id) => {
    const videoRef = videoRefs.current[id];
    if (videoRef) {
      videoRef.play();
      setIsPlaying((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    }
  };

  const handlePause = (id) => {
    const videoRef = videoRefs.current[id];
    if (videoRef) {
      videoRef.pause();
      setIsPlaying((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  };

  const handleDelete = async (data) => {
    dispatch(setLoadingTrue());
    const param = { mediaId: data.mediaId };
    try {
      const res = await apiCallDeleteEventMedia(param);
      const updatedEventData = medias.map((event) => {
        const updatedEvent = { ...event };
        if (updatedEvent.media) {
          updatedEvent.media = updatedEvent.media.filter(
            (mediaItem) => mediaItem.mediaId !== data.mediaId
          );
        }
        return updatedEvent;
      });
      setMedia(updatedEventData);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div className=" achievement-container">
      <div className="media-flex">Media details</div>

      {medias.length === 0 ? (
        <div className="empty-centered">
          <EmptyData />
        </div>
      ) : (
        medias?.map((media) => (
          <div className="media-item" key={media.id}>
            {media?.mediaUrl?.length > 0 && (
              <div className="media-thumbnail">
                <img
                  src={media.mediaUrl}
                  alt="User Media"
                  className="media-image"
                />
                <img
                  className="delete-icon"
                  src={Icons.delete}
                  onClick={() => handleDelete(media)}
                  alt="Delete Icon"
                />
              </div>
            )}
            {media?.thumbnailUrl?.length > 0 && (
              <div className="media-video">
                <video
                  src={media.thumbnailUrl}
                  loop
                  ref={(el) => (videoRefs.current[media.id] = el)}
                  className="video-element"
                />
                <img
                  className="delete-icon"
                  src={Icons.delete}
                  onClick={() => handleDelete(media)}
                  alt="Delete Icon"
                />
                {!isPlaying[media.id] ? (
                  <div
                    className="play-button"
                    onClick={() => handlePlay(media.id)}
                  >
                    <img src={Icons.playButton} alt="Play Button" />
                  </div>
                ) : (
                  <div
                    className="pause-overlay"
                    onClick={() => handlePause(media.id)}
                  ></div>
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default MediaDetails;
