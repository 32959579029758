import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../../../Components/Header/Header'
import Icons from '../../../Constants/Icons'
import UserTab from '../UserTab/UserTab'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../Config/Actions/loadingActions'
import { apiCallGetUserDetails } from '../../../Core/Apicall'
const UserProfile = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [userDetails, setUserDetails] = useState()
  useEffect(() => {
    if (location.state) {
      onApiCallUserDetails()
    }
  }, [])

  const onApiCallUserDetails = async () => {
    dispatch(setLoadingTrue())
    const param = {
      userId: location.state.userId
    }
    try {
      const res = await apiCallGetUserDetails(param)
      setUserDetails(res.data)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  return (
    <div>
      <Header name={'User Profile'} />
      <div className='user-profile-flex'>
        <div>
          <div className='margin-top-20  profile-gap-flex'>
            <div className='profile-hidden'>
              <img
                className='profile-image-width'
                src={userDetails?.image ?? Icons.Ic_HandShot}
              />
            </div>
            <div>{userDetails?.name}</div>
          </div>
        </div>
        <div>
          <div className='profile-gap-20'>
            <div className='profile-box'>
              <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
                followers
              </div>
              <div className='astrologerTextValueShow color-white'>
                {userDetails?.followers}
              </div>
            </div>
            <div className='profile-box'>
              <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
                following
              </div>
              <div className='astrologerTextValueShow color-white'>
                {userDetails?.following}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserTab userId={location.state.userId} userDetails={userDetails} />
    </div>
  )
}

export default UserProfile
