import React, { useState, useEffect, useRef } from 'react'
import Icons from '../../../../Constants/Icons'

const ArtProjectsModel = ({ ArtShow, mediaSelectData, setArtShow }) => {
  console.log('mediaSelectData', mediaSelectData)
  const [videoUrl, setVideoUrl] = useState(mediaSelectData?.videoUrl ?? '')
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsPlaying(true)
    }
  }

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }

  const handleClick = () => {
    setArtShow(false)
  }

  const handleDeleted = () => {
    if (window.confirm('Are you sure you want to delete this media?')) {
    }
  }

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this media?')) {
      setVideoUrl('')
    }
  }

  useEffect(() => {
    const video = videoRef.current
    const handleEnded = () => {
      setIsPlaying(false)
      video.currentTime = 0 
    }

    if (video) {
      video.addEventListener('ended', handleEnded)
      return () => {
        video.removeEventListener('ended', handleEnded)
      }
    }
  }, [videoRef])
  return (
    <div>
      <div
        className={`modal-category ${
          ArtShow ? 'display-block' : 'display-none'
        }`}
      >
        <div
          className='modal-content checking-details-model'
          style={{ maxHeight: '100vh', overflow: 'auto' }}
        >
          <span className='close' onClick={handleClick}>
            &times;
          </span>
          <div className='show'>
            <div
              style={{
                textAlign: 'left',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                color: 'rgba(255, 255, 255, 0.9)',
                padding: '20px 10px'
              }}
              className='lastWeekEndDivGreyColor'
            >
              Media
            </div>
            <div className='show'>
              {mediaSelectData?.thumbnailUrl == null ? (
                <div className='media_menu' style={{ width: '100%' }}>
                  <img
                    src={mediaSelectData?.videoUrl}
                    alt='User Media'
                    style={{
                      height: '200px',
                      width: '100%',
                      objectFit: 'cover'
                    }}
                  />
                  <img
                    className='delete_media'
                    src={Icons.delete}
                    onClick={handleDeleted}
                    style={{
                      width: '20px',
                      cursor: 'pointer',
                      filter: 'brightness(0) invert(1)'
                    }}
                    alt='Delete Icon'
                  />
                </div>
              ) : (
                <div
                  className='media_menu'
                  style={{ width: '100%', position: 'relative' }}
                >
                  <video
                    ref={videoRef}
                    loop
                    style={{
                      height: '200px',
                      width: '100%',
                      objectFit: 'cover'
                    }}
                  >
                    <source src={videoUrl} type='video/mp4' />
                  </video>
                  <img
                    className='delete_media'
                    src={Icons.delete}
                    onClick={handleDelete}
                    style={{
                      width: '20px',
                      cursor: 'pointer',
                      filter: 'brightness(0) invert(1)',
                      position: 'absolute',
                      top: '10px',
                      right: '10px'
                    }}
                    alt='Delete Icon'
                  />
                  {!isPlaying && (
                    <div
                      onClick={handlePlay}
                      style={{ position: 'absolute', cursor: 'pointer' }}
                    >
                      <img src={Icons.playButton} style={{ height: '35px' }} />
                    </div>
                  )}
                  {isPlaying && (
                    <div
                      onClick={handlePause}
                      style={{
                        position: 'absolute',
                        left: '0px',
                        top: '0px',
                        width: '100%',
                        height: '100%'
                      }}
                    ></div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtProjectsModel
