import './App.css'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { useEffect, useState } from 'react'
import ForgotPassword from './Screens/AuthFrom/ForgotPassword'
import SetResetPassword from './Screens/AuthFrom/SetResetPassword'
import Box from '@mui/material/Box'
import Dashboard from './Screens/Dashboard/Dashboard'
import EventCategory from './Screens/CategoryManagement/EventCategory'
import UserManagement from './Screens/UserManagement/UserManagement'
import UserProfile from './Screens/UserManagement/UserProfile/UserProfile'
import EventsManagement from './Screens/EventsManagement/EventsManagement'
import EventDetails from './Screens/EventsManagement/EventDetails/EventDetails'
import EventUpdate from './Screens/EventsManagement/EventUpdate/EventUpdate'
import Subcategory from './Screens/CategoryManagement/Subcategory/Subcategory'
import { useSelector } from 'react-redux'
import PastEvent from './Screens/EventsManagement/PastEvent'
import UpcomingEvent from './Screens/EventsManagement/UpcomingEvent'
import FeaturedEvent from './Screens/EventsManagement/FeaturedEvent'
import TodayEvents from './Screens/EventsManagement/TodayEvents'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SetForgotPassword from './Screens/AuthFrom/SetForgotPassword'
import UserUpdate from './Screens/UserManagement/UserTab/UserList/UserUpdate/UserUpdate'
import WithOutCheckInEvent from './Screens/EventsManagement/WithOutCheckInEvent'
import Achievements from './Screens/Achievement/Achievements'
import AddAchievements from './Screens/Achievement/AddAchievements'
import Registration from './Screens/Registration/Registration'
import CustomSideBar from './Components/CustomSideBar/CustomSideBar'
import Login from './Screens/AuthFrom/Login'

function App () {
  const routes = [
    { path: '/', element: <Dashboard /> },
    { path: '/EventCategory', element: <EventCategory /> },
    { path: '/UserManagement', element: <UserManagement /> },
    { path: '/UserProfile', element: <UserProfile /> },
    { path: '/PastEvent', element: <PastEvent /> },
    { path: '/TodayEvents', element: <TodayEvents /> },
    { path: '/FeaturedEvent', element: <FeaturedEvent /> },
    { path: '/Achievements', element: <Achievements /> },
    { path: '/EventsManagement', element: <EventsManagement /> },
    { path: '/EventDetails', element: <EventDetails /> },
    { path: '/EventUpdate', element: <EventUpdate /> },
    { path: '/AddAchievements', element: <AddAchievements /> },
    { path: '/UpcomingEvent', element: <UpcomingEvent /> },
    { path: '/Subcategory', element: <Subcategory /> },
    { path: '/Registration', element: <Registration /> },
    { path: '/UserUpdate', element: <UserUpdate /> },
    { path: '/WithOutCheckInEvent', element: <WithOutCheckInEvent /> },
    { path: '/SetResetPassword', element: <SetResetPassword /> }
  ]
  const isProfileData = useSelector(state => state.isProfileData)
  const [forgotNavigate, setForgotNavigate] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(
    isProfileData == null ? false : true
  )
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    if (isLoggedIn) {
      setIsLoggedIn(JSON.parse(isLoggedIn))
    }
  }, [isLoggedIn])
  return (
    <div className='min-screen-color'>
      {isProfileData ? (
        <div className='screen-color'>
          <Box className='screen-box-flex'>
            <CustomSideBar />
            <main className='min-screen-width'>
              <Routes>
                {routes.map(({ path, element }, index) => (
                  <Route key={index} path={path} element={element} />
                ))}
                <Route path='*' element={<Navigate to='/' replace={true} />} />
              </Routes>
            </main>
          </Box>
        </div>
      ) : (
        <Routes>
          <Route
            path='/'
            element={
              <Login
                setIsLoggedIn={setIsLoggedIn}
                setForgotNavigate={setForgotNavigate}
              />
            }
          />
          {forgotNavigate && (
            <Route path='/ForgotPassword' element={<ForgotPassword />} />
          )}
          {forgotNavigate && (
            <Route path='/SetForgotPassword' element={<SetForgotPassword />} />
          )}
          <Route
            path='*'
            element={
              <Navigate to='/' replace={true} setIsLoggedIn={setIsLoggedIn} />
            }
          />
        </Routes>
      )}
      <ToastContainer />
    </div>
  )
}

export default App
