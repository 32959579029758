import React from 'react'
import Icons from '../../../../Constants/Icons'
import { useState, useRef } from 'react'

const CheckingDetailsModel = ({
  checkingDetailShow,
  checkInEventSelect,
  setCheckingDetailShow,
  confirmDeleted
}) => {
  const handleClick = () => {
    setCheckingDetailShow(false)
  }
  const handleDeleted = item => {
    if (window.confirm('Are you sure you want to delete this media?')) {
      confirmDeleted(item)
    }
  }
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRefs = useRef({})
  const handlePlay = id => {
    const videoRef = videoRefs.current[id]
    if (videoRef) {
      videoRef.play()
      setIsPlaying(prevState => ({
        ...prevState,
        [id]: true
      }))
    }
  }

  const handlePause = id => {
    const videoRef = videoRefs.current[id]
    if (videoRef) {
      videoRef.pause()
      setIsPlaying(prevState => ({
        ...prevState,
        [id]: false
      }))
    }
  }
  return (
    <div
      className={`modal-category CheckingDetails ${
        checkingDetailShow ? 'display-block' : 'display-none'
      }`}
    >
      <div className='modal-content checking-details-model'>
        <span className='close' onClick={handleClick}>
          &times;
        </span>
        <div
          className={`show ${
            checkingDetailShow ? 'display-block' : 'display-none'
          }`}
        >
          <div className='lastWeekEndDivGreyColor'>Event Media</div>
          <div className='show'>
            {checkInEventSelect?.media?.length != 0 ? (
              checkInEventSelect?.media?.map(item => (
                <>
                  <div style={{}}>
                    {item?.mediaUrl?.slice(item?.mediaUrl?.length - 4) !=
                    '.mp4' ? (
                      <div key={item.mediaId} className='media_menu'>
                        <img src={item.mediaUrl} alt='User Media' />
                        <img
                          className='delete_media'
                          src={Icons.delete}
                          onClick={() => handleDeleted(item)}
                          alt='Delete Icon'
                        />
                      </div>
                    ) : (
                      <div key={item.mediaId} className='media_menu'>
                        <video
                          src={item.mediaUrl}
                          loop
                          ref={el => (videoRefs.current[item.mediaId] = el)}
                        />
                        <img
                          className='delete_media'
                          src={Icons.delete}
                          onClick={() => handleDeleted(item)}
                          alt='Delete Icon'
                        />
                        {!isPlaying[item.mediaId] && (
                          <div onClick={() => handlePlay(item.mediaId)}>
                            <img
                              src={Icons.playButton}
                              style={{ height: '35px' }}
                            />
                          </div>
                        )}
                        {isPlaying[item.mediaId] && (
                          <div
                            onClick={() => handlePause(item.mediaId)}
                            className='pause-overlay '
                          ></div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              ))
            ) : (
              <p className='no-media'>No media available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckingDetailsModel
