import React from 'react'
import CustomDropdown from '../DropDown/CustomDropdown'
import './Header.css'
import './../../Constants/CommonStyle.css'
import { useNavigate } from 'react-router-dom'
import { setProfileData } from '../../Config/Actions/profileActions'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'

function Header ({ name }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSelect = async item => {
    if (item === 'Logout') {
      dispatch(setLoadingTrue())
      setTimeout(() => {
        navigate('/', { replace: true })

        dispatch(setProfileData(null))
        const event = new CustomEvent('tabEventListener', {
          detail: '/Home'
        })
        window.dispatchEvent(event)

        dispatch(setLoadingFalse())
      }, 1000)
    }
    if (item === 'Reset Password') {
      navigate('/SetResetPassword')
    }
  }

  return (
    <div className='headerContainer' style={{ backgroundColor: 'transparent' }}>
      <div className='headerFlexContainer'>
        <div className='headerText'>{name}</div>

        <div className='headerIconsContainer'>
          <div className='headerDropdownContainer'>
            <CustomDropdown
              dropDownLabel={'Admin'}
              items={['Reset Password', 'Logout']}
              onSelect={handleSelect}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
