import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import Loader from '../../Components/Loader/Loader'
import Icons from '../../Constants/Icons'
import './auth.css'
import { postApiLoginAdmin } from '../../Core/Apicall'
import { setProfileData } from '../../Config/Actions/profileActions'
const Login = ({ setIsLoggedIn, setForgotNavigate }) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('admin@yopmail.com')
  const [newPassword, setNewPassword] = useState('Test@123')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const dispatch = useDispatch()
  const [valid, setValid] = useState({
    email: true,
    password: true
  })
  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validatePassword = newPassword => {
    return newPassword.length >= 8
  }

  useEffect(() => {
    navigate('/')
  }, [])

  const handleLogin = async e => {
    let isValid = true
    if (!validateEmail(email)) {
      setEmailError('Invalid email format')
      setValid(prevState => ({
        ...prevState,
        email: false
      }))
      isValid = false
    } else {
      setEmailError('')
      setValid(prevState => ({
        ...prevState,
        email: true
      }))
    }

    if (!validatePassword(newPassword)) {
      setPasswordError('Password must be at least 8 characters long')
      setValid(prevState => ({
        ...prevState,
        password: false
      }))
      isValid = false
    } else {
      setPasswordError('')
      setValid(prevState => ({
        ...prevState,
        password: true
      }))
    }

    if (isValid) {
      try {
        dispatch(setLoadingTrue(true))
        const param = {
          email: email,
          password: newPassword
        }

        const res = await postApiLoginAdmin(param)
        setIsLoggedIn(true)
        dispatch(setProfileData(res))
        dispatch(setLoadingFalse(true))
      } catch (error) {
        dispatch(setLoadingFalse(true))
        console.log('error', error)
        alert(error)
      }
    }
  }

  return (
    <div className='custom-login-flex'>
      <div className='custom-login-center'>
        <img src={Icons.ic_AY_logo} className='custom-login-img' />
      </div>
      <div className='custom-login-container'>
        <div className='custom-login-content'>
          <div className='title_reset_password title_reset_password-up'>
            Login
          </div>
          <div className='title_password_main'>Email</div>
          <div
            className={`common-invalid  ${valid.email ? 'valid' : 'invalid'}`}
          >
            <input
              type={'email'}
              value={email}
              onChange={e => setEmail(e.target.value)}
              className='searchTextInput w-100'
              placeholder='Enter email'
            />
          </div>
          {emailError && <div className='custom-error-login'>{emailError}</div>}
          <div className='title_password_main'>Enter password</div>
          <div
            className={`common-invalid  ${
              valid.password ? 'valid' : 'invalid'
            }`}
          >
            <input
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              className='searchTextInput w-100'
              placeholder='Enter password'
            />
            <img
              onClick={() => setShowNewPassword(!showNewPassword)}
              src={showNewPassword ? Icons.hidden : Icons.show}
              className='custom-password-icon'
            />
          </div>
          {passwordError && (
            <div className='custom-error-login'>{passwordError}</div>
          )}
          <div
            onClick={() => {
              setForgotNavigate(true)
              navigate('ForgotPassword', { replace: true })
            }}
            className='fontSize16 link-forgot'
          >
            Forgot Password?
          </div>
          <div
            onClick={() => handleLogin()}
            className='reset_password_on_click forget-pass'
          >
            Login
          </div>
        </div>
        <Loader />
      </div>
    </div>
  )
}

export default Login
