import React from 'react'
import Colors from '../../../../Constants/Colors'
import Icons from '../../../../Constants/Icons'
import CustomDropdown from '../../../../Components/DropDown/CustomDropdown'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../../Config/Actions/loadingActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  apiCallGetUserWiseAllEvent,
  apiCall_Admin_ToggleEventStatus
} from '../../../../Core/Apicall'
import CustomPagination from '../../../../Components/CustomPagination/CustomPagination'
import EmptyData from '../../../../Components/EmptyData/EmptyData'

const AllEvents = ({ userId }) => {
  const isLoading = useSelector(state => state.isLoading)
  const statusDropdownItem = ['All', 'Activate', 'Deactivate']
  const RecurringDropdownItem = [
    'All',
    'Weekly',
    'BiWeekly',
    'Monthly',
    "I Don't Known",
    'Custom'
  ]
  const DateDropdownItem = ['All', 'Today', 'This Week', 'This Month']
  const [selectDateDropValue, setDateDropValue] = useState('Select Date')
  const [selectRecurringValue, setRecurringDropValue] =
    useState('Select Recurring')
  const [statusDropValue, setStatusDropValue] = useState('Select Status')
  const [eventsListArray, setEventsListArray] = useState([])
  const [totalEventCountPage, setTotalEventCount] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    onApiCallAllEventGet()
  }, [selectDateDropValue, selectRecurringValue, statusDropValue])

  const onApiCallAllEventGet = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      userId: userId,
      repeatType:
        selectRecurringValue == 'All' ||
        selectRecurringValue == 'Select Recurring'
          ? ''
          : selectRecurringValue,
      weekSet:
        selectDateDropValue == 'All' || selectDateDropValue == 'Select Date'
          ? ''
          : selectDateDropValue == 'This Week'
          ? 'ThisWeekend'
          : selectDateDropValue == 'This Month'
          ? 'ThisMonth'
          : selectDateDropValue == "I Don't Known"
          ? 'IDontKnow'
          : selectDateDropValue,
      status:
        statusDropValue === 'All' || statusDropValue == 'Select Status'
          ? null
          : statusDropValue === 'Activate'
          ? true
          : statusDropValue === 'Deactivate'
          ? false
          : null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15
    }
    try {
      const res = await apiCallGetUserWiseAllEvent(param)
      setEventsListArray(res.data)
      setTotalEventCount(res.totalCount)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const navigate = useNavigate()

  const handleSelectRecurring = recurring => {
    setRecurringDropValue(recurring)
  }

  const handleSelectDate = date => {
    setDateDropValue(date)
  }

  const handleSelectStatus = status => {
    setStatusDropValue(status)
  }

  const handleEnabled = async data => {
    dispatch(setLoadingTrue())
    const param = {
      eventId: data.eventId,
      isEnabled: !data.isEnabled
    }
    try {
      const res = await apiCall_Admin_ToggleEventStatus(param)
      setEventsListArray(prevEvents =>
        prevEvents.map(item =>
          item.eventId === data.eventId
            ? { ...item, isEnabled: !item.isEnabled }
            : item
        )
      )
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const handleEdit = item => {
    navigate('/EventUpdate', {
      state: {
        eventId: item.eventId
      }
    })
  }

  return (
    <div>
      <div className='astrologers-list-parent padding-top-bottom'>
        <div className='frame-parent2'>
          <div className='custom-box-user'>
            <div className='custom-container-dropdown-div'>
              <CustomDropdown
                dropDownLabel={selectDateDropValue}
                items={DateDropdownItem}
                color='blue'
                onSelect={handleSelectDate}
                customLabelStyle={{
                  fontWeight: '400',
                  color: 'white'
                }}
              />
            </div>
            <div className='custom-container-dropdown-div w-200'>
              <CustomDropdown
                dropDownLabel={selectRecurringValue}
                items={RecurringDropdownItem}
                color='blue'
                onSelect={handleSelectRecurring}
                customLabelStyle={{
                  fontWeight: '400',
                  color: 'white'
                }}
              />
            </div>
            <div className='custom-container-dropdown-div'>
              <CustomDropdown
                dropDownLabel={statusDropValue}
                items={statusDropdownItem}
                color='blue'
                onSelect={handleSelectStatus}
                customLabelStyle={{
                  fontWeight: '400',
                  color: 'white'
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='margin-top-20 achievement-container'>
        <div className='achievement-title'>
          <div className='astrologerText'>Events</div>
        </div>

        <div className='achievement-content'>
          <div className='achievement-content-header'>
            <div className='lastWeekEndDivGreyColor w-28'>image</div>
            <div className='lastWeekEndDivGreyColor w-22'>Name</div>
            <div className='lastWeekEndDivGreyColor w-20'>Date</div>
            <div className='lastWeekEndDivGreyColor w-20'>Recurring Type</div>
            <div className='lastWeekEndDivGreyColor w-20'>Edit</div>
            <div className='lastWeekEndDivGreyColor w-20'>Status</div>
          </div>

          <div style={{ maxHeight: '400px' }} className='scrollable-container'>
            {!isLoading && eventsListArray.length == 0 ? (
              <EmptyData />
            ) : (
              eventsListArray.map((item, index) => (
                <div key={index}>
                  <div className='achievement-stretch-row'>
                    <div className='lastWeekEndDivGreyColor w-28'>
                      <img
                        className='subcategory-input'
                        src={item.eventImages}
                        style={{
                          objectFit: 'contain'
                        }}
                      />
                    </div>
                    <div className='fontSize16WithoutFlex w-22'>
                      {item.eventName}
                    </div>

                    <div className='fontSize16WithoutFlex w-20'>
                      {item.eventStartDate}
                    </div>
                    <div className='fontSize16WithoutFlex w-20'>
                      {item.repeatType === '' ? item.repeatType : '-'}
                    </div>
                    <div className='fontSize16WithoutFlex w-20'>
                      <img
                        src={Icons.edit}
                        className='edit-button pointer'
                        onClick={() => handleEdit(item, index)}
                      />
                    </div>
                    <div className='fontSize16WithoutFlex w-20'>
                      <div className='user_Status pointer'>
                        <button
                          onClick={() => handleEnabled(item, index)}
                          className='button'
                          style={{
                            cursor: 'pointer',
                            backgroundColor: item.isEnabled
                              ? Colors.mainGreen01
                              : Colors.mainRed01,
                            color: item.isEnabled
                              ? Colors.darkGreen
                              : Colors.darkRed
                          }}
                        >
                          {item.isEnabled ? 'Activate' : 'Deactivate'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {eventsListArray.length != 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={Math.ceil(totalEventCountPage / 15)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallAllEventGet}
          />
        </div>
      )}
    </div>
  )
}

export default AllEvents
