import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Loader from '../Loader/Loader'
import { Collapse } from '@mui/material'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Icons from '../../Constants/Icons'
const ListScreenData = [
  {
    text: 'Dashboard',
    icon: Icons.ic_grey_home,
    iconWhite: Icons.ic_home,
    route: '/'
  },

  {
    text: 'Event Category',
    icon: Icons.Ic_Category,
    iconWhite: Icons.Ic_Category_1,
    route: '/EventCategory'
  },
  {
    text: 'New Registration',
    icon: Icons.ic_user,
    iconWhite: Icons.ic_user_white,
    route: '/Registration'
  },
  {
    text: 'User',
    icon: Icons.ic_user,
    iconWhite: Icons.ic_user_white,
    route: '/UserManagement'
  },
  {
    text: 'Achievements',
    icon: Icons.ic_grey_achievement,
    iconWhite: Icons.ic_white_achievement,
    route: '/Achievements'
  },
  {
    text: 'Events',
    icon: Icons.Ic_event,
    route: '/EventsManagement',
    iconWhite: Icons.event,
    arrowIcon: Icons.ic_down,
    subItems: [
      {
        text: 'Today Event',
        route: '/TodayEvents'
      },

      {
        text: 'Upcoming Event',
        route: '/UpcomingEvent'
      },
      {
        text: 'Past Event',
        route: '/PastEvent'
      },
      {
        text: 'Featured Event',
        route: '/FeaturedEvent'
      },
      {
        text: 'Admin Event',
        route: '/WithOutCheckInEvent'
      }
    ]
  }
]
const CustomSideBar = () => {
  const [selectedItem, setSelectedItem] = useState('/')
  const [selectedSubItem, setSelectedSubItem] = useState('')
  const [openSubItems, setOpenSubItems] = useState('')
  const [subItemShow, setSubItemShow] = useState(null)
  const location = useLocation()
  useEffect(() => {
    const currentPath = location.pathname
    const matchedItem = ListScreenData.find(item => item.route === currentPath)
    if (matchedItem) {
      setSelectedItem(matchedItem.route)
      if (matchedItem.subItems) {
        setSubItemShow(true)
        setOpenSubItems(matchedItem.route)
      } else {
        setSubItemShow(false)
        setOpenSubItems('')
      }
    } else {
      const matchedSubItem = ListScreenData.flatMap(
        item => item.subItems || []
      ).find(subItem => subItem.route === currentPath)
      if (matchedSubItem) {
        setSelectedSubItem(matchedSubItem.route)
        const parentItem = ListScreenData.find(
          item => item.subItems && item.subItems.includes(matchedSubItem)
        )
        if (parentItem) {
          setSelectedItem(parentItem.route)
          setOpenSubItems(parentItem.route)
          setSubItemShow(true)
        }
      }
    }
  }, [location.pathname])

  const handleItemClick = item => {
    setSelectedSubItem('')
    setSelectedItem(item.route || item)
    setOpenSubItems(item.route || item)
    if (
      item.text === 'User' ||
      item.text === 'Event Category' ||
      item.text === 'Dashboard'
    ) {
      setSubItemShow(false)
    }
  }

  const handleSubItemClick = subItem => {
    setSelectedSubItem(subItem.route)
  }

  const isSubItemOpen = route => {
    return openSubItems === route
  }
  useEffect(() => {
    const eventListener = event => {
      const navLink = event.detail
      handleItemClick(navLink)
    }
    window.addEventListener('tabEventListener', eventListener)
    return () => {
      window.removeEventListener('tabEventListener', eventListener)
    }
  }, [])

  useEffect(() => {
    const eventListener = event => {
      const navLink = event.detail
      setSelectedItem('/EventsManagement')
      setOpenSubItems('/EventsManagement')
      setSubItemShow(true)
      setSelectedSubItem(navLink)
    }
    window.addEventListener('subTabEventListner', eventListener)
    return () => {
      window.removeEventListener('subTabEventListner', eventListener)
    }
  }, [])
  useEffect(() => {
    const handlePopState = () => {
      const currentPath = window.location.pathname
      const currentPathGet = ListScreenData.filter(
        item => item.route === currentPath
      )

      if (currentPathGet.length != 0) {
        handleItemClick(currentPathGet[0].route)
      }
    }
    window.addEventListener('popstate', handlePopState)
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: '15%',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: '15%',
          boxSizing: 'border-box',
          background: 'rgba(6, 101, 228, 0.2)'
        }
      }}
    >
      <Link to='/' className='drawer-link'>
        <div className='logo-container'>
          <img className='logo-image' src={Icons.ic_AY_logo} alt='new' />
          <div className='logo-text'>Artfulyo</div>
        </div>
      </Link>
      <List className='drawer-list'>
        {ListScreenData.map((item, index) => {
          const isSelected = selectedItem === item.route
          return (
            <div key={item.text}>
              <ListItem>
                <ListItemButton
                  className={`list-item-button ${isSelected ? 'active list-button-active' : ''}`}
                  component={NavLink}
                  to={item.route}
                  onClick={() => handleItemClick(item)}
                >
                  <div className='list-item-content'>
                    <img
                      className='list-item-icon'
                      src={isSelected ? item.iconWhite : item.icon}
                      alt='new'
                    />
                    <div className='list-item-text'>{item.text}</div>
                    {item.arrowIcon && (
                      <img
                        className={`arrow-icon ${
                          subItemShow ? 'valid-menu-round' : 'menu-round'
                        }`}
                        onClick={() => setSubItemShow(!subItemShow)}
                        src={item.arrowIcon}
                        alt='new'
                      />
                    )}
                  </div>
                </ListItemButton>
              </ListItem>
              {subItemShow && (
                <Collapse in={isSubItemOpen(item.route)}>
                  {item?.subItems?.map((subItem, subIndex) => (
                    <ListItem key={subIndex} className='sub-list-item'>
                      <ListItemButton
                        className={`sub-list-item-button ${
                          selectedSubItem === subItem.route
                            ? 'subitem-active'
                            : ''
                        }`}
                        component={NavLink}
                        to={subItem.route}
                        onClick={() => handleSubItemClick(subItem)}
                      >
                        <div className='sub-list-item-content'>
                          <div className='sub-list-item-text'>
                            {subItem.text}
                          </div>
                        </div>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Collapse>
              )}
            </div>
          )
        })}
      </List>
      <Loader />
    </Drawer>
  )
}
export default CustomSideBar
