import React from 'react'
import { useSelector } from 'react-redux'
import EmptyData from '../EmptyData/EmptyData'
const CustomCheckingDetails = ({ checkingDetails, handelEventMedia }) => {
  const isLoading = useSelector(state => state.isLoading)
  return (
    <div className='scrollable-container h-max-350'>
      {!isLoading && checkingDetails.length == 0 ? (
        <EmptyData />
      ) : (
        checkingDetails?.map((item, index) => (
          <div key={index}>
            <div className='achievement-stretch-row'>
              <div className='lastWeekEndDivGreyColor w-20'>
                <img className='subcategory-input' src={item.eventImages} />
              </div>
              <div className='fontSize16WithoutFlex w-20'>{item.eventName}</div>
              <div className='fontSize16WithoutFlex w-40'>
                {item.venueAddress}
              </div>

              <div className='fontSize16WithoutFlex w-20'>
                {item.eventStartDate}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}
export default CustomCheckingDetails
