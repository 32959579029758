import React, { useEffect } from 'react'
import Colors from '../../../../Constants/Colors'
import Icons from '../../../../Constants/Icons'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiCallPostGetArtLog } from '../../../../Core/Apicall'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../../Config/Actions/loadingActions'
import CustomPagination from '../../../../Components/CustomPagination/CustomPagination'
import EmptyData from '../../../../Components/EmptyData/EmptyData'

const ArtLog = ({ userId }) => {
  const isLoading = useSelector(state => state.isLoading)
  const [artLogs, setArtLog] = useState([])
  const [totalArtLogCount, setTotalArtLogCount] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    onApiCallArtLog()
  }, [])
  const onApiCallArtLog = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      userId: userId,
      status: null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15
    }
    try {
      const res = await apiCallPostGetArtLog(param)
      setArtLog(res?.data)
      setTotalArtLogCount(res.totalCount)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const handelEnabled = selected => {}

  return (
    <div>
      <div className='achievements'>
        <div className='upcoming_list'>
          <div className='margin-top-20 achievement-container'>
            <div className='achievement-header'>
              <div className='achievement-title'>
                <div className='astrologerText'>ArtLog</div>
              </div>
            </div>
            <div className='achievement-content'>
              <div className='achievement-content-header'>
                <div className='lastWeekEndDivGreyColor w-15'>image</div>
                <div className='lastWeekEndDivGreyColor w-15'>Name</div>
                <div className='lastWeekEndDivGreyColor w-13'>Events Name</div>

                <div className='lastWeekEndDivGreyColor w-20'>Date & times</div>
                <div className='lastWeekEndDivGreyColor w-20'>Status</div>
              </div>
              <div className='scrollable-container h-max-350'>
                {!isLoading && artLogs.length == 0 ? (
                  <EmptyData />
                ) : (
                  artLogs.map((item, index) => (
                    <div key={index}>
                      <div className='achievement-stretch-row'>
                        <div className='lastWeekEndDivGreyColor w-15'>
                          <img
                            src={item.eventImages}
                            className='subcategory-input'
                            style={{
                              objectFit: 'contain'
                            }}
                          />
                        </div>
                        <div className='fontSize16WithoutFlex w-15'>
                          {item.categoryName}
                        </div>
                        <div className='fontSize16WithoutFlex w-13'>
                          {item.eventName}
                        </div>

                        <div className='ellipsis-overflow-width'>
                          <div className='achievement-titleF'>
                            <img
                              className='icon_style_list'
                              alt=''
                              src={Icons.ic_calendar}
                            />
                            <div style={{}} className='itemTextDisplay'>
                              {item.eventStartDate +
                                ' , ' +
                                item.eventStartTime}
                            </div>
                          </div>
                        </div>
                        <div className='fontSize16WithoutFlex w-20'>
                          <div className='user_Status pointer'>
                            <button
                              onClick={() => handelEnabled(item, index)}
                              className='button'
                              style={{
                                cursor: 'pointer',
                                backgroundColor: item.isEnabled
                                  ? Colors.mainGreen01
                                  : Colors.mainRed01,
                                color: item.isEnabled
                                  ? Colors.darkGreen
                                  : Colors.darkRed
                              }}
                            >
                              {item.isEnabled ? 'Activate' : 'Deactivate'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {artLogs.length != 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={Math.ceil(totalArtLogCount / 15)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallArtLog}
          />
        </div>
      )}
    </div>
  )
}

export default ArtLog
