import React, { useEffect, useState } from 'react'
import CustomCheckingDetails from '../../../../Components/CustomUserProfileScreen/CustomCheckingDetails'
import CheckingDetailsModel from './CheckingDetailsModel'
import { useDispatch } from 'react-redux'
import CustomPagination from '../../../../Components/CustomPagination/CustomPagination'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../../Config/Actions/loadingActions'
import {
  apiCallDeleteEventMedia,
  apiCallUserCheckInEvent
} from '../../../../Core/Apicall'

const CheckingDetails = ({ userId }) => {
  const [eventList, setEventList] = useState([])
  const [eventListTotalCount, setEventListTotalCount] = useState(0)
  const [checkingDetailShow, setCheckingDetailShow] = useState(false)
  const [checkInEventSelect, setEventCheckDataSelect] = useState()
  const dispatch = useDispatch()
  useEffect(() => {
    onApiCallCheckInEvent()
  }, [])
  const onApiCallCheckInEvent = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      userId: userId,
      status: null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15
    }
    try {
      const res = await apiCallUserCheckInEvent(param)
      setEventList(res.data)
      setEventListTotalCount(res.totalCount)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  const confirmDeleted = async data => {
    dispatch(setLoadingTrue())
    const param = {
      eventId: checkInEventSelect.eventId,
      mediaId: data.mediaId
    }
    try {
      const res = await apiCallDeleteEventMedia(param)
      const updatedEventData = eventList.map(event => {
        const updatedEvent = { ...event }
        if (updatedEvent.media) {
          updatedEvent.media = updatedEvent.media.filter(
            mediaItem => mediaItem.mediaId !== data.mediaId
          )
        }
        return updatedEvent
      })
      setEventList(updatedEventData)

      const updatedDetails = {
        ...checkInEventSelect,
        media: checkInEventSelect.media.filter(
          item => item.mediaId !== data.mediaId
        )
      }
      setEventCheckDataSelect(updatedDetails)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  const handelEventMedia = selected => {
    setCheckingDetailShow(true)
    setEventCheckDataSelect(selected)
  }

  return (
    <div className='achievements'>
      <CheckingDetailsModel
        checkInEventSelect={checkInEventSelect}
        checkingDetailShow={checkingDetailShow}
        setCheckingDetailShow={setCheckingDetailShow}
        confirmDeleted={confirmDeleted}
      />
      <div className='upcoming_list'>
        <div className='margin-top-20 achievement-container'>
          <div className='achievement-header '>
            <div className='achievement-title'>
              <div className='astrologerText'>Check-in details</div>
            </div>
          </div>
          <div className='achievement-content'>
            <div className='achievement-content-header '>
              <div className='lastWeekEndDivGreyColor w-20'>image</div>
              <div className='lastWeekEndDivGreyColor w-20'>Event name</div>
              <div className='lastWeekEndDivGreyColor w-40'>Event Venue</div>

              <div className='lastWeekEndDivGreyColor w-20'>Date and Time</div>
            </div>

            <CustomCheckingDetails
              checkingDetails={eventList}
              handelEventMedia={handelEventMedia}
            />
          </div>
        </div>
      </div>
      {eventList.length != 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={Math.ceil(eventListTotalCount / 15)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallCheckInEvent}
          />
        </div>
      )}
    </div>
  )
}

export default CheckingDetails
