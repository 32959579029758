import React, { useState } from 'react'
import Icons from '../../Constants/Icons'
import Colors from '../../Constants/Colors'
import { useNavigate } from 'react-router-dom'

const RenderItemAchievements = ({
  data,
  onClickStatusChange,
  onClickYesDelete
}) => {
  const navigate = useNavigate()
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteItemData, setDeleteItemData] = useState(null)
  const handleEditClick = data => {
    navigate('/AddAchievements', {
      state: {
        achievementData: data,
        editAchievement: true
      }
    })
  }

  const onClickDelete = item => {
    setDeleteItemData(item)
    setDeleteConfirmation(true)
  }

  return (
    <div>
      <div style={{ maxHeight: '500px' }} className='scrollable-container'>
        {data?.map((item, index) => (
          <div key={index}>
            <div className='achievement-stretch-row'>
              <div className='lastWeekEndDivGreyColor w-15'>
                <img
                  src={item?.achievementImage}
                  className='subcategory-input'
                  style={{
                    objectFit: 'contain'
                  }}
                />
              </div>
              <div className='fontSize16WithoutFlex w-18'>
                {item?.achievementName}
              </div>
              <div className='fontSize16WithoutFlex w-20'>
                {item?.achievementDesc}
              </div>
              <div className='fontSize16WithoutFlex w-13'>
                {item?.achievementCount}
              </div>

              <div className='w-7'>
                <img
                  src={Icons.edit}
                  onClick={() => handleEditClick(item)}
                  className='edit-button pointer'
                />
              </div>

              <div className='fontSize16WithoutFlex w-10'>
                <img
                  src={Icons.delete}
                  onClick={() => onClickDelete(item)}
                  className='edit-button pointer'
                />
              </div>

              <div className='fontSize16WithoutFlex w-10'>
                <div className='user_Status pointer'>
                  <button
                    onClick={() => onClickStatusChange(item, index)}
                    className='button'
                    style={{
                      cursor: 'pointer',
                      backgroundColor: item.isEnabled
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: item.isEnabled ? Colors.darkGreen : Colors.darkRed
                    }}
                  >
                    {item.isEnabled ? 'Activate' : 'Deactivate'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {deleteConfirmation && (
        <div className='modal-category achievement-delete'>
          <div className='modal-content'>
            <p>Are you sure you want to delete this achievement?</p>
            <br />
            <div className='button-container'>
              <button
                onClick={() => {
                  setDeleteConfirmation(false)
                  onClickYesDelete(deleteItemData)
                }}
                className='modal-button button-yes'
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setDeleteItemData(null)
                  setDeleteConfirmation(false)
                }}
                className='modal-button button-no'
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default RenderItemAchievements
