import React, { useEffect, useState } from 'react'
import Colors from '../../../../Constants/Colors'
import { apiCallPostUserAchievementsEvent } from '../../../../Core/Apicall'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../../Config/Actions/loadingActions'
import CustomPagination from '../../../../Components/CustomPagination/CustomPagination'
import EmptyData from '../../../../Components/EmptyData/EmptyData'

const Achievements = ({ userId }) => {
  const isLoading = useSelector(state => state.isLoading)
  const [achievementsData, setAchievementData] = useState([])
  const [totalAchievementCount, setTotalAchievementCOunt] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    onApiCallAchievement()
  }, [])
  const onApiCallAchievement = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      userId: userId,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15
    }
    try {
      const res = await apiCallPostUserAchievementsEvent(param)
      setAchievementData(res.data)
      setTotalAchievementCOunt(res.totalCount)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  return (
    <div className='achievements'>
      <div className='upcoming_list'>
        <div className='margin-top-20 achievement-container'>
          <div className='achievement-header'>
            <div className='achievement-title'>
              <div className='astrologerText'>Achievements</div>
            </div>
          </div>
          <div className='achievement-content'>
            <div className='achievement-content-header'>
              <div className='lastWeekEndDivGreyColor w-28'>image</div>
              <div className='lastWeekEndDivGreyColor w-22'>Name</div>
              <div className='lastWeekEndDivGreyColor w-13'>Events Name</div>
              <div className='lastWeekEndDivGreyColor w-25'></div>
              <div className='lastWeekEndDivGreyColor w-30'>Description</div>
            </div>

            <div
              style={{ maxHeight: '350px' }}
              className='scrollable-container'
            >
              {!isLoading && achievementsData.length == 0 ? (
                <EmptyData />
              ) : (
                achievementsData.map((item, index) => (
                  <div key={index}>
                    <div className='achievement-content'>
                      <div className='lastWeekEndDivGreyColor w-28'>
                        <img src={item.imageUrl} style={{ width: '50px' }} />
                      </div>
                      <div className='fontSize16WithoutFlex w-22'>
                        {item.eventCreatorName}
                      </div>
                      <div className='fontSize16WithoutFlex w-13'>
                        {item.eventName}
                      </div>
                      <div className='fontSize16WithoutFlex w-25'></div>
                      <div className='fontSize16WithoutFlex w-30'>
                        {item.description}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {achievementsData.length != 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={Math.ceil(totalAchievementCount / 15)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallAchievement}
          />
        </div>
      )}
    </div>
  )
}
export default Achievements
