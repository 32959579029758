import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styles from '../Style'
import Header from '../../Components/Header/Header'
import Icons from '../../Constants/Icons'
import { Text } from 'react-native'
import { CustomUploadProfilePic } from '../../Core/CustomUploadProfilePic'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import { onApiCallAdminCreateAchievement } from '../../Core/Apicall'

const AddAchievements = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { editAchievement, achievementData } = location?.state
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const uploadImage = true
  const [formData, setFormData] = useState({
    achievementName: achievementData?.achievementName ?? '',
    achievementDescription: achievementData?.achievementDesc ?? '',
    achievementNumber: achievementData?.achievementCount ?? '',
    achievementImage: achievementData?.achievementImage ?? null
  })
  const [isValid, setIsValid] = useState({
    achievementName: true,
    achievementDescription: true,
    achievementNumber: true,
    achievementImage: true
  })
  const handleSubmit = async () => {
    const newIsValid = {
      achievementName: formData.achievementName !== '',
      achievementDescription: formData.achievementDescription !== '',
      achievementNumber: formData.achievementNumber !== '',
      achievementImage: formData.achievementImage !== null
    }
    setIsValid(newIsValid)
    const isFormValid = Object.values(newIsValid).every(Boolean)
    if (isFormValid) {
      try {
        dispatch(setLoadingTrue())
        let param = {
          achievementName: formData.achievementName,
          achievementDesc: formData.achievementDescription,
          achievementCount: JSON.parse(formData.achievementNumber),
          achievementImage: formData.achievementImage
        }
        if (editAchievement) {
          param.achievementId = achievementData?.achievementId
        }
        const res = await onApiCallAdminCreateAchievement(param)
        dispatch(setLoadingFalse())
        setShowSuccessPopup(true)
        setTimeout(() => {
          setFormData({
            achievementName: '',
            achievementDescription: '',
            achievementImage: null,
            achievementNumber: ''
          })
          setShowSuccessPopup(false)
          navigate('/Achievements')
        }, 2000)
      } catch (error) {
        console.log('errorerror', error)

        dispatch(setLoadingFalse())
      }
    }
  }
  const handleInputChange = (e, fieldName) => {
    let value = e.target.value
    if (fieldName === 'achievementNumber') {
      value = value.replace(/[^0-9]/g, '')
    }
    setFormData({
      ...formData,
      [fieldName]: value
    })
  }
  const handleImageUploadEvent = async event => {
    dispatch(setLoadingTrue())
    const selectedImage = event.target.files[0]
    const formData = new FormData()
    formData.append('imageURL', selectedImage)
    formData.append('imageType', 'AdminAchievementImages')
    formData.append('imageStoreId', '123456789')
    try {
      const data = await CustomUploadProfilePic(formData)
      setFormData(prevState => ({
        ...prevState,
        achievementImage: data.location
      }))

      dispatch(setLoadingFalse())
    } catch (error) {
      console.log('error', error)
      dispatch(setLoadingFalse())
    }
  }
  const uploadImageRemove = async () => {
    setFormData({
      ...formData,
      achievementImage: null
    })
  }
  return (
    <div>
      <Header
        name={editAchievement == false ? 'Achievement Add' : 'Achievement Edit'}
      />

      <div className='EventUpdate' style={styles.EventUpdate}>
        <button className='close_icon' onClick={() => navigate(-1)}>
          X
        </button>
        <div className='modal-content bg-Blue grid p-4 relative'>
          <h2>
            {editAchievement == false ? 'Achievement Add' : 'Achievement Edit'}
          </h2>

          <div
            className={`Category_name margin-bottom-20   ${
              isValid.achievementName ? 'valid' : 'invalid'
            }`}
          >
            <input
              type='text'
              placeholder='Name'
              value={formData.achievementName}
              onChange={e => handleInputChange(e, 'achievementName')}
            />
          </div>

          <div
            className={`Category_name margin-bottom-20   ${
              isValid.achievementDescription ? 'valid' : 'invalid'
            }`}
          >
            <input
              type='text'
              placeholder='Description'
              value={formData.achievementDescription}
              onChange={e => handleInputChange(e, 'achievementDescription')}
            />
          </div>

          <div
            className={`Category_name  margin-bottom-20  ${
              isValid.achievementNumber ? 'valid' : 'invalid'
            }`}
          >
            <input
              type='number'
              placeholder='Check In Number'
              value={formData.achievementNumber}
              onChange={e => handleInputChange(e, 'achievementNumber')}
            />
          </div>

          <div
            className={`Category_upload upload-custom ${
              isValid.achievementImage ? 'valid' : 'invalid'
            }`}
          >
            {uploadImage && (
              <div className='absolute upload-block'>
                <Text style={[{ marginBottom: '50px' }, styles.labelPhoto]}>
                  Achievement Photo
                </Text>
                <div className='upload-Image-flex'>
                  <div className='upload-data upload-cursor-width pointer'>
                    <input
                      type='file'
                      className='upload-cursor-width pointer'
                      onChange={handleImageUploadEvent}
                      accept='image/*'
                    />
                    <img
                      src={Icons.Ic_AddedImg}
                      className='pointer upload-cursor-width'
                    />
                  </div>
                  {formData.achievementImage != null && (
                    <div style={{ position: 'relative' }}>
                      <img
                        src={formData.achievementImage}
                        style={{
                          objectFit: 'cover'
                        }}
                        className='m-auto upload-cursor-width'
                      />
                      <img
                        src={Icons.delete}
                        alt='Category Preview'
                        onClick={() => uploadImageRemove()}
                        className='delete element-delete'
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className='Achievements-flex-M'>
            <div style={styles.eventSubmit} onClick={() => handleSubmit()}>
              Submit
            </div>
          </div>
          {showSuccessPopup && (
            <div className='success-popup'>
              <div className='success_event' style={{ textAlign: 'center' }}>
                <img src={Icons.Success} style={{ width: '50px' }} />
                <p>
                  {editAchievement
                    ? 'Achievement Updated Successfully'
                    : 'Achievement Added Successfully'}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AddAchievements
