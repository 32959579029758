import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../Components/Header/Header'
import Icons from '../../Constants/Icons'
import Colors from '../../Constants/Colors'
import CustomUserScreen from '../../Components/CustomUserScreen/CustomUserScreen'
import CustomDropdown from '../../Components/DropDown/CustomDropdown'
import CustomPagination from '../../Components/CustomPagination/CustomPagination'
import {
  apiCallGetUser,
  apiCallToggleUserStatus,
  onAPiCallAdminToggleFeaturedUser,
  onAPiCallUserSearchUser
} from '../../Core/Apicall'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import EmptyData from '../../Components/EmptyData/EmptyData'
import UseDebounce from '../../Components/UseDebounce'
import { CustomCommonBox } from '../../Components/CustomCommonBox/CustomCommonBox'
function UserManagement () {
  const statusDropdownItem = ['All', 'Activate', 'Deactivate']
  const dispatch = useDispatch()
  const [userListData, setUserListData] = useState()
  const isLoading = useSelector(state => state.isLoading)
  const [userTotalActive, setUserTotalActive] = useState(0)
  const [userTotalInActive, setUserTotalInActive] = useState(0)
  const [userTotalCount, setUserTotalCount] = useState(0)
  const [userListAllData, setUserListAllData] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState('By Status')
  const [initialPage, setInitialPage] = useState(0)

  const handleSelect = status => {
    setDropdownLabelSelect(status)
    if (status === 'All') {
      setUserListData(userListAllData)
    } else {
      const filteredData = userListAllData.data.filter(item =>
        status === 'Activate'
          ? item.isEnabled === true
          : item.isEnabled === false
      )
      setUserListData({ ...userListData, data: filteredData })
    }
  }
  const debouncedInput = UseDebounce(searchValue, 500)
  const isInitialRender = useRef(true)
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    searchFilterFunction(searchValue, 0)
  }, [debouncedInput])
  const handleInputChange = e => {
    const searchText = e.target.value
    setSearchValue(searchText)
  }

  const searchFilterFunction = async (text, page) => {
    setDropdownLabelSelect('By Status')
    const param = {
      searchQuery: text,
      offset: page + 1,
      limit: 15
    }
    dispatch(setLoadingTrue())
    try {
      const res = await onAPiCallUserSearchUser(param)
      setInitialPage(page)
      setUserListData(res)
      setUserListAllData(res)
      dispatch(setLoadingFalse())
    } catch (error) {
      console.error('Search error:', error)
    } finally {
      dispatch(setLoadingFalse())
    }
  }

  useEffect(() => {
    onApiCallUserList(0)
  }, [])
  const onApiCallUserList = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      status: null,
      offset: selectedPage + 1,
      limit: 15
    }
    try {
      const res = await apiCallGetUser(param)
      setUserTotalActive(res?.allActiveUser)
      setUserTotalCount(res?.allUser)
      setUserTotalInActive(res?.allDeActiveUser)
      setDropdownLabelSelect('By Status')
      setInitialPage(selectedPage ?? 0)
      setUserListData(res)
      setUserListAllData(res)
    } catch (error) {
      console.error('Error fetching user list:', error)
    } finally {
      dispatch(setLoadingFalse())
    }
  }

  const onClickFeatureStatusChange = async data => {
    dispatch(setLoadingTrue())
    const param = {
      userId: data.userId,
      isFeatured: !data.isFeatured
    }
    try {
      await onAPiCallAdminToggleFeaturedUser(param)
      setUserListData(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.userId === data.userId) {
            return { ...item, isFeatured: !item.isFeatured }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
      setUserListAllData(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.userId === data.userId) {
            return { ...item, isFeatured: !item.isFeatured }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
    } catch (error) {
      console.error('Error toggling featured status:', error)
    } finally {
      dispatch(setLoadingFalse())
    }
  }

  const onClickStatusChange = async data => {
    dispatch(setLoadingTrue())
    const param = {
      userId: data.userId,
      isEnabled: !data.isEnabled
    }
    try {
      await apiCallToggleUserStatus(param)
      if (data.isEnabled) {
        setUserTotalActive(prev => prev - 1)
        setUserTotalInActive(prev => prev + 1)
      } else {
        setUserTotalActive(prev => prev + 1)
        setUserTotalInActive(prev => prev - 1)
      }
      setUserListData(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.userId === data.userId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
      setUserListAllData(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.userId === data.userId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
    } catch (error) {
      console.error('Error toggling user status:', error)
    } finally {
      dispatch(setLoadingFalse())
    }
  }
  const UserTabs = [
    {
      label: 'Total Users',
      count: userTotalCount
    },
    {
      label: 'Total Activate',
      color: Colors.darkGreen,
      count: userTotalActive
    },
    {
      label: 'Total Deactivate',
      color: Colors.darkBlue,
      count: userTotalInActive
    }
  ]

  const onClickTabOpen = () => {}
  return (
    <div>
      <Header name={'User Management'} />
      <div className='register-flex'>
        <div className='astrologer-inner'>
          <div className='frame-container'>
            <div className='frame-div'>
              {UserTabs.map(tab => (
                <CustomCommonBox
                  label={tab.label}
                  onClickTabOpen={onClickTabOpen}
                  color={tab.color}
                  count={tab.count}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='astrologers-list-parent padding-top-bottom'>
        <div className='frame-parent2'>
          <div className='custom-box-user'>
            <div className='custom-container-dropdown-div'>
              <CustomDropdown
                dropDownLabel={dropdownLabelSelect}
                items={statusDropdownItem}
                color='blue'
                onSelect={handleSelect}
                customLabelStyle={{ fontWeight: '400', color: 'white' }}
              />
            </div>

            <div className='custom-box-search-div'>
              <img className='custom-image' alt='' src={Icons.ic_search} />
              <input
                type='text'
                disabled={isLoading}
                className='searchTextInput'
                placeholder='Search'
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='Event-custom-style'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
          <div className='user_title'>User List</div>
          <div className='transparentWhite'>
            <nav className='grid-cols grid_7 fontSize16WithoutFlex transparentWhite-padding'>
              <div className='user_name'>Name</div>
              <div className='user_name'>Username</div>
              <div className='user_name'>Contact</div>
              <div className='user_name'>Date of birth</div>
              <div className='user_name'>User type</div>
              <div className='user_name w-10'>Edit</div>
              <div className='user_name'>Status</div>
              <div className='user_name'>Featured</div>
            </nav>
            <CustomUserScreen
              currentUsers={userListData?.data}
              onClickStatusChange={onClickStatusChange}
              onClickFeatureStatusChange={onClickFeatureStatusChange}
            />
          </div>
        </div>
      </div>
      {userListData?.data.length === 0 && (
        <div className='empty-centered'>
          <EmptyData />
        </div>
      )}
      {userListData?.data.length !== 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={Math.ceil(userListData?.totalCount / 15)}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected)
              } else {
                onApiCallUserList(selected)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

export default UserManagement
