import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import Loader from '../../Components/Loader/Loader'
import Icons from '../../Constants/Icons'
import './auth.css'
import { onApiCallForgotPassword } from '../../Core/Apicall'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const [emailError, setEmailError] = useState('')
  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  const [valid, setValid] = useState({
    email: true
  })
  const handleLogin = async () => {
    let isValid = true

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.')
      isValid = false
      setValid(prevState => ({
        ...prevState,
        email: false
      }))
    } else {
      setEmailError('')
      setValid(prevState => ({
        ...prevState,
        email: true
      }))
    }

    if (isValid) {
      dispatch(setLoadingTrue())
      let param = {
        email: email
      }
      try {
        const res = await onApiCallForgotPassword(param)
        navigate('/SetForgotPassword', {
          state: email,
          replace: true
        })
        setEmail('')
        setEmailError('')
        toast.success('OTP sent successfully. Please check your email', {
          autoClose: 1200
        })
        dispatch(setLoadingFalse())
      } catch (error) {
        toast.error(error, {
          autoClose: 1200
        })
        dispatch(setLoadingFalse())
      }
    }
  }

  return (
    <div className='custom-login-flex'>
      <div className='custom-login-center'>
        <img className='custom-login-img' src={Icons.ic_AY_logo} />
      </div>
      <div className='custom-login-container'>
        <div className='custom-login-content'>
          <div className='title_reset_password title_reset_password-up'>
            Forgot password?
          </div>
          <div className='forgot_sub_title'>
            Enter the email address associated with your account and <br />{' '}
            we’ll send you a link to reset your password.
          </div>
          <div className='title_password_main'>Email</div>
          <div
            className={` common-invalid ${valid.email ? 'valid' : 'invalid'}`}
          >
            <input
              type={'email'}
              value={email}
              onChange={e => setEmail(e.target.value)}
              className='searchTextInput w-100'
              placeholder='Enter email'
            />
          </div>
          {emailError && <div className='custom-error-login'>{emailError}</div>}
          <div
            onClick={() => handleLogin()}
            className='reset_password_on_click forget-pass'
          >
            Send Link
          </div>
        </div>
        <Loader />
      </div>
    </div>
  )
}

export default ForgotPassword
