import React, { useEffect, useRef } from 'react'
import Header from '../../Components/Header/Header'
import CustomDropdown from '../../Components/DropDown/CustomDropdown'
import Icons from '../../Constants/Icons'
import { useState } from 'react'
import CustomPagination from '../../Components/CustomPagination/CustomPagination'
import CustomCategoryScreen from '../../Components/CustomCategoryScreen/CustomCategoryScreen'
import AddSubcategory from './AddSubcategory'
import Modal from './Modal'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import {
  apiCallDeleteInterestCategory,
  apiCallGetAdminInterestCategoryList,
  apiCallGetCategoryStatusChange,
  onAPiCallSearchInterestCategory
} from '../../Core/Apicall'
import UseDebounce from '../../Components/UseDebounce'
import EmptyData from '../../Components/EmptyData/EmptyData'
import './style.css'
import { CustomCommonBox } from '../../Components/CustomCommonBox/CustomCommonBox'
import Colors from '../../Constants/Colors'
function EventCategory () {
  const dispatch = useDispatch()
  const statusDropdownItem = ['All', 'Activate', 'Deactivate']
  const [editData, setEditData] = useState(null)
  const [Categories, setCategories] = useState(false)
  const [showCategory, setShowCategory] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [categoriesData, setCategoriesData] = useState()
  const [categoriesAllData, setCategoriesAllData] = useState()
  const [categoryShow, setCategoryShow] = useState(true)
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState('By Status')
  const [categoryItemSelect, setCategoryItemSelect] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [categoryTotalActive, setCategoryTotalActive] = useState(0)
  const [categoryTotalInActive, setCategoryTotalInActive] = useState(0)
  const [categoryTotalCount, setCategoryTotalCount] = useState(0)
  const debouncedInput = UseDebounce(searchValue, 500)
  const [initialPage, setInitialPage] = useState(0)

  useEffect(() => {
    onApiCallCategoryList(0)
  }, [])

  const isInitialRender = useRef(true)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    searchFilterFunction(searchValue, 0)
  }, [debouncedInput])

  const onApiCallCategoryList = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      status: null,
      offset: selectedPage + 1,
      limit: 15
    }
    try {
      const res = await apiCallGetAdminInterestCategoryList(param)
      setCategoryTotalActive(res.allActiveCategories)
      setCategoryTotalInActive(res.allDeActiveCategories)
      setCategoryTotalCount(res.allCategoriesTotal)
      setDropdownLabelSelect('By Status')
      setInitialPage(selectedPage ?? 0)
      setCategoriesData(res)
      setCategoriesAllData(res)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const addNewCategory = () => {
    setShowModal(true)
    setEditData(null)
  }
  const handleCloseModal = () => {
    setEditData()
    setShowModal(false)
  }

  const handleEditClick = selectedCategory => {
    setShowModal(true)
    setEditData(selectedCategory)
  }

  const handleSelect = status => {
    setDropdownLabelSelect(status)
    if (status === 'All') {
      setCategoriesData(categoriesAllData)
    } else {
      const filteredData = categoriesAllData.categories.filter(item =>
        status === 'Activate'
          ? item.isEnabled === true
          : item.isEnabled === false
      )
      setCategoriesData({ ...categoriesAllData, categories: filteredData })
    }
  }

  const handleInputChange = e => {
    const searchText = e.target.value
    setSearchValue(searchText)
  }

  const searchFilterFunction = async (text, page) => {
    setDropdownLabelSelect('By Status')
    const param = {
      searchQuery: text,
      offset: page + 1,
      limit: 15
    }
    dispatch(setLoadingTrue())
    try {
      const res = await onAPiCallSearchInterestCategory(param)
      setInitialPage(page)
      setCategoriesData(res)
      setCategoriesAllData(res)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  const onClickToggleStatusChange = async data => {
    dispatch(setLoadingTrue())
    const param = {
      categoryId: data.categoryId,
      isEnabled: !data.isEnabled
    }
    try {
      await apiCallGetCategoryStatusChange(param)
      dispatch(setLoadingFalse())
      if (data.isEnabled) {
        setCategoryTotalActive(prev => prev - 1)
        setCategoryTotalInActive(prev => prev + 1)
      } else {
        setCategoryTotalActive(prev => prev + 1)
        setCategoryTotalInActive(prev => prev - 1)
      }

      setCategoriesData(prevData => {
        const updatedData = prevData.categories.map(item => {
          if (item.categoryId === data.categoryId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, categories: updatedData }
      })
      setCategoriesAllData(prevData => {
        const updatedData = prevData.categories.map(item => {
          if (item.categoryId === data.categoryId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, categories: updatedData }
      })
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  const handelDelete = async selectedCategory => {
    dispatch(setLoadingTrue())
    const param = {
      categoryId: selectedCategory.categoryId
    }
    try {
      await apiCallDeleteInterestCategory(param)
      if (selectedCategory.isEnabled) {
        setCategoryTotalActive(prev => prev - 1)
      } else {
        setCategoryTotalInActive(prev => prev - 1)
      }
      setCategoryTotalCount(prev => prev - 1)
      setCategoriesData(prevData => ({
        ...prevData,
        categories: prevData.categories.filter(
          item => item.categoryId !== selectedCategory.categoryId
        )
      }))
      setCategoriesAllData(prevData => ({
        ...prevData,
        categories: prevData.categories.filter(
          item => item.categoryId !== selectedCategory.categoryId
        )
      }))
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const handleSubcategory = category => {
    setCategoryItemSelect(category)
    setShowCategory(true)
  }

  const eventTotal = [
    {
      name: 'Total Category',
      value: categoryTotalCount
    },
    {
      name: 'Total Activate',
      value: categoryTotalActive,
      color: Colors.darkGreen
    },
    {
      name: 'Total Deactivate',
      value: categoryTotalInActive,
      color: Colors.darkBlue
    }
  ]
  const onClickTabOpen = () => {}
  return (
    <div className='Category-Event'>
      <Header name={'Event Category'} />
      <div className='Event-AlignSelf'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch '>
          <div className='astrologer-inner'>
            <div className='frame-container'>
              <div className='frame-div'>
                {eventTotal.map(item => (
                  <CustomCommonBox
                    label={item.name}
                    count={item.value}
                    color={item.color}
                    onClickTabOpen={() => onClickTabOpen()}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='astrologers-list-parent padding-top-bottom'>
        <div className='frame-parent2'>
          <div className='custom-box-user'>
            <div className='category_button'>
              <button onClick={addNewCategory}>Add new category </button>
            </div>
            <div className='custom-container-dropdown-div'>
              <CustomDropdown
                dropDownLabel={dropdownLabelSelect}
                items={statusDropdownItem}
                color='blue'
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: '400',
                  color: 'white'
                }}
              />
            </div>

            <div className='custom-box-search-div'>
              <img className='custom-image' alt='' src={Icons.ic_search} />
              <input
                type='text'
                className='searchTextInput'
                placeholder='Search'
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      {showCategory && (
        <AddSubcategory
          shows={showCategory}
          setShowCategory={setShowCategory}
          editData={editData}
          categoryItemSelect={categoryItemSelect}
          handleClose={handleCloseModal}
        />
      )}
      {showModal && (
        <Modal
          show={showModal}
          editData={editData}
          handleClose={handleCloseModal}
          pageRefresh={() => onApiCallCategoryList(0)}
          Categories={Categories}
          setCategories={setCategories}
        />
      )}
      <div className='mainDarkColor Event-custom-style'>
        <div className='lastWeekEndDiv Total-Event'>
          <div className='user_title event-flex-container'>
            Event Category List
          </div>
          <div className='transparentWhite'>
            <nav className='grid-cols grid_7 fontSize16WithoutFlex transparentWhite-padding'>
              <div className='category_w'>Category img</div>
              <div className='category_w'>Category name</div>
              <div className='category_w'>Status</div>
              <div className='category_w'>Edit</div>
              <div className='category_w'>Subcategory</div>
              <div className='category_w'>Delete</div>
            </nav>
            <CustomCategoryScreen
              currentCategories={categoriesData?.categories}
              onClickToggleStatusChange={onClickToggleStatusChange}
              onEditClick={handleEditClick}
              handelDelete={handelDelete}
              handleSubcategory={handleSubcategory}
              categoryShow={categoryShow}
            />
          </div>
        </div>
      </div>

      {categoriesData?.categories?.length === 0 && (
        <div className='empty-centered'>
          <EmptyData />
        </div>
      )}

      {categoriesData?.categories?.length != 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={Math.ceil(categoriesData?.totalCount / 15)}
            initialPage={initialPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected)
              } else {
                onApiCallCategoryList(selected)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
export default EventCategory
