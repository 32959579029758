import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Components/Header/Header'
import CustomDropdown from '../../../Components/DropDown/CustomDropdown'
import Colors from '../../../Constants/Colors'
import Icons from '../../../Constants/Icons'
import CustomPagination from '../../../Components/CustomPagination/CustomPagination'
import CustomSubCategoryScreen from '../../../Components/CustomCategoryScreen/CustomSubCategoryScreen'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../Config/Actions/loadingActions'
import { useLocation } from 'react-router-dom'
import {
  apiCallDeleteCategoryWiseInterest,
  apiCallGetAdminCategoryWiseInterest,
  apiCallToggleCategoryWiseInterest,
  onAPiCallSearchCategoryWiseInterest
} from '../../../Core/Apicall'
import ModalSubCategory from './ModalSubCategory'
import UseDebounce from '../../../Components/UseDebounce'
import EmptyData from '../../../Components/EmptyData/EmptyData'
import { CustomCommonBox } from '../../../Components/CustomCommonBox/CustomCommonBox'
function Subcategory () {
  const statusDropdownItem = ['All', 'Activate', 'Deactivate']
  const [editData, setEditData] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [Categories, setCategories] = useState()
  const [subCategoryListData, setSubCategoryListData] = useState()
  const [subCategoryListAllData, setSubCategoryListAllData] = useState()
  const [SubCategoryShow, setSubCategoryShow] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState('By Status')
  const [categoryTotalActive, setCategoryTotalActive] = useState(0)
  const [categoryTotalInActive, setCategoryTotalInActive] = useState(0)
  const [categoryTotalCount, setCategoryTotalCount] = useState(0)
  const debouncedInput = UseDebounce(searchValue, 500)
  const [initialPage, setInitialPage] = useState(0)
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    onApiCallSubCategoryList(0)
  }, [])
  const isInitialRender = useRef(true)
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    searchFilterFunction(searchValue, 0)
  }, [debouncedInput])

  const onApiCallSubCategoryList = async selectedPage => {
    dispatch(setLoadingTrue())
    const param = {
      status: null,
      categoryId: location?.state?.categoryId,
      offset: selectedPage + 1,
      limit: 15
    }
    try {
      const res = await apiCallGetAdminCategoryWiseInterest(param)
      setSubCategoryListData(res)
      setSubCategoryListAllData(res)
      setDropdownLabelSelect('By Status')
      setInitialPage(selectedPage ?? 0)
      setCategoryTotalActive(res.allActiveSubCat)
      setCategoryTotalInActive(res.allDeActiveSubCat)
      setCategoryTotalCount(res.allSubCatTotal)
      console.log('dd', res)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const handleCloseModal = () => {
    setEditData(null)
    setShowModal(false)
  }

  const handleEditClick = selectedCategory => {
    setEditData(selectedCategory)
    setShowModal(true)
  }

  const handleSelect = status => {
    setDropdownLabelSelect(status)
    if (status === 'All') {
      setSubCategoryListData(subCategoryListAllData)
    } else {
      const filteredData = subCategoryListAllData.data.filter(item =>
        status === 'Activate'
          ? item.isEnabled === true
          : item.isEnabled === false
      )
      setSubCategoryListData({ ...subCategoryListAllData, data: filteredData })
    }
  }

  const handleInputChange = e => {
    const searchText = e.target.value
    setSearchValue(searchText)
  }

  const searchFilterFunction = async (text, page) => {
    setDropdownLabelSelect('By Status')
    const param = {
      searchQuery: text,
      categoryId: location?.state?.categoryId,
      offset: page + 1,
      limit: 15
    }
    try {
      const res = await onAPiCallSearchCategoryWiseInterest(param)
      setInitialPage(page)
      setSubCategoryListData(res)
      setSubCategoryListAllData(res)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const onClickToggleStatusChange = async data => {
    dispatch(setLoadingTrue())
    const param = {
      artId: data.artId,
      isEnabled: !data.isEnabled
    }
    try {
      await apiCallToggleCategoryWiseInterest(param)
      dispatch(setLoadingFalse())
      if (data.isEnabled) {
        setCategoryTotalActive(prev => prev - 1)
        setCategoryTotalInActive(prev => prev + 1)
      } else {
        setCategoryTotalActive(prev => prev + 1)
        setCategoryTotalInActive(prev => prev - 1)
      }

      setSubCategoryListData(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.artId === data.artId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
      setSubCategoryListAllData(prevData => {
        const updatedData = prevData.data.map(item => {
          if (item.artId === data.artId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return { ...prevData, data: updatedData }
      })
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  const handelDelete = async selectedCategory => {
    dispatch(setLoadingTrue())
    const param = {
      artId: selectedCategory.artId
    }
    try {
      const res = await apiCallDeleteCategoryWiseInterest(param)
      if (selectedCategory.isEnabled) {
        setCategoryTotalActive(prev => prev - 1)
      } else {
        setCategoryTotalInActive(prev => prev - 1)
      }
      setCategoryTotalCount(prev => prev - 1)
      setSubCategoryListData(prevData => ({
        ...prevData,
        data: prevData.data.filter(
          item => item.artId !== selectedCategory.artId
        )
      }))
      setSubCategoryListAllData(prevData => ({
        ...prevData,
        data: prevData.data.filter(
          item => item.artId !== selectedCategory.artId
        )
      }))
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const SubcategoryTotal = [
    {
      name: 'Total Subcategory',
      count: categoryTotalCount,
      color: ''
    },
    {
      name: 'Total Activate',
      count: categoryTotalActive,
      color: Colors.darkGreen
    },
    {
      name: 'Total Deactivate',
      count: categoryTotalInActive,
      color: Colors.darkBlue
    }
  ]
  const onClickTabOpen = () => {}
  return (
    <div className='Category-Event'>
      <Header name={'Subcategory'} />
      <div className='Event-custom-style'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
          <div className='astrologer-inner background-none'>
            <div className='frame-container'>
              <div className='frame-div'>
                {SubcategoryTotal.map(item => (
                  <CustomCommonBox
                    label={item.name}
                    count={item.count}
                    color={item.color}
                    onClickTabOpen={() => onClickTabOpen()}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='astrologers-list-parent padding-top-bottom'>
        <div className='frame-parent2'>
          <div className='custom-box-user'>
            <div className='custom-container-dropdown-div'>
              <CustomDropdown
                dropDownLabel={dropdownLabelSelect}
                items={statusDropdownItem}
                color='blue'
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: '400',
                  color: 'white'
                }}
              />
            </div>
            <div className='custom-box-search-div'>
              <img className='custom-image' alt='' src={Icons.ic_search} />
              <input
                type='text'
                className='searchTextInput'
                placeholder='Search'
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ModalSubCategory
          show={showModal}
          editData={editData}
          handleClose={handleCloseModal}
          Categories={Categories}
          pageRefresh={() => onApiCallSubCategoryList(0)}
          setCategories={setCategories}
        />
      )}
      <div className='Event-custom-style'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
          <div className='user_title event-flex-container'>
            Subcategory List
          </div>
          <div className='transparentWhite'>
            <nav className='grid-cols grid_7 fontSize16WithoutFlex transparentWhite-padding'>
              <div className='category_w'>Subcategory img</div>
              <div className='category_w'>Subcategory name</div>
              <div className='category_w'>Status</div>
              <div className='category_w'>Edit</div>
              <div className='category_w'>Delete</div>
            </nav>
            <CustomSubCategoryScreen
              currentCategories={subCategoryListData?.data}
              onEditClick={handleEditClick}
              onClickToggleStatusChange={onClickToggleStatusChange}
              handelDelete={handelDelete}
              SubCategoryShow={SubCategoryShow}
            />
          </div>
        </div>
      </div>

      {subCategoryListData?.data?.length === 0 && (
        <div className='empty-centered'>
          <EmptyData />
        </div>
      )}

      {subCategoryListData?.data?.length != 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={Math.ceil(subCategoryListData?.totalCount / 15)}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected)
              } else {
                onApiCallSubCategoryList(selected)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Subcategory
