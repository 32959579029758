import React from 'react'
import Header from '../../../Components/Header/Header'
import { useState, useEffect } from 'react'
import CheckingDetails from './CustomEventDetails/CheckingDetails'
import HostDetails from './CustomEventDetails/HostDetails'
import MediaDetails from './CustomEventDetails/MediaDetails'
import { useLocation } from 'react-router-dom'
import { apiCallGetEventDetails } from '../../../Core/Apicall'
import { useDispatch } from 'react-redux'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../../Config/Actions/loadingActions'

function EventDetails () {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(0)
  const [eventDetails, setEventDetails] = useState([])
  const [hostDetails, setHostDetails] = useState()
  const tabs = ['Host Details', 'Media Details', 'Checked In Details']
  const dispatch = useDispatch()
  const handleTabClick = index => {
    setActiveTab(index)
  }
  useEffect(() => {
    if (location.state) {
      onApiCallUserDetails()
    }
  }, [])

  const onApiCallUserDetails = async () => {
    dispatch(setLoadingTrue())
    const param = {
      eventId: location.state.eventId
    }
    try {
      const response = await apiCallGetEventDetails(param)
      setEventDetails(response)
      setHostDetails(response.data)
      console.log('er', response)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  return (
    <div>
      <Header name='Event Details' />
      <div className='user-profile-custom-container margin-top-20'>
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{
              padding: '10px',
              fontWeight: '400',
              color: index != activeTab ? 'rgba(255, 255, 255, 0.5)' : ' #fff',
              borderBottom: index != activeTab ? '' : '1px solid #fff'
            }}
            className='fontSize18 pointer'
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>

      <div className='margin-top-20'>
        {activeTab === 0 && (
          <div className='grid grid-cols-2 gap-7'>
            <HostDetails eventDetails={eventDetails?.data} />
          </div>
        )}
        {activeTab === 1 && (
          <div className='grid grid-cols-2 gap-7'>
            <MediaDetails
              hostDetails={hostDetails?.eventMedia}
              onApiCallUserDetails={onApiCallUserDetails}
            />
          </div>
        )}
        {activeTab === 2 && (
          <div className='grid grid-cols-2 gap-7'>
            <CheckingDetails eventDetails={eventDetails?.data} />
          </div>
        )}
      </div>
    </div>
  )
}
export default EventDetails
