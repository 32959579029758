import Header from '../../Components/Header/Header'
import Colors from '../../Constants/Colors'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CustomDropdown from '../../Components/DropDown/CustomDropdown'
import Icons from '../../Constants/Icons'
import RenderItemAchievements from './RenderItemAchievements'
import { useNavigate } from 'react-router-dom'
import {
  onApiAdminToggleAchievementStatus,
  onApiCallAdminDeleteAchievement,
  onApiCallGetAchievement
} from '../../Core/Apicall'
import {
  setLoadingFalse,
  setLoadingTrue
} from '../../Config/Actions/loadingActions'
import EmptyData from '../../Components/EmptyData/EmptyData'
import CustomPagination from '../../Components/CustomPagination/CustomPagination'
import UseDebounce from '../../Components/UseDebounce'
import { CustomCommonBox } from '../../Components/CustomCommonBox/CustomCommonBox'

function Achievements () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const statusDropdownItem = ['All', 'Activate', 'Deactivate']
  const [searchValue, setSearchValue] = useState('')
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState('By Status')
  const [initialPage, setInitialPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [totalActive, setTotalActive] = useState(0)
  const [totalInActive, setTotalInActive] = useState(0)
  const [achievementsData, setAchievementsData] = useState([])
  const [achievementsDataAll, setAchievementsDataAll] = useState([])

  const debouncedInput = UseDebounce(searchValue, 500)
  useEffect(() => {
    onApiCallAchievementData(0)
  }, [debouncedInput])

  const onApiCallAchievementData = async page => {
    dispatch(setLoadingTrue())
    let param = {
      status: null,
      searchQuery: searchValue == '' ? null : searchValue,
      offset: page + 1,
      limit: 15
    }
    try {
      const res = await onApiCallGetAchievement(param)
      setTotalCount(res.totalCount)
      setTotalActive(res.allActiveEventType)
      setTotalInActive(res.allDeActiveEventType)
      setAchievementsData(res.data)
      setAchievementsDataAll(res.data)
      setInitialPage(page)
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }

  const handleInputChange = e => {
    const searchText = e.target.value
    setSearchValue(searchText)
  }

  const handleSelect = status => {
    setDropdownLabelSelect(status)
    if (status === 'All') {
      setAchievementsData(achievementsDataAll)
    } else {
      const filteredData = achievementsDataAll.filter(item =>
        status === 'Activate'
          ? item.isEnabled === true
          : item.isEnabled === false
      )
      setAchievementsData(filteredData)
    }
  }

  const onClickYesDelete = async item => {
    let param = {
      achievementId: item?.achievementId
    }
    dispatch(setLoadingTrue())
    try {
      const res = await onApiCallAdminDeleteAchievement(param)
      const updateDeleteArray = achievementsData.filter(
        data => data.achievementId !== item.achievementId
      )

      if (item.isEnabled) {
        setTotalActive(prev => prev - 1)
      } else {
        setTotalInActive(prev => prev - 1)
      }
      setTotalCount(prev => prev - 1)

      setAchievementsData(updateDeleteArray)
      setAchievementsDataAll(updateDeleteArray)

      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  const onClickStatusChange = async data => {
    dispatch(setLoadingTrue())
    const param = {
      achievementId: data.achievementId,
      isEnabled: !data.isEnabled
    }
    try {
      const res = await onApiAdminToggleAchievementStatus(param)
      if (data.isEnabled) {
        setTotalActive(pre => pre - 1)
        setTotalInActive(pre => pre + 1)
      } else {
        setTotalActive(pre => pre + 1)
        setTotalInActive(pre => pre - 1)
      }
      setAchievementsData(prevData => {
        const updatedData = prevData.map(item => {
          if (item.achievementId === data.achievementId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return updatedData
      })
      setAchievementsDataAll(prevData => {
        const updatedData = prevData.map(item => {
          if (item.achievementId === data.achievementId) {
            return { ...item, isEnabled: !item.isEnabled }
          }
          return item
        })
        return updatedData
      })
      dispatch(setLoadingFalse())
    } catch (error) {
      dispatch(setLoadingFalse())
    }
  }
  const onClickTabOpen = () => {}
  return (
    <div>
      <Header name={'Achievements'} />
      <div className='Event-AlignSelf'>
        <div className='lastWeekEndDiv Event-AlignSelf-stretch'>
          <div className='astrologer-inner'>
            <div className='frame-container'>
              <div className='frame-div'>
                {[
                  { label: 'Total Achievements', color: '', count: totalCount },
                  {
                    label: 'Total Activate',
                    color: Colors.darkGreen,
                    count: totalActive
                  },
                  {
                    label: 'Total Deactivate',
                    color: Colors.darkBlue,
                    count: totalInActive
                  }
                ].map(items => (
                  <CustomCommonBox
                    label={items.label}
                    color={items.color}
                    count={items.count}
                    onClickTabOpen={onClickTabOpen}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='astrologers-list-parent padding-top-bottom'>
          <div className='frame-parent2'>
            <div className='custom-box-user'>
              <div className='category_button'>
                <button
                  onClick={() => {
                    navigate('/AddAchievements', {
                      state: { editAchievement: false }
                    })
                  }}
                >
                  Add Achievements
                </button>
              </div>
              <div className='custom-container-dropdown-div'>
                <CustomDropdown
                  dropDownLabel={dropdownLabelSelect}
                  items={statusDropdownItem}
                  color='blue'
                  onSelect={handleSelect}
                  customLabelStyle={{
                    fontWeight: '400',
                    color: 'white'
                  }}
                />
              </div>

              <div className='custom-box-search-div'>
                <img className='custom-image' alt='' src={Icons.ic_search} />
                <input
                  type='text'
                  className='searchTextInput'
                  placeholder='Search'
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='Event-custom-style'>
          <div className='lastWeekEndDiv Total-Event'>
            <div className='user_title event-flex-container'>
              Achievements List
            </div>

            <div className='Achievements-class-name'>
              <div className='achievement-content'>
                <div className='achievement-content-header'>
                  <div className='lastWeekEndDivGreyColor w-15'>Image</div>
                  <div className='lastWeekEndDivGreyColor w-18'>Name</div>
                  <div className='lastWeekEndDivGreyColor w-20'>
                    Description
                  </div>

                  <div className='lastWeekEndDivGreyColor w-13'>
                    Check In Count
                  </div>
                  <div className='lastWeekEndDivGreyColor w-7'>Edit</div>

                  <div className='lastWeekEndDivGreyColor w-10'>Delete</div>

                  <div className='lastWeekEndDivGreyColor w-10'>Status</div>
                </div>
                <div className='scrollable-container transform_data'>
                  <RenderItemAchievements
                    onClickStatusChange={onClickStatusChange}
                    data={achievementsData}
                    onClickYesDelete={onClickYesDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {achievementsData.length === 0 && (
        <div className='empty-centered'>
          <EmptyData />
        </div>
      )}

      {achievementsData?.length !== 0 && (
        <div className='Custom-Pagination'>
          <CustomPagination
            pageCount={Math.ceil(totalCount / 15)}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              onApiCallAchievementData(selected)
            }}
          />
        </div>
      )}
    </div>
  )
}
export default Achievements
