import React from 'react'
import Icons from '../../Constants/Icons'
import Colors from '../../Constants/Colors'
import { useNavigate } from 'react-router-dom'

const UpcomingEventsScreen = ({ data, handelEnabled, adminEventEdit }) => {
  const navigate = useNavigate()
  const onClickEventDetails = item => {
    navigate('/EventDetails', {
      state: {
        eventId: item.eventId
      }
    })
  }
  const handleEditClick = currentUsers => {
    navigate('/EventUpdate', {
      state: {
        eventId: currentUsers.eventId,
        eventTypeId: currentUsers.eventTypeId,
        adminEventEdit: adminEventEdit
      }
    })
  }
  return (
    <div>
      <div style={{ maxHeight: '500px' }} className='scrollable-container'>
        {data?.map((item, index) => (
          <div key={index}>
            <div className='achievement-stretch-row'>
              <div
                onClick={() => onClickEventDetails(item)}
                className='lastWeekEndDivGreyColor w-15 pointer'
              >
                <img
                  src={item.eventImages ?? Icons.ic_AY_logo}
                  className='subcategory-input'
                  style={{
                    objectFit: 'contain'
                  }}
                />
              </div>
              <div
                onClick={() => onClickEventDetails(item)}
                className='fontSize16WithoutFlex w-15 pointer '
              >
                {item.eventTypeName}
              </div>
              <div
                onClick={() => onClickEventDetails(item)}
                className='fontSize16WithoutFlex w-13 pointer'
              >
                {item.eventName}
              </div>

              <div
                onClick={() => onClickEventDetails(item)}
                className='ellipsis-overflow-width'
              >
                <div className='achievement-title'>
                  <img
                    className='icon_style_list'
                    alt=''
                    src={Icons.ic_calendar}
                  />
                  <div style={{}} className='itemTextDisplay'>
                    {item.eventStartDate + ' , ' + item.eventStartTime}
                  </div>
                </div>
                <div className='achievement-title'>
                  <img
                    className='icon_style_list'
                    alt=''
                    src={Icons.ic_location}
                  />
                  <div className='itemTextDisplay'>
                    {item.birthPlace == '' ? '-' : item.venueAddress}
                  </div>
                </div>
              </div>
              <div className='w-10'>
                <img
                  src={Icons.edit}
                  onClick={() => handleEditClick(item)}
                  className='edit-button pointer'
                />
              </div>
              <div className='fontSize16WithoutFlex w-20'>
                <div className='user_Status pointer'>
                  <button
                    onClick={() => handelEnabled(item, index)}
                    className='button'
                    style={{
                      cursor: 'pointer',
                      backgroundColor: item.isEnabled
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: item.isEnabled ? Colors.darkGreen : Colors.darkRed
                    }}
                  >
                    {item.isEnabled ? 'Activate' : 'Deactivate'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default UpcomingEventsScreen
